import React from "react"

const ButtonToAdd = (props) => {
	return (
		<div className={"w-40"}>
			<button
				onClick={props.toggleModal}
				className={"commission-btn " + props.class}
			>
				{props.name} <i className="fas fa-plus"></i>
			</button>
		</div>
	)
}

export default ButtonToAdd
