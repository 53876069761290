import React from "react";

const convertHexToRgba = (colorHex) => {
	colorHex = colorHex.replace("#", "");

	const r = parseInt(colorHex.substring(0, 2), 16);
	const g = parseInt(colorHex.substring(2, 4), 16);
	const b = parseInt(colorHex.substring(4, 6), 16);

	const opacity = 0.5;

	const rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;

	return rgbaColor;
};

const ImageInText = ({ image, color, isSmallText = false }) => {
	return (
		<div
			className={`inline-block rounded-[10px] relative  overflow-hidden ${
				isSmallText
					? "w-[80px] h-[30px] md:h-[35px] mr-[5px]"
					: "w-[100px] h-[45px] md:h-[60px] lg:h-[75px] lg:w-[120px] mr-[16px]"
			}`}
			style={{
				background: `radial-gradient(50% 50.00% at 50% 50.00%, ${convertHexToRgba(
					color
				)} 0%, ${color} 100%)`,
			}}
		>
			<img
				src={image}
				alt="Image illustrating text"
				className="absolute w-full bottom-0 text-sm font-normal rounded-b-[10px]"
			/>
		</div>
	);
};

export default ImageInText;
