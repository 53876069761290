import React from "react"
import moment from "moment"
import { useParams } from "react-router-dom"
import axios from "axios"

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
	const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM")
	return buildDate
}

export function withRouter(Children) {
	return (props) => {
		const match = { params: useParams() }
		return <Children {...props} match={match} />
	}
}

export const validateCodeHandler = async (
	code,
	city,
	petType,
	dogService,
	staff
) => {
	if (!code) {
		return { err: "Coupon code cannot be empty." }
	}

	try {
		const response = await axios.get(`/api/discount/${code}`)
		const {
			pass,
			status,
			serviceType,
			expireDate,
			location,
			serviceToBeProvidedBy,
		} = response.data

		if (!pass) {
			return { err: "This coupon is invalid." }
		}

		if (location !== "all") {
			if (
				(location === "milton" && city === "cambridge") ||
				(location === "cambridge" && city === "milton")
			) {
				return { err: "This coupon is invalid for this location." }
			}
		}
		if (serviceToBeProvidedBy !== "any") {
			if (serviceToBeProvidedBy !== staff) {
				return {
					err: `Sorry. This coupon can only be used with: ${serviceToBeProvidedBy}`,
				}
			}
		}

		const isDateOnCouponValid = moment(expireDate, "YYYY-MM-DD").isAfter(
			moment()
		)
		if (!isDateOnCouponValid) {
			return {
				err: "This coupon has expired! Use a different one or leave it blank.",
			}
		}

		if (serviceType === "daycare") {
			return {
				err: "This coupon cannot be used for grooming services, please use a different code!",
			}
		}

		if (pass && status === "active") {
			if (
				petType === "Dog" &&
				dogService === "Bath" &&
				(serviceType === "dogBath" || serviceType === "all")
			) {
				return { success: true }
			}
			if (
				petType === "Dog" &&
				dogService === "Grooming" &&
				(serviceType === "dogGrooming" || serviceType === "all")
			) {
				return { success: true }
			}
			if (
				petType === "Cat" &&
				(serviceType === "catBath" ||
					serviceType === "catGrooming" ||
					serviceType === "all")
			) {
				return { success: true }
			}
		}

		return { err: "This coupon cannot be used for this type of service." }
	} catch (error) {
		console.error("Error validating code:", error)
		return { err: "There is something wrong with this coupon." }
	}
}

export const fileUploadHandler = async (index, values, vaccineType) => {
	try {
		const updatedImages = await Promise.all(
			values.map(async (value) => {
				const fd = new FormData()
				fd.append("file", value)
				fd.append("upload_preset", "amazing")

				const res = await axios.post(
					"https://api.cloudinary.com/v1_1/amazing-pet-grooming/image/upload",
					fd
				)

				return {
					type:
						vaccineType === "bordatella"
							? "bordatellaImgUrl"
							: "vaccineRecordImgUrl",
					url: res.data.secure_url,
				}
			})
		)

		return updatedImages
	} catch (err) {
		console.log(err)
		return []
	}
}
