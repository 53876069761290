import React from "react";

const LocationCard = ({ location, className }) => {
	return (
		<div className={`w-full lg:w-1/2 h-full flex flex-col ${className}`}>
			<h5 className="text-black text-[20px] font-medium uppercase mb-[10px]">
				{location.name}
			</h5>
			<ul className="mb-[10px] text-black">
				<li>📞 {location.phoneNumbers}</li>
				<li>📍 {location.address}</li>
			</ul>

			<div className="w-full h-full relative rounded-default overflow-hidden">
				<iframe
					title={location.map.title}
					src={location.map.link}
					width="100%"
					height="100%"
					style={{ border: "0" }}
					allowFullScreen={true}
					loading="eager"
				></iframe>

				{/* Gradient */}
				{/* <div
          className="w-full h-[12%] absolute top-0 pointer-events-none"
          style={{
            background:
              "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)",
          }}
        /> */}
			</div>
		</div>
	);
};

export default LocationCard;
