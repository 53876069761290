const FloatingWindow = ({
	isOwnerReady,
	isPetReady,
	isReleaseFormReady,
	saveAllForms,
}) => {
	return (
		<div className="w-full">
			<button
				onClick={saveAllForms}
				disabled={!isOwnerReady || !isPetReady || !isReleaseFormReady}
				className={`py-2 mt-4 font-bold px-4 bg-green-200 text-white rounded-b-md w-full  ${
					isOwnerReady && isPetReady && isReleaseFormReady
						? "button-flashing-submit"
						: ""
				}`}
			>
				SUBMIT
			</button>
			{/* <div
				className={`fixed md:top-28 top-0 lg:right-2 bg-black text-white rounded-md w-48`}
			>
				<h4 className="text-sm text-center mt-1 border-b-2">Progress Status</h4>
				<p className="text-xs m-0 text-center">
					<span className="mx-2">
						<i className="fas fa-circle text-green-500 "></i> Ready
					</span>
					<span className="mx-2">
						<i className="fas fa-circle text-yellow-100"></i> Missing Forms
					</span>
				</p>
				<div className="flex flex-row gap-4 px-4 z-50">
					<div
						className={`mt-2 ${
							isOwnerReady ? "text-green-500" : "text-yellow-100"
						}`}
					>
						<p className={`text-center m-0`}>
							<i className="fas fa-user md:text-[18px] text-sm"></i>
						</p>
						<p className="text-xs">Owner</p>
					</div>
					<div
						className={`mt-2 ${
							isPetReady ? "text-green-500" : "text-yellow-100"
						}`}
					>
						<p className={`text-center m-0`}>
							<i className="fas md:text-[18px] text-sm fa-paw"></i> <br />
						</p>
						<p className="text-xs">Pets</p>
					</div>
					<div
						className={`mt-2 ${
							isReleaseFormReady ? "text-green-500" : "text-yellow-100"
						}`}
					>
						<p className={`text-center m-0`}>
							<i className="fas fa-file-signature md:text-[18px] text-sm"></i>
						</p>
						<p className="text-xs">Release</p>
					</div>
				</div>
				<button
					onClick={saveAllForms}
					disabled={!isOwnerReady || !isPetReady || !isReleaseFormReady}
					className={`py-1 px-4 bg-purple-600 rounded-b-md w-full ${
						isOwnerReady && isPetReady && isReleaseFormReady
							? "button-flashing-submit"
							: ""
					}`}
				>
					Submit
				</button>
			</div> */}
		</div>
	)
}

export default FloatingWindow
