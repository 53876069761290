import React, { useState, useEffect, useContext } from "react"
import "./SideDrawer.css"
import { Link } from "react-router-dom"
import { RegistrationContext } from "../../contexts/registrationStatusContext"

const SideDrawer = (props) => {
	const [employeeUsername, setEmployeeUsername] = useState("")
	const [jobType, setJobType] = useState("")
	const { setSwitchToOldToolbar } = useContext(RegistrationContext)

	useEffect(() => {
		window.scrollTo(0, 0)
		const accessString = localStorage.getItem("JWT")
		if (accessString == null) {
			setEmployeeUsername("")
		} else {
			try {
				const employeeUsername = localStorage.getItem("USERNAME")
				const jobType = localStorage.getItem("JOBTYPE")
				setEmployeeUsername(employeeUsername)
				setJobType(jobType)
			} catch (error) {
				console.error(error)
			}
		}
	}, [])

	//Logout User
	const handleLogOut = (e) => {
		localStorage.clear()
		setSwitchToOldToolbar(false)
		setTimeout(() => {
			window.location.href = "/"
		}, 500)
	}

	let drawerClasses = ["side-drawer"]
	if (props.show) {
		drawerClasses = "side-drawer open"
	}

	return (
		<nav className={drawerClasses}>
			<ul>
				<li>
					<Link to="/">Home</Link>
				</li>

				<li>
					<Link to="/auth/employees_profile">My Calendar</Link>
				</li>
				<li className="usernameLoggedinHomeSidebar">
					Logged in as: {employeeUsername}
				</li>
				{jobType === "receptionist" && (
					<li>
						<Link
							className="mainDeskPanelBtnSidebar controlPanelSidebar"
							to="/auth/reception"
						>
							Main Desk Control
						</Link>
					</li>
				)}

				{jobType === "admin" && (
					<button className="adminPanelBtnSidebar">
						<Link className="adminBtnSidebar" to="/auth/admin_dashboard">
							Admin Panel
						</Link>
					</button>
				)}
				<button className="logoutBtnSidebar" onClick={handleLogOut}>
					Logout
				</button>
			</ul>
		</nav>
	)
}

export default SideDrawer
