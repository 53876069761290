import React, { useLayoutEffect, useRef } from "react";
import { HiPencil } from "react-icons/hi";
import { gsap } from "gsap";

const Tag = ({ value, onClick, hasIcon = false, disabled = false }) => {
  const container = useRef(null);
  const tl = useRef(null);
  useLayoutEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current.to(container.current, {
      duration: 0.3,
      boxShadow: "2px 4px 12px 0px rgba(0, 156, 255, 0.10)",
      y: -2,
    });
  }, []);
  return (
    <div
      className={`px-[10px] py-[5px] rounded-default bg-lightBlue border-[1px] border-main text-main text-[14px] flex items-center justify-center gap-[5px] max-w-fit cursor-pointer ${
        disabled && "opacity-50 pointer-events-none cursor-pointer"
      }}`}
      onClick={onClick}
      ref={container}
      onMouseEnter={() => {
        tl.current.play();
      }}
      onMouseLeave={() => {
        tl.current.reverse();
      }}
    >
      <p className="leading-none mb-0">{value}</p>
      {hasIcon && <HiPencil size="14" color="#65BFF8" />}
    </div>
  );
};

export default Tag;
