import React from "react";

const Cambridge = (props) => {
  return (
		<div className="p-2 border h-full lg:overflow-scroll rounded">
			<h5 className="text-black mb-0">TERMS AND CONDITIONS</h5>
			<div className="w-full h-32 sm:h-40 overflow-scroll lg:overflow-scroll flex flex-col gap-[20px] lg:h-4/5">
				<p className="text-black">
					The health of your pet is our primary concern. In case of emergency,
					you, the owner, designates AMAZING PET GROOMING as an agent and
					understand that AMAZING PET GROOMING will do whatever is appropiate
					for the well-being of your pet's health while in our care. If your pet
					becomes sick or injured and requires professional attention, we will
					attempt to contact you as soon as possible. However, if we are unable
					to reach you, AMAZING PET GROOMING at its sole discretion, may engage
					the services of a trusted Veterinarian in town and any expense shall
					be paid by you (the owner). AMAZING PET GROOMING will not be liable
					for any accident or injury to your pet caused, while using the proper
					grooming procedures. <br /> AMAZING PET GROOMING has the right to
					refuse any services at any time. In the event that your pet is too
					stressed or becomes dangerous to groom, AMAZING PET GROOMING has the
					right to refuse grooming services, stop grooming services, or cancel
					grooming services at any time before, during, or after grooming and
					client will be charged a grooming fee (for what was done up to that
					point). For the safety of the pet being groomed, as well as the
					professional pet groomer, it is asked that you.{" "}
					<span className="text-black px-[5px] bg-lightBlue font-medium">
						Do not interrupt the groomer during the grooming process.
					</span>{" "}
					If you arrive to pick up your pet and it is still being groomed,
					please
					<span className="text-black px-[5px] bg-lightBlue font-medium">
						DO NOT talk to your pet or allow him/her to see you.
					</span>{" "}
					Please step outside for a few moments until the groom is completed.
					Every effort will be made to ensure your pet is groomed as safely as
					possible, but an excited pet can be dangerous to continue to work on.
					If you insist on talking to your pet or the groomer, we reserve the
					right to end the grooming session, even if the groom is not completed
					and the full grooming price will be charged.
					<br /> AMAZING PET GROOMING will not be held responsible for allergic
					reactions resulting from manufacturer-recommended usage of such
					products. Although a pet may experience an allergic reaction to
					grooming procedures at any given time, flea and tick products are
					often associated with a higher incidence of reactions. Please consult
					a veterinarian prior to having your pet treated if you have any
					questions or concerns regarding your pet's sensitivity to such
					treatments. <br /> AMAZING PET GROOMING will not be held responsible
					for any pre-existing medical condition or the aggravation of those
					conditions, including: arthritis, heart-disease, diabetes, obesity,
					infections, skin problems, or any other medical problem that may be
					affected by the grooming process.
					<span className="text-black px-[5px] bg-lightBlue font-medium">
						Any new puppy / kitty clients being serviced in our salon must be up
						to date on all required vaccinations. Adult / senior, dogs / cats
						must be current on Rabies and Distemper. Although we don't require
						proof, you certify that this is true and can be verified at any
						time.
					</span>
				</p>
			</div>
			<div className="mt-2 w-full p-phone border-[1px] border-main bg-lightBlue rounded-default flex flex-col gap-[10px]">
				<div className="w-full flex gap-[10px] items-center">
					<input
						className="w-[20px] h-[20px] border-main border-[1px] rounded-default bg-lightBlue"
						onChange={props.toggleChangeTermsAndConditions}
						type="checkbox"
						name="checkbox"
						value={props.value}
					></input>
					<span className="text-main font-medium">
						I agree with the terms and conditions.
					</span>
				</div>
				<span className="text-main">
					Check box and fill out the form to continue.
				</span>
			</div>
		</div>
	);
};

export default Cambridge;
