import React, { useState, useEffect } from "react";
import LoadPage from "../../components/LoadingPage";
import {
	Label,
	Button,
	Form,
	FormGroup,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import "./style.scss";
import Select from "react-select";
import API from "../../utils/API";
import axios from "axios";
import { scroller, Element } from "react-scroll";
import ModalToUpdateOwner from "../../components/Modals/UpdateClient";
import { useParams, useLocation, useNavigate } from "react-router-dom";

let petTypeOptions = [
	{
		value: "Dog",
		label: "Dog",
	},
	{
		value: "Cat",
		label: "Cat",
	},
];

const CustomerAddPage = () => {
	const [pets, setPets] = useState([]);
	const [petName, setPetName] = useState(undefined);
	const [breed, setBreed] = useState(undefined);
	const [type, setType] = useState(undefined);
	const [notes, setNotes] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("");
	const [cellphone, setCellphone] = useState("");
	const [workPhone, setWorkPhone] = useState("");
	const [email, setEmail] = useState("");
	const [allowPhotoIsNotChecked, setAllowPhotoIsNotChecked] = useState(false);
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [modalToAddPet, setModalToAddPet] = useState(true);
	const [modalForEdittingPets, setModalForEdittingPets] = useState(false);
	const [modalTofinishCustomer, setModalTofinishCustomer] = useState(false);
	const [modalToUpdateOwner, setModalToUpdateOwner] = useState(false);
	const [customerIsReady, setCustomerIsReady] = useState(false);
	const [PetId, setPetId] = useState(null);
	const [ClientId, setClientId] = useState(null);
	const [petNameToEdit, setPetNameToEdit] = useState(null);
	const [petBreedToEdit, setPetBreedToEdit] = useState(null);
	const [petTypeToEdit, setPetTypeToEdit] = useState(null);
	const [petNotesToEdit, setPetNotesToEdit] = useState(null);
	const [locationSelected, setLocationSelected] = useState("");
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		getClientInformation();
		if (location.pathname.includes("cambridge")) {
			setLocationSelected("cambridge");
		}
		if (location.pathname.includes("milton")) {
			setLocationSelected("milton");
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (pets.length > 0 && !modalToAddPet) {
			scroller.scrollTo("done-with-pets-button", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			});
		}
	}, [pets, modalToAddPet]);

	const getClientInformation = async () => {
		await axios
			.get("/auth/customer_milton/" + params.regid)
			.then(async (res) => {
				setClientId(res.data.id);
				setPets(res.data.Pets);
				setFirstName(res.data.firstName);
				setLastName(res.data.lastName);
				setPrimaryPhoneNumber(res.data.primaryPhoneNumber);
				setCellphone(res.data.cellphone);
				setWorkPhone(res.data.workPhone);
				setEmail(res.data.email);
			})
			.catch((err) => console.log(err));
	};

	const toggleChangeAllowPhoto = () => {
		if (allowPhotoIsNotChecked) {
			setErrorMsg("");
		}
		setAllowPhotoIsNotChecked(!allowPhotoIsNotChecked);
	};

	const toggleModalForAddingPets = () => {
		if (modalToAddPet === false) {
			setPetName("");
			setBreed("");
			setType("");
			setNotes("");
			setErrorMsg("");
		}
		setModalToAddPet(!modalToAddPet);
		setCustomerIsReady(false);
	};

	//Add a Pet to Client
	const onSubmitPetForm = async (e) => {
		e.preventDefault();

		if (!type || !petName || !breed) {
			return setErrorMsg("Please enter the required fields above!");
		}

		let obj = {
			name: petName.replace(/^./, (str) => str.toUpperCase()),
			breed: breed.replace(/^./, (str) => str.toUpperCase()),
			type: type.value,
			notes: notes,
			allowPhoto: allowPhotoIsNotChecked,
		};

		setLoadingAxiosReq(true);

		await API.addPet(ClientId, obj)

			.then((res) => {
				console.log(res);
				setPets(res.data);
				setLoadingAxiosReq(false);
				getClientInformation();
				toggleModalForAddingPets();
			})
			.catch((error) => {
				this.setState({
					loadingAxiosReq: false,
				});
				console.log(error);
			});
	};

	const handleCustomerDone = (e) => {
		e.preventDefault();
		setTimeout(function () {
			return navigate("/");
		}, 7000);
		setModalTofinishCustomer(true);
	};

	const toggleModalForEdittingPets = () => {
		if (modalForEdittingPets) {
			setPetId("");
			setPetNameToEdit("");
			setPetBreedToEdit("");
			setPetTypeToEdit("");
			setPetNotesToEdit("");
		}
		setModalForEdittingPets(!modalForEdittingPets);
	};

	const getPetIdForUpdateFunc = async ({ currentTarget }) => {
		toggleModalForEdittingPets();
		const id = currentTarget.value;
		await API.getPet(id)
			.then((res) => {
				setPetId(res.data.id);
				setPetNameToEdit(res.data.name);
				setPetBreedToEdit(res.data.breed);
				setPetTypeToEdit(res.data.type);
				setPetNotesToEdit(res.data.notes);
			})
			.catch((error) => console.log(error));
	};

	const updatePet = async (e) => {
		e.preventDefault();

		let petObj = {
			name: petNameToEdit.replace(/^./, (str) => str.toUpperCase()),
			breed: petBreedToEdit.replace(/^./, (str) => str.toUpperCase()),
			type:
				typeof petTypeToEdit === "object" ? petTypeToEdit.value : petTypeToEdit,
			notes: petNotesToEdit,
		};
		if (!petObj.name || !petObj.breed || !petObj.type) {
			return alert("Missing a required field!");
		}
		let id = PetId;
		await API.updatePet(id, petObj)
			.then(() => {
				getClientInformation();
				toggleModalForEdittingPets();
			})
			.catch((error) => console.log(error));
	};

	const onSelectedChangedToEdit = (value) => {
		setPetTypeToEdit(value);
	};

	const onSelectedChanged = (value) => {
		setType(value);
	};

	const petsList =
		pets.length > 0 ? (
			pets.map((pet) => {
				return (
					<div key={pet.id}>
						<div className="card pet-card-customer">
							<h4 className="text-align-center">
								<i className="fas fa-paw"></i> Pet Info
								<hr />
							</h4>
							<div className=" text-align-left">
								<p className="pl-2 label-customer-addPet-page">
									Pet Name:{" "}
									<span className="text-content-addPet-page-pet">
										{" "}
										{pet.name}
									</span>
									<br />
									Breed:{" "}
									<span className="text-content-addPet-page-pet">
										{pet.breed}
									</span>
									<br />
									Type:{" "}
									<span className="text-content-addPet-page-pet">
										{pet.type}
									</span>
									<br />
									Notes:{" "}
									<span className="text-content-addPet-page-pet">
										{" "}
										{pet.notes ? pet.notes : "Nothing"}
									</span>
								</p>
								<button
									className="btn btn-info btn-block"
									value={pet.id}
									onClick={getPetIdForUpdateFunc}
								>
									Update Pet
								</button>
							</div>
						</div>
					</div>
				);
			})
		) : (
			<div>
				<h4 className="text-align-center">
					<i className="fas fa-paw"></i> PETS
				</h4>
				No pets added yet, please add them by clicking the yellow Button
			</div>
		);

	return (
		<div className=" customer-add-pet-page-container__cambridge">
			<h1 className="p-1 text-center text-custom-sm md:text-custom-md lg:text-custom-xl">
				One last step
			</h1>

			<div className="register_pet_container">
				<div className="register_pet_client_container card">
					<h2 className="text-align-center text-custom-sm md:text-custom-md lg:text-custom-xl">
						<i className="fas fa-user-circle"></i> Owner Information{" "}
						<button
							onClick={() => setModalToUpdateOwner(!modalToUpdateOwner)}
							className="btn btn-warning"
						>
							Update Owner Info
						</button>
					</h2>
					<ModalToUpdateOwner
						locationSelected={locationSelected}
						clientId={ClientId}
						modalToUpdateOwner={modalToUpdateOwner}
						toggleModalToUpdateOwner={() =>
							setModalToUpdateOwner(!modalToUpdateOwner)
						}
						firstName={firstName}
						lastName={lastName}
						primaryPhoneNumber={primaryPhoneNumber}
						cellphone={cellphone}
						workPhone={workPhone}
						email={email}
						getClientInformation={getClientInformation}
					/>
					<span>Add your pet(s), one at a time if multiple pets</span>
					<hr className="hr-black" />
					<p className="label-customer-addPet-page">
						First Name:{" "}
						<span className="text-content-addPet-page">{firstName}</span>{" "}
					</p>

					<p className="label-customer-addPet-page">
						Last Name:{" "}
						<span className="text-content-addPet-page"> {lastName}</span>{" "}
					</p>
					<p className="label-customer-addPet-page">
						Primary Phone:{" "}
						<span className="text-content-addPet-page">
							{" "}
							{primaryPhoneNumber}
						</span>{" "}
					</p>
					<p className="label-customer-addPet-page">
						Cell: <span className="text-content-addPet-page">{cellphone}</span>{" "}
					</p>
					<p className="label-customer-addPet-page">
						Emergency Phone:{" "}
						<span className="text-content-addPet-page"> {workPhone}</span>{" "}
					</p>
					<p className="label-customer-addPet-page">
						Email: <span className="text-content-addPet-page">{email}</span>{" "}
					</p>
					<Button
						className="add-pet-button-customer"
						onClick={toggleModalForAddingPets}
						color="warning"
					>
						<i className="fas fa-plus"></i> Click here to Add Pets{" "}
						<i className="fas fa-paw"></i>
					</Button>

					{customerIsReady ? (
						<>
							<h4 className="text-center">
								Final Step
								<br />
								<i className="fas fa-arrow-circle-down"></i>
							</h4>
							<Button
								block
								className="done-btn button-flashing-submit"
								onClick={handleCustomerDone}
								color="dark"
							>
								<strong>
									<i className="fas fa-check"></i> CLICK TO COMPLETE MY
									REGISTRATION {""}
									<i className="fas fa-paw"></i>
								</strong>
							</Button>
						</>
					) : null}
				</div>
				<div className="registration_pet_pet_wrapper">
					<h3 className="pets_list_title text-center mt-3">
						<i className="fas fa-arrow-circle-down"></i> My Pets{" "}
						<i className="fas fa-arrow-circle-down"></i>
					</h3>
					{petsList}
					{pets.length > 0 && !customerIsReady && (
						<Element name="done-with-pets-button">
							<Button
								onClick={handleCustomerDone}
								className="mt-8 btn-success btn-block button-flashing-submit"
							>
								<strong>Click here if you are done</strong>{" "}
							</Button>
						</Element>
					)}
					<Modal
						modalClassName="modal-cambridge-calendar"
						isOpen={modalToAddPet}
						toggle={toggleModalForAddingPets}
					>
						<ModalBody>
							<div className="form-registration-container">
								<button
									onClick={() => setModalToAddPet(false)}
									className="bg-slate-500 text-white px-1 absolute"
								>
									X
								</button>
								<form className="form-group" onSubmit={onSubmitPetForm}>
									<h4
										className="grey-text text-darken-3 text-base"
										style={{
											textAlign: "center",
											marginTop: "15px",
										}}
									>
										<i className="fas fa-paw"></i> Add your pet below <br />{" "}
										(One pet per form)
									</h4>

									<p>* Fields required</p>
									<hr
										style={{
											background: "grey",
											marginTop: "10px",
										}}
									></hr>
									<div className="input-field-registration">
										<input
											className="form-control"
											type="text"
											placeholder="* Pet Name"
											value={petName}
											name="petName"
											onChange={(e) => setPetName(e.target.value)}
										/>
									</div>
									<div className="input-field-registration">
										<input
											className="form-control"
											type="text"
											placeholder="* Breed"
											value={breed}
											name="breed"
											onChange={(e) => setBreed(e.target.value)}
										/>
									</div>
									<div className="input-field-registration">
										<Select
											value={type}
											options={petTypeOptions}
											placeholder={"* Select Pet Type"}
											isSearchable={false}
											onChange={onSelectedChanged}
											// styles={colourStyles}
										/>
									</div>
									<div className="input-field-registration">
										<textarea
											className="form-control"
											type="text"
											placeholder="Warnings / notes (Optional)"
											name="notes"
											value={notes}
											onChange={(e) => setNotes(e.target.value)}
										/>
									</div>
									<p className="agree-with-photo">
										<input
											className="checkbox-registration"
											onChange={toggleChangeAllowPhoto}
											type="checkbox"
											name="checkbox"
											value={allowPhotoIsNotChecked}
										></input>{" "}
										<span className="photo-release ">
											(OPTIONAL) <i className="fas fa-camera-retro"></i> I
											authorize Amazing Pet grooming, to publish photographs
											taken of my pet(s), for use in the Company's print, online
											and video-based marketing materials, as well as other
											Company publications. I also understand that I will not
											receive financial compensation of any type associated with
											this material.
										</span>
									</p>

									{loadingAxiosReq ? (
										<LoadPage />
									) : (
										<div className="input-field-registration">
											<Button
												color="info"
												block
												style={{
													marginTop: "10px",
													fontWeight: "700",
													fontSize: "22px",
												}}
												className="btn-primary lighten-1 z-depth-0"
											>
												Submit
											</Button>
										</div>
									)}
								</form>
								<p className="error-message-registration text-align-center">
									{errorMsg}
								</p>
							</div>
						</ModalBody>
					</Modal>

					{/* Modal for editing pets */}
					<Modal
						modalClassName="update_owner_modal"
						className="col-sm-6"
						isOpen={modalForEdittingPets}
						toggle={toggleModalForEdittingPets}
					>
						<ModalBody>
							<button
								onClick={toggleModalForEdittingPets}
								className="bg-slate-500 text-white px-1 absolute"
							>
								X
							</button>
							<Form onSubmit={updatePet}>
								<FormGroup>
									<h2 className="titleComments">Edit Your Pet</h2>
									<Label>Name</Label>
									<Input
										id="petNameToEdit"
										onChange={(e) => setPetNameToEdit(e.target.value)}
										type="text"
										name="petNameToEdit"
										defaultValue={petNameToEdit}
									/>
									<Label className="mt-2">Breed</Label>
									<Input
										id="petBreedToEdit"
										onChange={(e) => setPetBreedToEdit(e.target.value)}
										type="text"
										name="petBreedToEdit"
										defaultValue={petBreedToEdit}
									/>
									<Label className="mt-2">
										Current Type:{" "}
										{petTypeToEdit && typeof petTypeToEdit === "object"
											? petTypeToEdit.value
											: petTypeToEdit}
									</Label>
									<Select
										name="form-field-name"
										defaultValue={petTypeToEdit}
										options={petTypeOptions}
										isSearchable={false}
										onChange={onSelectedChangedToEdit}
									/>
									<Label className="mt-2">Notes</Label>
									<Input
										id="petNotesToEdit"
										onChange={(e) => setPetNotesToEdit(e.target.value)}
										type="text"
										name="petNotesToEdit"
										defaultValue={petNotesToEdit}
									/>

									<Button value={PetId} className="petEditBtn" color="warning">
										Submit Changes
									</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Modal>

					{/* Final Modal after registering */}
					<Modal
						modalClassName="modal-success-app"
						size="lg"
						isOpen={modalTofinishCustomer}
					>
						<ModalBody>
							<div className="count-down-bar">
								<h4 className="text-align-center count-down-bar_heading">
									Thank you! You have successfully registered{" "}
									<i className="fas fa-paw"></i>
								</h4>
								<video className="bg-video__content-pet" autoPlay muted loop>
									<source src="/images/dog-customer.mp4" type="video/mp4" />
									<source src="img/video.webm" type="video/webm" />
									Your browser is not supported!
								</video>
							</div>
						</ModalBody>
					</Modal>
				</div>
			</div>
		</div>
	);
};

export default CustomerAddPage;

// 	render() {

// }
