import React, { useState, useEffect, useMemo } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import axios from "axios"
import Table from "../Table"
import { useLocation, Link } from "react-router-dom"

export default function Index() {
	const location = useLocation()

	const [modal, setModal] = useState(false)
	const [modalToShowResultsByLastName, setModalToShowResultsByLastName] =
		useState(false)
	const [modalToShowResultsByPetName, setModalToShowResultsByPetName] =
		useState(false)
	const [petsForThisOwner, setPetsForThisOwner] = useState([])
	const [searchAnyWordInput, setSearchAnyWordInput] = useState("")
	const [idForThisClientOnModal, setIdForThisClientOnModal] = useState("")
	const [fullClientInfoForModal, setFullClientInfoForModal] = useState([])
	const [errorMsg, setErrorMsg] = useState("")
	const [errorMsgClients, setErrorMsgClients] = useState("")
	const [city, setCity] = useState("")
	const [searchResults, setSearchResults] = useState([])
	const [searchPetResults, setSearchPetResults] = useState([])
	const [searchResultsTrigger, setSearchResultsTrigger] = useState(true)
	const [searchResultsPetsTrigger, setSearchResultsPetsTrigger] = useState(true)

	useEffect(() => {
		if (location.pathname === "/auth/reception") {
			setCity("milton")
		} else {
			setCity("cambridge")
		}
	}, [location.pathname])

	useEffect(() => {
		if (!modalToShowResultsByLastName) {
			setErrorMsgClients("")
			setErrorMsg("")
		}
	}, [modalToShowResultsByLastName])

	useEffect(() => {
		if (!modalToShowResultsByPetName) {
			setErrorMsgClients("")
			setErrorMsg("")
		}
	}, [modalToShowResultsByPetName])

	useEffect(() => {
		if (searchResults.length) {
			setSearchResultsTrigger(true)
			setModalToShowResultsByLastName(!modalToShowResultsByLastName)
		} else {
			setSearchResultsTrigger(false)
		}
	}, [searchResults])

	useEffect(() => {
		if (searchPetResults.length) {
			setSearchResultsPetsTrigger(true)
			setModalToShowResultsByPetName(true)
		} else {
			setSearchResultsPetsTrigger(false)
		}
	}, [searchPetResults])

	const searchClient = async (e) => {
		e.preventDefault()
		const accessString = localStorage.getItem("JWT")

		let anyWord = searchAnyWordInput

		if (/^[\/?]+$/.test(searchAnyWordInput)) {
			setSearchAnyWordInput("")
			return
		}

		if (!anyWord) {
			setSearchAnyWordInput("")
			setModalToShowResultsByLastName(false)
			return
		}

		if (location.pathname === "/auth/reception") {
			await axios
				.get(`/search/clients/${anyWord}`, {
					headers: { jwt: `${accessString}` },
				})
				.then(async (resClient) => {
					if (resClient.data.length) {
						setSearchResults(resClient.data)
						setErrorMsgClients("Showing results for Clients")
					} else {
						setErrorMsgClients("No results for Clients")
					}
					await axios
						.get(`/search/pets/${anyWord}`, {
							headers: { jwt: `${accessString}` },
						})
						.then((res) => {
							console.log(res)
							if (res.data.length) {
								setSearchPetResults(res.data)
								setErrorMsg("Showing results for Pets")
							} else {
								setErrorMsg("No results for Pets")
							}
						})
						.catch((err) => console.log(err.response))
				})
				.catch((err) => console.log(err.response))
		} else {
			// await getAllPetsCambridge();
			await axios
				.get(`/api/clients_cambridge/${anyWord}`, {
					headers: { jwt: `${accessString}` },
				})
				.then(async (resClient) => {
					if (resClient.data.length) {
						setSearchResults(resClient.data)
						setErrorMsgClients("Showing results for Clients")
					} else {
						setErrorMsgClients("No results for Clients")
					}
					await axios
						.get(`/api/search/pets_cambridge/${anyWord}`, {
							headers: { jwt: `${accessString}` },
						})
						.then((res) => {
							if (res.data.length) {
								setSearchPetResults(res.data)
								setErrorMsg("Showing results for Pets")
							} else {
								setErrorMsg("No results for Pets")
							}
						})
						.catch((err) => console.log(err.response))
				})
				.catch((err) => console.log(err.response))
		}
	}

	const columnsClientResults = useMemo(
		() => [
			{
				Header: "Client Search Result",
				columns: [
					{
						Header: "Client #",
						accessor: "id",
					},
					{
						Header: "First Name",
						accessor: "firstName",
					},
					{
						Header: "Last Name",
						accessor: "lastName",
					},
					{
						Header: "Primary Phone",
						accessor: "primaryPhoneNumber",
					},
					{
						Header: "Cell",
						accessor: "cellphone",
					},
					{
						Header: "Email",
						accessor: "email",
					},
					{
						Header: "Pets",
						accessor: (data) => {
							let output = []
							if (location.pathname === "/auth/reception" && data.Pets) {
								data.Pets.map((item) => {
									return output.push(`${item.name} (${item.breed})`)
								})
							} else if (data.PetCambridges) {
								data.PetCambridges.map((item) => {
									return output.push(`${item.name} (${item.breed})`)
								})
							}
							return output.join(", ")
						},
					},
					{
						Header: "Action",
						Cell: (tableProps) => {
							const clientId = tableProps.row.original.id
							const currentCity =
								location.pathname === "/auth/reception" ? "milton" : "cambridge"
							return (
								<Link
									to={`/clients/${currentCity}/${clientId}`}
									className="btn btn-info btn-block"
								>
									Info
								</Link>
							)
						},
					},
				],
			},
		],
		[]
	)

	const columnsPetResults = useMemo(
		() => [
			{
				Header: "Pet Search Result",
				columns: [
					{
						Header: "Client #",
						accessor: `${
							localStorage.getItem("CITY") === "milton"
								? "ClientId"
								: "ClientCambridgeId"
						}`,
					},
					{
						Header: "Owner Info",
						Cell: (tableProps) => {
							const clientId =
								localStorage.getItem("CITY") === "milton"
									? tableProps.row.original.ClientId
									: tableProps.row.original.ClientCambridgeId

							return (
								<button
									value={clientId}
									style={{
										border: "4px solid white",
										borderRadius: "5px",
										display: "block",
									}}
									className="btn btn-warning btn-block"
									onClick={getFullClientInfoOnModal}
								>
									OWNER INFO
								</button>
							)
						},
					},
					{
						Header: "Pet Name",
						accessor: "name",
					},
					{
						Header: "Breed",
						accessor: "breed",
					},
					{
						Header: "Type",
						accessor: "type",
					},
					{
						Header: "Full Info",
						Cell: (tableProps) => {
							const clientId =
								localStorage.getItem("CITY") === "milton"
									? tableProps.row.original.ClientId
									: tableProps.row.original.ClientCambridgeId
							const currentCity =
								localStorage.getItem("CITY") === "milton"
									? "milton"
									: "cambridge"
							return (
								<Link
									to={`/clients/${currentCity}/${clientId}`}
									className="btn btn-info btn-block"
								>
									Info
								</Link>
							)
						},
					},
				],
			},
		],
		[]
	)

	const getFullClientInfoOnModal = async ({ currentTarget }) => {
		setModal(true)

		const clientId = currentTarget.value
		let accessString = localStorage.getItem("JWT")
		if (location.pathname === "/auth/reception") {
			await axios
				.get("/auth/api/clients/" + clientId, {
					headers: { JWT: accessString },
				})
				.then(async (res) => {
					setFullClientInfoForModal(res.data)
					setPetsForThisOwner(res.data.Pets)
					setIdForThisClientOnModal(res.data.id)
				})
				.catch((error) => console.log(error))
		} else {
			await axios
				.get("/auth/api/clients_cambridge/" + clientId, {
					headers: { JWT: accessString },
				})
				.then(async (res) => {
					setFullClientInfoForModal(res.data)
					setPetsForThisOwner(res.data.PetCambridges)
					setIdForThisClientOnModal(clientId)
				})
				.catch((error) => console.log(error))
		}
	}

	const clientFullInformation = fullClientInfoForModal
	const petsArray = petsForThisOwner
	const petsListFormModal = petsArray.length
		? petsArray.map((pet) => {
				return (
					<div className="card" key={pet.id}>
						<p className="petsCard">
							Name: {pet.name} <br></br> {""} Breed: {pet.breed}
						</p>
					</div>
				)
		  })
		: null

	return (
		<div className="mt-3 searchBoxContainerr">
			<div className="searchBox-wrapper ">
				<div className="searchBox__3 searchForm_receptionist">
					<form onSubmit={searchClient}>
						<h6 className="searchBoxTitles">
							Search Clients by first name, last name, email, phone, pet name,
							breed... <i className="fas fa-user"></i>
						</h6>
						<input
							className="search-input-reception"
							onChange={(e) => setSearchAnyWordInput(e.target.value)}
							name="searchAnyWordInput"
							placeholder="Type here"
							value={searchAnyWordInput}
						/>
						<button className="searchButtons">
							Search <i className="fas fa-search"></i>
						</button>
						<div
							style={{
								padding: "5px",
								textAlign: "center",
								color: "yellow",
							}}
						>
							<p>{errorMsg}</p>
							<p>{errorMsgClients}</p>
						</div>
					</form>
				</div>
			</div>

			{/* Modal to show client results  */}
			<div className="col-md-12">
				<Modal
					modalClassName="modal-cambridge-calendar"
					className="modal-xl"
					isOpen={modalToShowResultsByLastName}
					toggle={() =>
						setModalToShowResultsByLastName(!modalToShowResultsByLastName)
					}
				>
					<ModalHeader
						toggle={() =>
							setModalToShowResultsByLastName(!modalToShowResultsByLastName)
						}
					>
						<div>
							<h4>Search Results</h4>
						</div>
					</ModalHeader>
					<ModalBody>
						<Table columns={columnsClientResults} data={searchResults} />
					</ModalBody>
				</Modal>
			</div>

			{/* Modal to show Owner Information after pet Name results */}
			<div>
				<Modal
					modalClassName="modal-cambridge-calendar"
					isOpen={modal}
					toggle={() => setModal(!modal)}
				>
					<ModalHeader toggle={() => setModal(!modal)}>
						Client Number: {clientFullInformation.id}
						<Link
							style={{
								width: "150px",
								borderRadius: "5px",
								border: "1px solid black",
								marginLeft: "100px",
								marginTop: "15px",
							}}
							className="btn btn-success"
							to={
								city === "milton"
									? `/clients/${
											location.pathname === "/auth/reception"
												? "milton"
												: "cambridge"
									  }/${clientFullInformation.id}`
									: "/clients/cambridge/" + clientFullInformation.id
							}
						>
							Client's Profile...
						</Link>
					</ModalHeader>
					<ModalBody>
						<div className="card ownerInfoModal">
							<p>First Name: {clientFullInformation.firstName}</p>
							<p>Last Name: {clientFullInformation.lastName}</p>
							<p>Phone Number: {clientFullInformation.primaryPhoneNumber}</p>
							<div>Pets: {petsListFormModal}</div>
						</div>
					</ModalBody>
				</Modal>
			</div>

			{/* Modal to show results By Pet Name */}
			<div className="col-md-12">
				<Modal
					modalClassName="modal-cambridge-calendar"
					className="modal-lg"
					isOpen={modalToShowResultsByPetName}
					toggle={() =>
						setModalToShowResultsByPetName(!modalToShowResultsByPetName)
					}
				>
					<ModalHeader
						toggle={() =>
							setModalToShowResultsByPetName(!modalToShowResultsByPetName)
						}
					>
						<div>
							<h4>Search Results</h4>
						</div>
					</ModalHeader>
					<ModalBody>
						<Table columns={columnsPetResults} data={searchPetResults} />
					</ModalBody>
				</Modal>
			</div>
		</div>
	)
}
