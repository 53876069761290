import React, { useState } from "react"
import Select from "react-select"
import axios from "axios"
import { teamMembers } from "../../utils/constants"

const DiscountForm = () => {
	const [formData, setFormData] = useState({
		name: "",
		type: "percent",
		value: "",
		code: "",
		description: "",
		status: "active",
		expireDate: "",
		serviceType: "",
		serviceToBeProvidedBy: "",
		location: "",
	})

	const discountTypeOptions = [
		{ value: "percent", label: "Percent" },
		{ value: "amount", label: "Amount" },
	]

	const statusOptions = [
		{ value: "active", label: "Active" },
		{ value: "inactive", label: "Inactive" },
	]

	const serviceTypeOptions = [
		{ value: "all", label: "All Services" },
		{ value: "daycare", label: "Daycare" },
		{ value: "dogBath", label: "Dog Bath" },
		{ value: "dogGrooming", label: "Dog Grooming" },
		{ value: "catBath", label: "Cat Bath" },
		{ value: "catGrooming", label: "Cat Grooming" },
	]
	const serviceToBeProvidedByOptions = [
		{ value: "any", label: "ANY" },
		...Object.values(teamMembers).map(
			(member) =>
				member && {
					value: member.name,
					label: member.name.toUpperCase(),
				}
		),
	]
	const locationOptions = [
		{ value: "all", label: "All" },
		{ value: "milton", label: "Milton" },
		{ value: "cambridge", label: "Cambridge" },
	]

	const handleChange = (e) => {
		const { name, value } = e.target
		setFormData((prevData) => ({ ...prevData, [name]: value }))
	}

	const handleSelectChange = (selectedOption, field) => {
		console.log(field)
		console.log(selectedOption)
		setFormData((prevData) => ({ ...prevData, [field]: selectedOption.value }))
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			const token = localStorage.getItem("JWT")
			const response = await axios.post("/api/discount/add", formData, {
				headers: { jwt: `${token}` },
			})
			alert("Discount added successfully!")
			setFormData({
				name: "",
				type: "percent",
				value: "",
				code: "",
				description: "",
				status: "active",
				expireDate: "",
				serviceType: "",
				serviceToBeProvidedBy: "",
				location: "",
			})
		} catch (error) {
			console.error("Error adding discount:", error)
			alert("Failed to add discount. Please try again.")
		}
	}

	return (
		<div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg">
			<h2 className="text-2xl font-bold text-center mb-2">Add New Discount</h2>
			<form onSubmit={handleSubmit} className="space-y-2">
				<div>
					<label className="block text-gray-700 font-semibold">
						Discount Name
					</label>
					<input
						type="text"
						name="name"
						value={formData.name}
						onChange={handleChange}
						className="w-full p-2 border border-gray-300 rounded"
						required
					/>
				</div>

				<div>
					<label className="block text-gray-700 font-semibold">
						Discount Type
					</label>
					<Select
						options={discountTypeOptions}
						onChange={(option) => handleSelectChange(option, "type")}
						value={discountTypeOptions.find(
							(option) => option.value === formData.type
						)}
						className="mt-1"
					/>
				</div>

				<div>
					<label className="block text-gray-700 font-semibold">Value</label>
					<input
						type="number"
						name="value"
						value={formData.value}
						onChange={handleChange}
						className="w-full p-2 border border-gray-300 rounded"
						required
					/>
				</div>

				<div>
					<label className="block text-gray-700 font-semibold">
						Discount Code
					</label>
					<input
						type="text"
						name="code"
						value={formData.code}
						onChange={handleChange}
						className="w-full p-2 border border-gray-300 rounded"
					/>
				</div>

				<div>
					<label className="block text-gray-700 font-semibold">
						Description
					</label>
					<input
						name="description"
						value={formData.description}
						onChange={handleChange}
						className="w-full p-2 border border-gray-300 rounded"
					></input>
				</div>
				<div>
					<label className="block text-gray-700 font-semibold">
						Service Type
					</label>
					<Select
						options={serviceTypeOptions}
						onChange={(option) => handleSelectChange(option, "serviceType")}
						value={serviceTypeOptions.find(
							(option) => option.value === formData.serviceTypeOptions
						)}
						className="mt-1"
					/>
				</div>
				<div>
					<label className="block text-gray-700 font-semibold">Staff</label>
					<Select
						options={serviceToBeProvidedByOptions}
						onChange={(option) =>
							handleSelectChange(option, "serviceToBeProvidedBy")
						}
						value={serviceToBeProvidedByOptions.find(
							(option) => option.value === formData.serviceToBeProvidedBy
						)}
						className="mt-1"
					/>
				</div>
				<div>
					<label className="block text-gray-700 font-semibold">Location</label>
					<Select
						options={locationOptions}
						onChange={(option) => handleSelectChange(option, "location")}
						value={locationOptions.find(
							(option) => option.value === formData.location
						)}
						className="mt-1"
					/>
				</div>

				<div>
					<label className="block text-gray-700 font-semibold">Status</label>
					<Select
						options={statusOptions}
						onChange={(option) => handleSelectChange(option, "status")}
						value={statusOptions.find(
							(option) => option.value === formData.status
						)}
						className="mt-1"
					/>
				</div>

				<div>
					<label className="block text-gray-700 font-semibold">
						Expiration Date
					</label>
					<input
						type="date"
						name="expireDate"
						value={formData.expireDate}
						onChange={handleChange}
						className="w-full p-2 border border-gray-300 rounded"
					/>
				</div>

				<button
					type="submit"
					className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
				>
					Add Discount
				</button>
			</form>
		</div>
	)
}

export default DiscountForm
