import React, { useContext, useState, useEffect } from "react"
import { LiaTimesSolid } from "react-icons/lia"
import { useNavigate } from "react-router-dom"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import { Link } from "react-router-dom"

const Cart = ({ cart, onUpdateCart }) => {
	const { setCartModal, setIsMenuOpen } = useContext(RegistrationContext)
	const [totalPrice, setTotalPrice] = useState(0)
	const [subTotalPrice, setSubTotalPrice] = useState(0)
	const [taxPrice, setTaxPrice] = useState(0)
	const navigate = useNavigate()

	useEffect(() => {
		const subTotal = cart.reduce(
			(acc, item) => acc + item.price * item.quantity,
			0
		)
		setSubTotalPrice(subTotal)
		setTaxPrice((subTotal * 13) / 100)
		setTotalPrice((subTotal * 13) / 100 + subTotal)
	}, [cart])

	const handleRemove = (id) => {
		onUpdateCart((prevCart) => prevCart.filter((item) => item.id !== id))
	}

	const handleQuantityChange = (id, newQuantity) => {
		if (newQuantity <= 0) return
		onUpdateCart((prevCart) =>
			prevCart.map((item) =>
				item.id === id ? { ...item, quantity: newQuantity } : item
			)
		)
	}

	return (
		<div className="bg-white rounded-lg p-1 mx-auto ">
			<h2 className="text-lg font-bold mb-1 text-center">Cart</h2>
			<ul className="space-y-1">
				{cart.map((item) => (
					<li
						key={item.id}
						className="flex justify-between items-center bg-gray-100 p-2 rounded-md shadow-sm"
					>
						<span className="flex items-center">
							{item.name} (<LiaTimesSolid />
							<input
								type="number"
								min="1"
								value={item.quantity}
								onChange={(e) =>
									handleQuantityChange(item.id, Number(e.target.value))
								}
								className="w-16 mx-2 border rounded-md px-2 py-1 focus:outline-none"
							/>
							)
						</span>
						<div>
							<button
								onClick={() => handleRemove(item.id)}
								className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
							>
								Remove
							</button>
						</div>
					</li>
				))}
			</ul>
			{cart.length ? (
				<>
					<div className="flex justify-between">
						<span>Subtotal:</span>
						<span>${subTotalPrice.toFixed(2)}</span>
					</div>
					<div className="flex justify-between">
						<span>Estimated GST/HST:</span>
						<span>${taxPrice.toFixed(2)}</span>
					</div>
					<div className="flex justify-between">
						<span>Total Price:</span>
						<span>${totalPrice.toFixed(2)}</span>
					</div>
					<button
						onClick={() => {
							setCartModal(false)
							setIsMenuOpen(false)
							navigate("/checkout")
						}}
						className="mt-4 bg-green-500 text-white px-4 py-2 rounded-md w-full hover:bg-green-600"
					>
						Go to Checkout
					</button>
				</>
			) : (
				<>
					<div className="flex justify-center">
						<img className="w-30" src="/images/funny_dog.png" alt="funny dog" />
					</div>
					<p className="text-center mt-4">
						Your cart is empty!{" "}
						<span
							className="text-blue-500 cursor-pointer"
							onClick={() => {
								setCartModal(false)
								setIsMenuOpen(false)
								navigate("/store")
							}}
						>
							Visit our store
						</span>
					</p>
				</>
			)}
		</div>
	)
}

export default Cart
