import { useTable, usePagination } from "react-table";
import "./style.css";

export default function Table({ columns, data }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
			},
		},
		usePagination
	);
	return (
		<div className="table-container">
			<table className="table-custom" {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render("Header")}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			{/* Pagination UI */}
			{data.length > pageSize && (
				<div className="flex justify-center mt-2 p-1">
					<span className="mr-4 hidden md:block mt-2">
						Page <strong>{pageIndex + 1}</strong> of{" "}
						<strong>{pageOptions.length}</strong>
					</span>
					<button
						className="px-4 py-2 bg-blue-400 text-white rounded disabled:bg-gray-300"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						First
					</button>
					<button
						className="px-4 py-2 ml-2 bg-blue-400 text-white rounded disabled:bg-gray-300"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						Previous
					</button>
					<button
						className="px-4 py-2 ml-2 bg-blue-400 text-white rounded disabled:bg-gray-300"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						Next
					</button>
					<button
						className="px-4 py-2 ml-2 bg-blue-400 text-white rounded disabled:bg-gray-300"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						Last
					</button>
				</div>
			)}
		</div>
	);
}
