export default function ReleaseGroomingForm_Content({
	isWithinDaycareRelease,
	includeVaccinePart,
}) {
	return (
		<div>
			<p className="mb-4">
				The health of your pet is our primary concern. In case of emergency,
				you, the owner, designates AMAZING PET GROOMING as an agent and
				understands that AMAZING PET GROOMING will do whatever is appropiate for
				the well-being of your pet's health while in our care. If your pet
				becomes sick or injured and requires professional attention, we will
				attempt to contact you as soon as possible. However, if we are unable to
				reach you, AMAZING PET GROOMING at its sole discretion, may engage the
				services of any qualified Veterinarian in town and any expense shall be
				paid by you (the owner). AMAZING PET GROOMING will not be liable for any
				accident or injury to your pet caused while using the proper grooming
				procedures.
			</p>

			<p className="mb-4">
				AMAZING PET GROOMING has the right to refuse any services at any time.
				In the event that your pet is too stressed or becomes dangerous to
				groom, AMAZING PET GROOMING has the right to refuse grooming services,
				stop grooming services, or cancel grooming services at any time before,
				during, or after grooming and the owner will be charged a grooming fee
				(for what was done up until that point). For the safety of the pet being
				groomed, as well as the professional pet groomer, it is asked that you{" "}
				<span className="terms-and-conditions-important">
					Do not interrupt the groomer during the grooming process.
				</span>{" "}
				If you arrive to pick up your pet and it is still being groomed, please{" "}
				<span className="terms-and-conditions-important">
					DO NOT talk to your pet or allow him/her to see you.{" "}
				</span>{" "}
				Please step outside for a few moments until the groom is completed.
				Every effort will be made to insure your pet is groomed as safely as
				possible, but an excited pet can be dangerous to continue to work on. If
				you insist on talking to your pet or the groomer, we reserve the right
				to end the grooming session, even if the groom is not completed and the
				full grooming price will be charged to the owner.
			</p>

			<p className="mb-4">
				AMAZING PET GROOMING will not be held responsible for allergic reactions
				resulting from manufacturer-recommended usage of such products. Although
				a pet may experience an allergic reaction to grooming procedures at any
				given time, flea and tick products are often associated with a higher
				incidence of reactions. Please consult a veterinarian prior to having
				your pet treated if you have any questions or concerns regarding your
				pet's sensitivity to such treatments.
			</p>

			<p className="mb-4">
				AMAZING PET GROOMING will not be held responsible for any pre-existing
				medical condition or the aggravation of those conditions, including:
				arthritis, heart-disease, diabetes, obesity, infections, skin problems,
				or any other medical problem that may be affected by the grooming
				process.
				<br />
				{!isWithinDaycareRelease && (
					<span className="terms-and-conditions-important-vaccines">
						Any new puppy / kitty clients being serviced in our salon must be up
						to date on all required vaccinations. Adult / senior, dogs / cats
						must be current on Rabies and Distemper.{" "}
						{includeVaccinePart &&
							"Although we don't require proof for grooming, you certify that this is true and it can be verified at any time."}
					</span>
				)}
			</p>
		</div>
	)
}
