import React, { useEffect } from "react"
import { Form, FormGroup, Input, Label } from "reactstrap"
import { useLocation } from "react-router-dom" // Import useLocation

const OwnerFormComponent = ({
	redInputHandler,
	setLastName,
	setFirstName,
	setPrimaryPhoneNumber,
	setCellphone,
	setWorkPhone,
	setAddress,
	setEmail,
	setEmail2,
	lastName,
	firstName,
	primaryPhoneNumber,
	cellphone,
	workPhone,
	address,
	email,
	email2,
	isOwnerButtonReady,
	isNotCard,
	isCustomMargin,
	agreeMarketing,
	handleCheckboxMarketingChange,
	emailError,
}) => {
	const isSpecificPath =
		location.pathname === "/grooming_registration" ||
		location.pathname === "/daycare_registration"

	return (
		<div
			className={`${isCustomMargin ? "px-2" : "px-8"} ${
				!isCustomMargin && "py-8 "
			} mt-2 rounded-md ${!isNotCard && "shadow-lg"}  ${
				isOwnerButtonReady ? "bg-green-200" : "bg-white"
			} bg-opacity-70`}
		>
			<Form>
				<p className={`${isNotCard && "mt-4"}`}>* Fields required</p>
				<hr />
				<FormGroup>
					<div className="form-group">
						<Label>* Last Name: </Label>
						<Input
							type="text"
							name="lastName"
							className={redInputHandler(lastName)}
							defaultValue={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<Label>* First Name: </Label>
						<Input
							type="text"
							name="firstName"
							className={redInputHandler(firstName)}
							defaultValue={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<Label>* Primary Phone: </Label>
						<Input
							type="text"
							name="primaryPhoneNumber"
							className={redInputHandler(primaryPhoneNumber)}
							defaultValue={primaryPhoneNumber}
							onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<Label>Cell (Optional): </Label>
						<Input
							type="text"
							name="cellphone"
							defaultValue={cellphone}
							onChange={(e) => setCellphone(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<Label>Work Phone (Optional): </Label>
						<Input
							type="text"
							name="workPhone"
							defaultValue={workPhone}
							onChange={(e) => setWorkPhone(e.target.value)}
						/>
					</div>
					<div className={`form-group`}>
						<Label>Address (Optional): </Label>
						<Input
							type="text"
							name="address"
							// className={`${!address ? "border-red-input" : ""}`}
							defaultValue={address}
							onChange={(e) => setAddress(e.target.value)}
						/>
					</div>
					<div className="mt-4 form-group">
						<Label>Email: </Label>
						<Input
							type="email"
							name="email"
							// className={redInputHandler(email)}
							defaultValue={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="mt-4 form-group">
						<Label>* Confirm Email: </Label>
						<Input
							type="email"
							name="email2"
							// className={redInputHandler(email2)}
							defaultValue={email2}
							onChange={(e) => setEmail2(e.target.value)}
						/>
					</div>

					{/* ONLY SHOWS ON CLIENT'S REGISTRATION FORM */}
					{isSpecificPath && (
						<div className="mt-8">
							<label className="flex text-lg ">
								<input
									type="checkbox"
									checked={agreeMarketing}
									onChange={handleCheckboxMarketingChange}
									className="mr-2 w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
								/>
								<span className="text-md">
									I agree to receive promotional emails and updates from Amazing
									Pet Grooming. I understand that I can unsubscribe from
									promotional emails at any time. However, I may still receive
									essential service-related communications.
								</span>
							</label>
						</div>
					)}
				</FormGroup>
				<p className="mt-4 text-center error-message-registration">
					{emailError}
				</p>
			</Form>
		</div>
	)
}

export default OwnerFormComponent
