import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"

const PurchaseDetails = () => {
	const { id } = useParams()
	const [purchaseDetails, setPurchaseDetails] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	useEffect(() => {
		const fetchPurchaseDetails = async () => {
			try {
				const response = await axios.get(`/api/clients/purchase/${id}`)
				setPurchaseDetails(response.data)
			} catch (err) {
				setError("Failed to load purchase details. Please try again.")
			} finally {
				setLoading(false)
			}
		}

		fetchPurchaseDetails()
	}, [id])

	if (loading) {
		return (
			<div className="text-center mt-36">
				<p className="text-lg text-gray-600">Loading...</p>
			</div>
		)
	}

	if (error) {
		return (
			<div className="text-center mt-36">
				<img
					src="/images/funny_dog.png"
					alt="Error"
					className="w-40 mx-auto mb-4"
				/>
				<p className="text-lg text-red-500">{error}</p>
			</div>
		)
	}

	return (
		<div className="container mx-auto px-4 py-8 mt-24">
			<div className="text-center mb-8">
				<h2 className="text-2xl font-semibold text-gray-800">
					Purchase Confirmation
				</h2>
				<p className="text-lg text-gray-600">Thank you for your purchase!</p>
			</div>

			<div className="bg-white shadow-lg rounded-lg p-6 mb-8">
				<h3 className="text-xl font-bold text-gray-800 mb-4">
					Purchase Details
				</h3>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					<div className="space-y-4">
						<div>
							<h5 className="font-medium text-gray-600">Email:</h5>
							<p>{purchaseDetails.email}</p>
						</div>
						<div>
							<h5 className="font-medium text-gray-600">Order ID:</h5>
							<p>{purchaseDetails.uid}</p>
						</div>
						<div>
							<h5 className="font-medium text-gray-600">Date:</h5>
							<p>{new Date(purchaseDetails.createdAt).toLocaleDateString()}</p>
						</div>
					</div>

					<div className="space-y-4">
						<div>
							<h5 className="font-medium text-gray-600">Sub Total:</h5>
							<p>${Number(purchaseDetails.subTotal).toFixed(2)}</p>
						</div>
						<div>
							<h5 className="font-medium text-gray-600">Estimated GST/HST:</h5>
							<p>
								${((Number(purchaseDetails.subTotal) * 13) / 100).toFixed(2)}
							</p>
						</div>
						<div>
							<h5 className="font-medium text-gray-600">Total Amount:</h5>
							<p>${Number(purchaseDetails.amount).toFixed(2)}</p>
						</div>
					</div>
				</div>
			</div>

			<div>
				<h3 className="text-xl font-bold text-gray-800 mb-4">
					Products Purchased
				</h3>
				{purchaseDetails.items.map((item) => {
					const product = item.product
					const quantity = Number(item.qty)
					const pricePerItem = Number(product.price)

					const giftCardCodes = purchaseDetails.giftCards
						.filter((giftCard) => giftCard.productId === product.id)
						.map((giftCard) => giftCard.code)

					return (
						<div className="bg-white shadow-lg rounded-lg p-6" key={item.id}>
							<h4 className="text-lg text-center font-semibold text-gray-800">
								{product.name}
							</h4>
							<hr />
							<div className="flex flex-col md:flex-row gap-6 hover:shadow-xl transition-shadow duration-300">
								<div className="w-full md:w-1/3 rounded-lg overflow-hidden flex-shrink-0">
									<img
										src={product.imgUrl || "/images/default-product.png"}
										alt={product.name || "Product"}
										className="w-full h-60 object-cover shadow-md"
									/>
								</div>
								<div className="flex-1">
									<p className="text-gray-600">
										<strong>Description:</strong> {product.description || "N/A"}
									</p>
									<p className="text-gray-600">
										<strong>Quantity:</strong> {quantity}
									</p>
									<p className="text-gray-600">
										<strong>Price per Item:</strong> ${pricePerItem.toFixed(2)}
									</p>
									<p className="text-gray-600">
										<strong>Total for Item:</strong> $
										{Number(item.amount).toFixed(2)}
									</p>

									{/* Check if the product type is 'gift_card' */}
									{product.type === "gift_card" && giftCardCodes.length > 0 && (
										<div>
											<strong className="mr-2">
												Gift Card Code{giftCardCodes.length > 1 ? "s" : ""} (
												{giftCardCodes.length}):
											</strong>
											<ol className="text-gray-600 list-decimal pl-6">
												{giftCardCodes.map((code, index) => (
													<li key={index} className="mt-1">
														{code}
													</li>
												))}
											</ol>
										</div>
									)}
								</div>
							</div>
						</div>
					)
				})}
			</div>

			<hr />
			<div className="mt-8 text-center">
				<p className="text-lg text-gray-600">Thank you for shopping with us!</p>
				<img
					src="/images/funny_dog.png"
					alt="Thank You"
					className="w-32 h-32 mx-auto mt-6"
				/>
			</div>
		</div>
	)
}

export default PurchaseDetails
