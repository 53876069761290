import React, { useState, useEffect, useContext, useRef } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { gsap } from "gsap"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import { CustomButton } from "../CustomButton"
import Cookies from "js-cookie"
import FloatingCartIcon from "../FloatingCartIcon"
import { GiShoppingBag } from "react-icons/gi"
import { MdFiberNew } from "react-icons/md"
import MobileMenu from "../MobileMenu"

const Toolbar = (props) => {
	const location = useLocation()
	const navigate = useNavigate()
	const [employeeUsername, setEmployerUsername] = useState("")
	const [showSubItems, setShowSubItems] = useState(false)
	const [showSubItemsRegistration, setShowSubItemsRegistration] =
		useState(false)
	const [authorized, setAuthorized] = useState(false)
	const [jobType, setJobType] = useState("")

	// REFS
	const mainDesktopMenuContainer = useRef(null)

	useEffect(() => {
		animateItemsDesktopMain()
		window.scrollTo(0, 0)
	}, [])

	const {
		isRegistrationInProcess,
		setIsRegistrationInProcess,
		setLimitedAccessToCambridgeAdmin,
		setSwitchToOldToolbar,
		setShowLoginModal,
		setShowRegisterDaycareModal,
		showLoginModal,
		showRegisterDaycareModal,
		setShowRegisterGroomingModal,
		isAuthenticated,
		isMenuOpen,
		setIsMenuOpen,
	} = useContext(RegistrationContext)

	useEffect(() => {
		if (window.innerWidth < 912) props.closeSideDrawerHandler()
		// eslint-disable-next-line
	}, [location.pathname, isRegistrationInProcess])

	const accessString = localStorage.getItem("JWT")
	useEffect(() => {
		window.scrollTo(0, 0)
		if (accessString == null) {
			setAuthorized(false)
		} else {
			try {
				const employeeUsername = localStorage.getItem("USERNAME")
				const jobType = localStorage.getItem("JOBTYPE")
				setEmployerUsername(employeeUsername)
				setAuthorized(true)
				setJobType(jobType)
			} catch (error) {
				console.error(error)
			}
		}
	}, [accessString])

	// 	//Logout User
	const handleLogOut = (e) => {
		e.preventDefault()
		localStorage.clear()
		setSwitchToOldToolbar(false)
		setLimitedAccessToCambridgeAdmin(false)
		setTimeout(() => {
			window.location.href = "/"
		}, 500)
	}

	const registrationStatusChecker = (navLink) => {
		if (!isRegistrationInProcess) {
			return
		} else if (
			window.confirm(
				"Your registration is not complete yet! Are you sure you want to leave? Your progress will be lost"
			)
		) {
			setIsRegistrationInProcess(false)
			localStorage.clear()
			navigate(`/${navLink}`)
		}
	}

	//Animation for desktop menu.
	const animateItemsDesktopMain = () => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline()
			tl.fromTo(
				".itemMainDesktopMenu",
				{
					opacity: 0,
					y: 20,
				},
				{
					opacity: 1,
					y: 0,
					duration: 0.5,
					stagger: 0.1,
				}
			)
		}, mainDesktopMenuContainer)
		return () => ctx.revert()
	}

	const logoutHandler = () => {
		Cookies.remove("authtoken")
		Cookies.remove("client_location")
		localStorage.removeItem("CITY")
		window.location.reload()
	}

	return (
		<nav className={`w-full items-center justify-start`}>
			{/* Desktop */}
			<div className="fixed bg-white z-[1000] w-full top-0">
				<ul
					className={`lg:flex hidden flex-row items-center justify-between pt-phone px-phone md:px-tablet lg:px-desktop`}
					ref={mainDesktopMenuContainer}
				>
					<li
						className="cursor-pointer select-none itemMainDesktopMenu"
						onClick={() => {
							isRegistrationInProcess && registrationStatusChecker("")
							window.scrollTo(0, 0)
						}}
					>
						<Link to={!isRegistrationInProcess && "/"} className="text-black">
							<img
								className="w-[100px]"
								src="/images/newLogo.png"
								alt="Amazing pet grooming logo"
							/>
						</Link>
					</li>
					<li>
						<ul className="w-full flex items-center gap-[20px] text-black relative">
							{!authorized ? (
								<>
									{process.env.REACT_APP_TESTING_ADMIN_MODE !== "on" && (
										<li
											className="cursor-pointer select-none itemMainDesktopMenu"
											onClick={() => {
												isRegistrationInProcess &&
													registrationStatusChecker("gallery")
											}}
										>
											<Link
												to={!isRegistrationInProcess && "/store"}
												className="text-main hover:text-[#019DFF] hover:no-underline"
											>
												<MdFiberNew
													size={30}
													color="orange"
													className="inline mr-1"
												/>
												Store
												<GiShoppingBag className="inline ml-1" />
											</Link>
										</li>
									)}
									<li
										className="relative z-10 cursor-pointer select-none itemMainDesktopMenu"
										onMouseEnter={() => setShowSubItems(true)}
										onMouseLeave={() => setShowSubItems(false)}
									>
										<div className="text-main hover:text-[#019DFF] hover:no-underline">
											Services
										</div>
										{/* Submenu for Daycare */}
										{showSubItems && (
											<ul className="absolute bg-white py-[10px] px-[20px] top-full left-0 border-[1px] border-main rounded-default rounded-tl-none transition-all duration-300 flex flex-col gap-2 subMenu">
												<li>
													<Link
														to={!isRegistrationInProcess && "/daycare"}
														className="text-main hover:text-[#019DFF] hover:no-underline"
													>
														Daycare
													</Link>
												</li>
												<li>
													<Link
														to={!isRegistrationInProcess && "/services"}
														className="text-main hover:text-[#019DFF] hover:no-underline"
													>
														Grooming
													</Link>
												</li>
											</ul>
										)}
									</li>
									<li
										className="cursor-pointer select-none itemMainDesktopMenu"
										onClick={() => {
											isRegistrationInProcess &&
												registrationStatusChecker("about")
										}}
									>
										<Link
											to={!isRegistrationInProcess && "/about"}
											className="text-main hover:text-[#019DFF] hover:no-underline"
										>
											About
										</Link>
									</li>
									<li
										className="cursor-pointer select-none itemMainDesktopMenu"
										onClick={() => {
											isRegistrationInProcess &&
												registrationStatusChecker("gallery")
										}}
									>
										<Link
											to={!isRegistrationInProcess && "/gallery"}
											className="text-main hover:text-[#019DFF] hover:no-underline"
										>
											Gallery
										</Link>
									</li>
									<li
										className="cursor-pointer select-none itemMainDesktopMenu"
										onClick={() => {
											isRegistrationInProcess &&
												registrationStatusChecker("contact")
										}}
									>
										<Link
											to={!isRegistrationInProcess && "/contact"}
											className="text-main hover:text-[#019DFF] hover:no-underline"
										>
											Contact
										</Link>
									</li>

									{isAuthenticated ? (
										<>
											<li className="p-2 bg-blue-100 rounded-sm">
												<Link
													to={!isRegistrationInProcess && "/client-dashboard"}
													className="text-main mt-4 hover:text-[#019DFF] hover:no-underline"
												>
													Profile
												</Link>
											</li>
											<button
												className="px-4 py-2 font-bold text-black bg-yellow-300 rounded-md hover:bg-yellow-400"
												onClick={logoutHandler}
											>
												Logout
											</button>
										</>
									) : (
										<>
											<li
												className="relative z-10 cursor-pointer select-none itemMainDesktopMenu"
												onMouseEnter={() => setShowSubItemsRegistration(true)}
												onMouseLeave={() => setShowSubItemsRegistration(false)}
											>
												<div className="text-main hover:text-[#019DFF] hover:no-underline">
													Register
												</div>
												{showSubItemsRegistration && (
													<ul
														className={`absolute left-0 py-[10px] px-[20px] bg-white border-[1px] border-main rounded-default rounded-tl-none transition-all duration-300 flex flex-col gap-2 subMenu`}
													>
														<li>
															<div
																onClick={() =>
																	setShowRegisterDaycareModal(true)
																}
																className="text-main hover:text-[#019DFF] hover:no-underline"
															>
																Daycare
															</div>
														</li>
														<li>
															<Link
																// to={"/grooming_registration"}
																onClick={() =>
																	setShowRegisterGroomingModal(true)
																}
																className="text-main mt-4 hover:text-[#019DFF] hover:no-underline"
															>
																Grooming
															</Link>
														</li>
													</ul>
												)}
											</li>
											<li
												className="cursor-pointer select-none itemMainDesktopMenu"
												onClick={() => {
													isRegistrationInProcess &&
														registrationStatusChecker("contact")
												}}
											>
												<div
													onClick={() => setShowLoginModal(!showLoginModal)}
													// to={!isRegistrationInProcess && "/login"}
													className="text-main hover:text-[#019DFF] hover:no-underline"
												>
													Login
												</div>
											</li>
											<li
												className="cursor-pointer select-none itemMainDesktopMenu"
												onClick={() => {
													isRegistrationInProcess &&
														registrationStatusChecker("schedule")
												}}
											>
												<Link
													to={!isRegistrationInProcess && "/schedule"}
													className="hover:no-underline"
												>
													<CustomButton onClick={() => {}} hasArrow>
														Book Grooming
													</CustomButton>
												</Link>
											</li>
										</>
									)}
								</>
							) : (
								<>
									<li className="text-main hover:text-[#019DFF] hover:no-underlin itemMainDesktopMenu">
										👤 Logged in as: {employeeUsername}
									</li>
									{jobType === "receptionist" ||
										(jobType === "groomer1" && (
											<>
												{jobType === "receptionist" && (
													<li className="cursor-pointer select-none itemMainDesktopMenu">
														<Link
															to={{
																pathname: "/auth/commission",
															}}
															className="text-main hover:text-[#019DFF] hover:no-underline"
														>
															💰 Daily Report
														</Link>
													</li>
												)}
												<li className="cursor-pointer select-none itemMainDesktopMenu">
													<Link
														to="/auth/reception"
														className="text-main hover:text-[#019DFF] hover:no-underline"
													>
														⚙️ Control Panel
													</Link>
												</li>
											</>
										))}
									{jobType === "admin" && (
										<li className="cursor-pointer select-none itemMainDesktopMenu">
											<Link
												to="/auth/admin_dashboard"
												className="text-main hover:text-[#019DFF] hover:no-underline"
											>
												Admin panel
											</Link>
										</li>
									)}
									<li className="cursor-pointe itemMainDesktopMenu">
										<CustomButton onClick={handleLogOut}>Log out</CustomButton>
									</li>
								</>
							)}
							{process.env.REACT_APP_TESTING_ADMIN_MODE !== "on" && (
								<FloatingCartIcon position="fixed" />
							)}
						</ul>
					</li>
				</ul>
			</div>

			{/* Mobile */}
			<MobileMenu
				isRegistrationInProcess={isRegistrationInProcess}
				registrationStatusChecker={registrationStatusChecker}
				logoutHandler={logoutHandler}
				setShowRegisterGroomingModal={setShowRegisterGroomingModal}
				setShowRegisterDaycareModal={setShowRegisterDaycareModal}
				isAuthenticated={isAuthenticated}
				setShowLoginModal={setShowLoginModal}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
				showRegisterDaycareModal={showRegisterDaycareModal}
				showLoginModal={showLoginModal}
			/>
		</nav>
	)
}

export default Toolbar
