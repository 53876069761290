import { useState, useEffect } from "react"
import { Form, FormGroup, Input } from "reactstrap"
import Select from "react-select"
import API from "../../utils/API"
import API_CAMBRIDGE from "../../utils/APICAMBRIDGE"
import axios from "axios"
import Cookies from "js-cookie"
import moment from "moment"

const PetDetails = ({
	pet,
	index,
	handlePetChange,
	redInputHandler,
	yesOrNoOptions,
	genderOptions,
	petTypeOptions,
	isPetReady,
	mode,
	fetchData,
	clientId,
	setAddPetOpenModal,
	formType,
	removePetFormHandler,
	isClientDashboard,
}) => {
	const [name, setName] = useState("")
	const [type, setType] = useState("")
	const [breed, setBreed] = useState("")
	const [age, setAge] = useState("")
	const [color, setColor] = useState("not saved yet")
	const [gender, setGender] = useState("")
	const [weight, setWeight] = useState("")
	const [fixedDate, setFixedDate] = useState("")
	const [currentInjuries, setCurrentInjuries] = useState("")
	const [generalBehaviour, setGeneralBehaviour] = useState("")
	const [bordatellaImgUrl, setBordatellaImgUrl] = useState("")
	const [vaccineRecordImgUrl, setVaccineRecordImgUrl] = useState("")
	const [isFixed, setIsFixed] = useState(false)
	const [hasBordatellaVaccine, setHasBordatellaVaccine] = useState(false)
	const [hasRegularVaccine, setHasRegularVaccine] = useState(false)
	const [hasAllergies, setHasAllergies] = useState(false)
	const [allergiesContent, setAllergiesContent] = useState("")

	const standardClasses =
		"mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"

	const fileUploadHandler = async (value) => {
		const fd = new FormData()
		fd.append("file", value)
		fd.append("upload_preset", "amazing")

		try {
			const res = await axios.post(
				"https://api.cloudinary.com/v1_1/amazing-pet-grooming/image/upload",
				fd
			)

			return res.data.secure_url
		} catch (err) {
			console.log(err)
		}
	}

	const addPetHandler = async (e) => {
		e.preventDefault()

		const pet = {
			name,
			type,
			breed,
			age,
			color,
			gender,
			weight,
			// fixedDate: moment(fixedDate),
			currentInjuries,
			generalBehaviour,
			isFixed,
			hasAllergies,
			hasBordatellaVaccine,
			hasRegularVaccine,
		}
		if (vaccineRecordImgUrl) {
			const vaccineRecordUrl = await fileUploadHandler(vaccineRecordImgUrl)
			pet.vaccineRecordImgUrl = vaccineRecordUrl
		}
		if (bordatellaImgUrl) {
			const bordatellaUrl = await fileUploadHandler(bordatellaImgUrl)
			pet.bordatellaImgUrl = bordatellaUrl
		}

		const userLocation = Cookies.get("client_location")
		const petUrl = userLocation === "milton" ? API : API_CAMBRIDGE

		await petUrl
			.addPet(clientId, pet)
			.then((res) => {
				console.log(res)
				fetchData()
				setAddPetOpenModal(false)
			})
			.catch((err) => console.log(err))
	}

	return (
		<>
			{formType === "add" ? (
				<div
					className={`px-8 py-4 mt-2 rounded-md shadow-lg ${
						isPetReady ? "bg-green-200" : "bg-white"
					} bg-opacity-70`}
				>
					<h5 className="text-center text-md">
						<strong>Add a Pet</strong>
					</h5>
					<hr />
					<Form onSubmit={addPetHandler} className="row">
						<div
							className={`${mode === "daycare" ? "col-md-6" : "col-md-12"} `}
						>
							<FormGroup>
								<label className="text-sm">* Pet Name</label>
								<Input
									className={redInputHandler(name)}
									onChange={(e) => setName(e.target.value)}
									type="text"
									name="name"
									value={name}
								/>
								<label className="text-sm">* Pet Type</label>
								<Select
									className={redInputHandler(type)}
									value={{
										value: type,
										label: type,
									}}
									options={petTypeOptions}
									isSearchable={false}
									onChange={(value) => setType(value.value)}
								/>
								<label className="text-sm">* Breed</label>
								<Input
									className={redInputHandler(breed)}
									onChange={(e) => setBreed(e.target.value)}
									type="text"
									name="breed"
									value={breed}
								/>
								<label className="text-sm">Gender</label>
								<Select
									// className={redInputHandler(gender)}
									value={{
										value: gender,
										label: gender,
									}}
									options={genderOptions}
									isSearchable={false}
									onChange={(value) => setGender(value.value)}
								/>
								{/* <label className="text-sm">Color</label>
								<Input
									className={redInputHandler(color)}
									onChange={(e) => setColor(e.target.value)}
									type="text"
									name="color"
									value={color}
								/> */}
								<label className="text-sm">Pet Date of Birth (Aprox)</label>
								<Input
									// className={redInputHandler(age)}
									onChange={(e) => setAge(e.target.value)}
									type="date"
									name="age"
									value={age}
								/>
								<label className="text-sm">
									Aprox weight in lbs (Numbers only)
								</label>
								<Input
									// className={redInputHandler(weight)}
									id="weight"
									onChange={(e) => setWeight(e.target.value)}
									type="number"
									min={0}
									name="weight"
									value={weight}
								/>
								<label className="text-sm">
									General behavior with other dogs/people
								</label>
								<Input
									className={redInputHandler(generalBehaviour)}
									onChange={(e) => setGeneralBehaviour(e.target.value)}
									type="text"
									name="generalBehaviour"
									value={generalBehaviour}
								/>
								<label className="text-sm">
									Any current injury or concern? Explain
								</label>
								<Input
									// className={redInputHandler(currentInjuries)}
									onChange={(e) => setCurrentInjuries(e.target.value)}
									type="text"
									name="currentInjuries"
									value={currentInjuries}
								/>
							</FormGroup>
						</div>
						{mode === "daycare" && (
							<>
								<div className="col-md-6">
									{isClientDashboard && (
										<p className="mb-2">
											The following fields are mandatory if your dog is
											attending Daycare &#8595;
										</p>
									)}
									<FormGroup>
										<div className="border p-2 mb-2 rounded-md">
											<label className="text-sm">Is Spayed or Neutered</label>
											<Select
												className={
													!isClientDashboard
														? redInputHandler(isFixed)
														: standardClasses
												}
												value={{
													value: isFixed,
													label: isFixed === true ? "Yes" : "No",
												}}
												options={yesOrNoOptions}
												isSearchable={false}
												onChange={(value) => setIsFixed(value.value)}
											/>
											<p className="bg-yellow-300 p-2 text-center rounder-sm">
												Spayed / Neutered is Mandatory!
											</p>
											{/* {isFixed === true ? (
												<>
													<label className="text-sm">
														When was Spayed/Neutered{" "}
														<span className="text-xs">(YYYY-MM-DD)</span>
													</label>
													<Input
														className={
															!isClientDashboard
																? redInputHandler(fixedDate)
																: standardClasses
														}
														onChange={(e) => setFixedDate(e.target.value)}
														type="date"
														name="fixedDate"
														value={fixedDate}
													/>
												</>
											) : (
												<p className="bg-yellow-300 p-2 text-center rounder-sm">
													Spayed / Neutered is Mandatory!
												</p>
											)} */}
										</div>
										<label className="text-sm">Has allergies?</label>
										<Select
											className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
											value={{
												value: hasAllergies,
												label: hasAllergies === true ? "Yes" : " No",
											}}
											options={yesOrNoOptions}
											isSearchable={false}
											onChange={(value) => setHasAllergies(value.value)}
										/>
										{hasAllergies && (
											<>
												<label className="text-sm">List any allergies</label>
												<Input
													className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
													onChange={(e) => setAllergiesContent(e.target.value)}
													type="text"
													name="allergiesContent"
													value={allergiesContent}
												/>
											</>
										)}

										<div className="border p-2 mb-2 rounded-md">
											<label className="text-sm">Has Bordetella Vaccine?</label>
											<Select
												className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
												defaultValue={{
													value: hasBordatellaVaccine,
													label: hasBordatellaVaccine === true ? "Yes" : " No",
												}}
												options={yesOrNoOptions}
												isSearchable={false}
												onChange={(value) =>
													setHasBordatellaVaccine(value.value)
												}
											/>
											{!hasBordatellaVaccine && (
												<p className="bg-yellow-300 p-2 text-center rounder-sm">
													Bordetella vaccine is Mandatory!
												</p>
											)}
										</div>
										<div className="border p-2 mb-2 rounded-md">
											<label className="text-sm">
												Are regular vaccines up to date?
											</label>
											<Select
												className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
												defaultValue={{
													value: hasRegularVaccine,
													label: hasRegularVaccine === true ? "Yes" : " No",
												}}
												options={yesOrNoOptions}
												isSearchable={false}
												onChange={(value) => setHasRegularVaccine(value.value)}
											/>
											{!hasRegularVaccine && (
												<p className="bg-yellow-300 p-2 text-center rounder-sm">
													Regular vaccine must be up to date!
												</p>
											)}
										</div>
									</FormGroup>
								</div>
								{isClientDashboard && (
									<div className="border-t pt-2 w-full">
										<p className="ml-4">For Daycare &#8595;</p>
									</div>
								)}
								<div className="form-group border p-2 mt-2 w-full mx-3">
									<label className="text-sm">
										Vaccine Record Photo,
										<br />
										<span className="text-sm text-yellow-600">
											If no photo is uploaded, physical proof is required when
											you arrive
										</span>
									</label>
									<input
										className={`py-1 w-full pl-1`}
										type="file"
										accept="image/*"
										onChange={(e) => setVaccineRecordImgUrl(e.target.files[0])}
									/>
								</div>
								<div className="form-group border p-2 mt-2 w-full mx-3">
									<label className="text-sm">
										Bordetella Vaccine Record Photo,
										<br />
										<span className="text-sm text-yellow-600">
											If no photo is uploaded, physical proof is required when
											you arrive
										</span>
									</label>
									<input
										className={`py-1 w-full pl-1`}
										type="file"
										accept="image/*"
										onChange={(e) => setBordatellaImgUrl(e.target.files[0])}
									/>
								</div>
							</>
						)}
						<button className="btn btn-block btn-info">Submit</button>
					</Form>
				</div>
			) : (
				<div key={pet.id} className={`${index > 0 ? "mt-4" : ""}`}>
					<div
						className={`px-8 py-4 mt-2 rounded-md shadow-lg ${
							isPetReady ? "bg-green-200" : "bg-white"
						} bg-opacity-70`}
					>
						{pet.temporaryId && index !== 0 && (
							<div className="flex justify-end">
								<button
									onClick={() => removePetFormHandler(pet.temporaryId)}
									className="btn btn-danger"
								>
									Remove this pet form
								</button>
							</div>
						)}
						{pet.id && (
							<h5 className="text-center text-md">
								<strong>
									{pet.name && pet.name.toUpperCase()} -{" "}
									{pet.breed && pet.breed.toUpperCase()}
								</strong>
							</h5>
						)}

						<hr />
						<Form className="row">
							<div
								className={`${mode === "daycare" ? "col-md-6" : "col-md-12"}`}
							>
								<FormGroup>
									<label className="text-sm">* Pet Name</label>
									<Input
										className={redInputHandler(pet.name)}
										onChange={(e) =>
											handlePetChange(index, "name", e.target.value)
										}
										type="text"
										name="name"
										defaultValue={pet.name || ""}
									/>
									<label className="text-sm">* Pet Type</label>
									<Select
										className={redInputHandler(pet.type)}
										defaultValue={{
											value: pet.type,
											label: pet.type && pet.type,
										}}
										options={petTypeOptions}
										isSearchable={false}
										onChange={(value) =>
											handlePetChange(index, "type", value.value)
										}
									/>
									<label className="text-sm">* Breed</label>
									<Input
										className={redInputHandler(pet.breed)}
										onChange={(e) =>
											handlePetChange(index, "breed", e.target.value)
										}
										type="text"
										name="breed"
										defaultValue={pet.breed || ""}
									/>
									<label className="text-sm">Gender</label>
									<Select
										// className={redInputHandler(pet.gender)}
										defaultValue={{
											value: pet.gender,
											label: pet.gender ? pet.gender : "",
										}}
										options={genderOptions}
										isSearchable={false}
										onChange={(value) =>
											handlePetChange(index, "gender", value.value)
										}
									/>
									{/* <label className="text-sm">Color</label>
									<Input
										className={redInputHandler(pet.color)}
										onChange={(e) =>
											handlePetChange(index, "color", e.target.value)
										}
										type="text"
										name="color"
										defaultValue={pet.color || ""}
									/> */}
									<label className="text-sm">Pet Date of Birth (Aprox)</label>
									<Input
										// className={redInputHandler(pet.age)}
										onChange={(e) =>
											handlePetChange(index, "age", e.target.value)
										}
										type="date"
										name="age"
										defaultValue={pet.age || ""}
									/>
									<label className="text-sm">
										Aprox weight in lbs (Numbers only)
									</label>
									<Input
										// className={redInputHandler(pet.weight)}
										id="petBreedToEdit"
										onChange={(e) =>
											handlePetChange(index, "weight", e.target.value)
										}
										onInput={(event) =>
											event.target.value.length > 3
												? (event.target.value = event.target.value.slice(0, 3))
												: event.target.value
										}
										min={1}
										max={400}
										type="number"
										name="weight"
										defaultValue={pet.weight || ""}
									/>
									<label className="text-sm">
										General behavior with other dogs/people
									</label>
									<Input
										className={redInputHandler(pet.generalBehaviour)}
										onChange={(e) =>
											handlePetChange(index, "generalBehaviour", e.target.value)
										}
										type="text"
										name="generalBehaviour"
										defaultValue={pet.generalBehaviour || ""}
									/>
								</FormGroup>
							</div>
							{mode === "daycare" && (
								<>
									<div className="col-md-6">
										<FormGroup>
											<div className="border p-2 mb-2 rounded-md">
												<label className="text-sm">Is Spayed or Neutered</label>
												<Select
													className={redInputHandler(pet.isFixed)}
													defaultValue={{
														value: pet.isFixed,
														label: pet.isFixed === true ? "Yes" : "No",
													}}
													options={yesOrNoOptions}
													isSearchable={false}
													onChange={(value) =>
														handlePetChange(index, "isFixed", value.value)
													}
												/>
												<p className="bg-yellow-300 p-2 text-center rounder-sm">
													Spayed / Neutered is Mandatory!
												</p>
												{/* {pet.isFixed === true ? (
													<>
														<label className="text-sm">
															When was Spayed/Neutered{" "}
															<span className="text-xs">(YYYY-MM-DD)</span>
														</label>
														<Input
															className={redInputHandler(pet.fixedDate)}
															onChange={(e) =>
																handlePetChange(
																	index,
																	"fixedDate",
																	e.target.value
																)
															}
															type="date"
															name="fixedDate"
															defaultValue={pet.fixedDate}
														/>
													</>
												) : (
													<p className="bg-yellow-300 p-2 text-center rounder-sm">
														Spayed / Neutered is Mandatory!
													</p>
												)} */}
											</div>
											<label className="text-sm">Has allergies?</label>
											<Select
												className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
												defaultValue={{
													value: pet.hasAllergies,
													label: pet.hasAllergies === true ? "Yes" : " No",
												}}
												options={yesOrNoOptions}
												isSearchable={false}
												onChange={(value) =>
													handlePetChange(index, "hasAllergies", value.value)
												}
											/>
											{pet.hasAllergies && (
												<>
													<label className="text-sm">List any allergies</label>
													<Input
														className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
														onChange={(e) =>
															handlePetChange(
																index,
																"allergiesContent",
																e.target.value
															)
														}
														type="text"
														name="allergiesContent"
														defaultValue={pet.allergiesContent || ""}
													/>
												</>
											)}
											<label className="text-sm">
												Any current injury or concern? Explain
											</label>
											<Input
												// className={redInputHandler(pet.currentInjuries)}
												onChange={(e) =>
													handlePetChange(
														index,
														"currentInjuries",
														e.target.value
													)
												}
												type="text"
												name="currentInjuries"
												defaultValue={pet.currentInjuries || ""}
											/>
											<div className="border p-2 mb-2 rounded-md">
												<label className="text-sm">
													Has Bordetella Vaccine?
												</label>
												<Select
													className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
													defaultValue={{
														value: pet.hasBordatellaVaccine
															? pet.hasBordatellaVaccine
															: false,
														label:
															pet.hasBordatellaVaccine === true ? "Yes" : " No",
													}}
													options={yesOrNoOptions}
													isSearchable={false}
													onChange={(value) =>
														handlePetChange(
															index,
															"hasBordatellaVaccine",
															value.value
														)
													}
												/>
												{!pet.hasBordatellaVaccine && (
													<p className="bg-yellow-300 p-2 text-center rounder-sm">
														Bordetella vaccine is Mandatory!
													</p>
												)}
											</div>
											<div className="border p-2 mb-2 rounded-md">
												<label className="text-sm">
													Are regular vaccines up to date?
												</label>
												<Select
													className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
													defaultValue={{
														value: pet.hasRegularVaccine,
														label:
															pet.hasRegularVaccine === true ? "Yes" : " No",
													}}
													options={yesOrNoOptions}
													isSearchable={false}
													onChange={(value) =>
														handlePetChange(
															index,
															"hasRegularVaccine",
															value.value
														)
													}
												/>
												{!pet.hasRegularVaccine && (
													<p className="bg-yellow-300 p-2 text-center rounder-sm">
														Regular vaccine must be up to date!
													</p>
												)}
											</div>
										</FormGroup>
									</div>
									<div className="form-group border p-2 mt-2 w-full mx-3">
										<label className="text-sm">
											Vaccine Record Photo,
											<br />
											<span className="text-sm text-yellow-600">
												If no photo is uploaded, physical proof is required when
												you arrive
											</span>
										</label>
										<input
											className={`py-1 w-full pl-1`}
											type="file"
											accept="image/*"
											onChange={(e) =>
												handlePetChange(
													index,
													"vaccineRecordImgUrl",
													e.target.files[0]
												)
											}
										/>
									</div>
									<div className="form-group border p-2 mt-2 w-full mx-3">
										<label className="text-sm">
											Bordetella Vaccine Record Photo,
											<br />
											<span className="text-sm text-yellow-600">
												If no photo is uploaded, physical proof is required when
												you arrive
											</span>
										</label>
										<input
											className={`py-1 w-full pl-1`}
											type="file"
											accept="image/*"
											onChange={(e) =>
												handlePetChange(
													index,
													"bordatellaImgUrl",
													e.target.files[0]
												)
											}
										/>
									</div>
								</>
							)}
						</Form>
					</div>
				</div>
			)}
		</>
	)
}

export default PetDetails
