import React from "react"

const convertHexToRgba = (colorHex, opacity = 1) => {
	colorHex = colorHex.replace("#", "")

	const r = parseInt(colorHex.substring(0, 2), 16)
	const g = parseInt(colorHex.substring(2, 4), 16)
	const b = parseInt(colorHex.substring(4, 6), 16)

	const rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`

	return rgbaColor
}

const ImageGradient = ({
	image,
	color,
	bigImage = false,
	centered = false,
}) => {
	return (
		<div
			className="w-full h-full relative select-none"
			style={{
				backgroundImage: `radial-gradient(50% 50% at 50% 50%, ${convertHexToRgba(
					color,
					0.2
				)} 0%, ${convertHexToRgba(color, 0.5)} 60%, ${convertHexToRgba(
					color,
					0.8
				)} 100%), url(/images/about_bg.png)`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<img
				className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 ${
					bigImage ? "w-[60%]" : "w-1/3"
				} ${centered && "top-1/2"}`}
				style={{
					transform: centered && "translate(-50%, -50%)",
					bottom: centered && "0%",
				}}
				src={image}
				alt="image with gradient background"
			/>
		</div>
	)
}

export default ImageGradient
