const ReleaseStatus = ({
	releaseType,
	data,
	releaseOpenModal,
	setReleaseOpenModal,
}) => {
	const isReleaseSigned = data && data[`signed${releaseType}Release`]

	const handleClick = () => {
		if (data && !isReleaseSigned) {
			setReleaseOpenModal(!releaseOpenModal)
		}
	}

	return (
		<span
			onClick={handleClick}
			className={`py-1 px-2 rounded-sm ${
				isReleaseSigned
					? "bg-green-200 text-gray"
					: "bg-yellow-400 cursor-pointer"
			}`}
		>
			{releaseType}{" "}
			{isReleaseSigned ? <span>&#10003;</span> : <span>&#9998;</span>}
		</span>
	)
}

export default ReleaseStatus
