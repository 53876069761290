import React, { useState, useEffect } from "react"
import { Button, Modal, ModalBody } from "reactstrap"
import LoadPage from "../../LoadingPage"
import axios from "axios"

const Index = ({
	clientID,
	vetId,
	modalToUpdateVet,
	toggleModalToUpdateVet,
	vetName,
	vetPhone,
	vetClinicName,
	vetAddress,
	getVetInformation,
}) => {
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [vetNameEdited, setVetNameEdited] = useState("")
	const [vetPhoneEdited, setVetPhoneEdited] = useState("")
	const [vetClinicNameEdited, setVetClinicNameEdited] = useState("")
	const [vetAddressEdited, setVetAddressEdited] = useState("")
	const [clientId, setClientId] = useState("")

	useEffect(() => {
		setVetNameEdited(vetName)
		setVetPhoneEdited(vetPhone)
		setVetClinicNameEdited(vetClinicName)
		setVetAddressEdited(vetAddress)
		setClientId(clientID)
	}, [vetName, vetPhone, vetClinicName, vetAddress, clientID])

	const updateVetHandler = async (e) => {
		e.preventDefault()
		if (
			!vetPhoneEdited ||
			!vetNameEdited ||
			!vetClinicNameEdited ||
			!vetAddressEdited
		) {
			const fieldsMissing = []
			if (!vetPhoneEdited) {
				fieldsMissing.push("Vet Phone")
			}
			if (!vetNameEdited) {
				fieldsMissing.push("Vet Name")
			}
			if (!vetClinicNameEdited) {
				fieldsMissing.push("Vet Clinic Name")
			}
			if (!vetAddressEdited) {
				fieldsMissing.push("Vet Address")
			}

			return setErrorMessage(
				`Missing fields: ${fieldsMissing.map((field) => ` ${field}`)}`
			)
		}
		setErrorMessage("")
		setLoadingAxiosReq(true)
		const updatedVet = {
			vetName: vetNameEdited,
			vetPhone: vetPhoneEdited.replace(/^./, (str) => str.toUpperCase()),
			vetClinicName: vetClinicNameEdited,
			vetAddress: vetAddressEdited,
			clientID: clientId,
		}

		await axios
			.put(`/api/vets/${vetId}`, updatedVet)
			.then(() => {
				getVetInformation()
				setTimeout(() => {
					setLoadingAxiosReq(false)
					toggleModalToUpdateVet()
				}, 1000)
			})
			.catch((error) => {
				setLoadingAxiosReq(false)
				console.log(error)
			})
	}
	return (
		<Modal
			modalClassName="modal-success-app"
			isOpen={modalToUpdateVet}
			toggle={toggleModalToUpdateVet}
		>
			<ModalBody>
				<div className="form-registration-container">
					<button
						onClick={toggleModalToUpdateVet}
						className="bg-slate-500 text-white px-1 absolute"
					>
						X
					</button>
					<h4 className="text-center">Update Vet</h4>
					<form className="form-group" onSubmit={updateVetHandler}>
						<div className="input-field-registration">
							<label className="mt-2" htmlFor="vetClinicName">
								* Clinic Name
							</label>
							<input
								id="vetClinicName"
								className="form-control"
								type="text"
								placeholder="Clinic Name"
								name="vetClinicName"
								value={vetClinicNameEdited}
								onChange={(e) => setVetClinicNameEdited(e.target.value)}
							/>
						</div>
						<div className="input-field-registration">
							<label className="mt-2" htmlFor="vetName">
								* Vet Name
							</label>
							<input
								id="vetName"
								className="form-control"
								type="text"
								placeholder="* Vet Name"
								value={vetNameEdited}
								name="vetName"
								onChange={(e) => setVetNameEdited(e.target.value)}
							/>
						</div>
						<div className="input-field-registration">
							<label className="mt-2" htmlFor="vetPhone">
								* Vet Phone
							</label>
							<input
								id="vetPhone"
								className="form-control"
								type="text"
								placeholder="* Vet Phone"
								value={vetPhoneEdited}
								name="vetPhone"
								onChange={(e) => setVetPhoneEdited(e.target.value)}
							/>
						</div>

						<div className="input-field-registration">
							<label className="mt-2" htmlFor="vetAddress">
								Vet Address
							</label>
							<input
								id="vetAddress"
								className="form-control"
								type="text"
								placeholder="Vet Address"
								name="vetAddress"
								value={vetAddressEdited}
								onChange={(e) => setVetAddressEdited(e.target.value)}
							/>
						</div>

						{loadingAxiosReq ? (
							<LoadPage />
						) : (
							<div className="input-field-registration">
								<Button
									color="info"
									block
									style={{
										marginTop: "10px",
										fontWeight: "700",
										fontSize: "22px",
									}}
									className="btn-primary lighten-1 z-depth-0"
								>
									Submit
								</Button>
							</div>
						)}
					</form>
					<p className="error-message-registration text-align-center">
						{errorMessage}
					</p>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default Index
