import "./style.css"

export default function Index() {
	return (
		<div className="loading-status-full-page">
			<div className="loading-spinner">
				<i className="fa fa-refresh fa-spin "></i> Please wait...
			</div>
		</div>
	)
}
