import React, {
	useState,
	useContext,
	useRef,
	useLayoutEffect,
	useEffect,
} from "react"
import API_CAMBRIDGE from "../../utils/APICAMBRIDGE"
import API_MILTON from "../../utils/API"
import LoadPage from "../../components/LoadingPage"
import TermsAndConditions from "../TermsAndConditions/Cambridge"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import { Helmet } from "react-helmet"
import City from "../../components/BookingForm/FrmScreens/City"
import Tag from "../../components/BookingForm/Tag"
import { CustomButton } from "../../components/CustomButton"
import CustomInput from "../../components/CustomInput"
import { gsap } from "gsap"
import { scroller, Element } from "react-scroll"
import axios from "axios"

const CustomerAddPageAllCities = () => {
	const [lastName, setLastName] = useState("")
	const [firstName, setFirstName] = useState("")
	const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("")
	const [cellphone, setCellphone] = useState("")
	const [workPhone, setWorkPhone] = useState("")
	const [email, setEmail] = useState("")
	const [notChecked, setNotChecked] = useState(true)
	const [errorMsg, setErrorMsg] = useState({
		message: "",
		fields: [],
	})
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [animateTermsContainer, setAnimateTermsContainer] = useState(false)
	const [buttonFlashing, setButtonFlashing] = useState(false)
	const [areAllFieldsReady, setAreAllFieldsReady] = useState(false)
	const [locationSelected, setLocationSelected] = useState("")
	const { setIsRegistrationInProcess } = useContext(RegistrationContext)

	useEffect(() => {
		if (!notChecked && lastName && firstName && primaryPhoneNumber && email) {
			scroller.scrollTo("terms-and-conditions-btn-wrapper", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			})
			setAreAllFieldsReady(true)
			setErrorMsg({ message: "", fields: [] })
		} else {
			setAreAllFieldsReady(false)
		}
	}, [notChecked, lastName, firstName, primaryPhoneNumber, email])

	//Add client form submit
	const handleFormSubmit = async (e) => {
		e.preventDefault()

		if (!lastName || !firstName || !primaryPhoneNumber || !email) {
			const fieldsMissing = []
			if (!lastName) {
				fieldsMissing.push("Last Name")
			}
			if (!firstName) {
				fieldsMissing.push("First Name")
			}
			if (!primaryPhoneNumber) {
				fieldsMissing.push("Primary phone")
			}
			if (!email) {
				fieldsMissing.push("Email")
			}

			setErrorMsg({
				message: `Missing fields: ${fieldsMissing.map((field) => ` ${field}`)}`,
				fields: fieldsMissing,
			})

			setAnimateTermsContainer(false)
			setButtonFlashing(false)
			return
		}
		if (notChecked === true) {
			setErrorMsg({
				message: "Please read and accept the terms and conditions",
				fields: [],
			})
			setAnimateTermsContainer(true)
			setButtonFlashing(false)
			return
		}
		setLoadingAxiosReq(true)

		const checkPhoneURL =
			locationSelected === "cambridge"
				? `/api/cambridge/primaryPhoneNumber/${primaryPhoneNumber}`
				: `/api/clients/primaryPhoneNumber/${primaryPhoneNumber}`

		await axios
			.get(checkPhoneURL)
			.then(async (res) => {
				if (res.data.length > 0) {
					setLoadingAxiosReq(false)
					return alert(
						`You already have an acocunt with us. \nPlease inform our staff that your client number is: ${res.data[0].id}`
					)
				} else {
					const URL_CITY_SELECTED =
						locationSelected === "cambridge" ? API_CAMBRIDGE : API_MILTON

					await URL_CITY_SELECTED.addClient({
						lastName: lastName.replace(/^./, (str) => str.toUpperCase()),
						firstName: firstName.replace(/^./, (str) => str.toUpperCase()),
						primaryPhoneNumber: primaryPhoneNumber.replace(/[- )(]/g, ""),
						cellphone: cellphone.replace(/[- )(]/g, ""),
						workPhone: workPhone.replace(/[- )(]/g, ""),
						email: email,
					})
						.then((res) => {
							setLoadingAxiosReq(false)
							if (locationSelected === "cambridge") {
								window.location.href =
									"/auth/customer_cambridge/" + res.data.registrationNumber
							}
							if (locationSelected === "milton") {
								window.location.href =
									"/auth/customer_milton/" + res.data.registrationNumber
							}
						})
						.catch((err) => {
							setLoadingAxiosReq(false)
							console.log(err)
						})
				}
			})
			.catch((err) => console.log(err))
	}

	const toggleChangeTermsAndConditions = () => {
		if (notChecked) {
			setErrorMsg({ message: "", fields: [] })
			setAnimateTermsContainer(false)
		}
		setNotChecked(!notChecked)
	}

	//Animation terms not checked
	const container = useRef(null)
	const tl = useRef(null)
	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			tl.current = gsap.timeline({ paused: true })

			if (locationSelected) {
				tl.current.to(".termsContainer", {
					scale: 1.02,
					duration: 1,
					repeat: -1,
					yoyo: true,
					ease: "Power3.easeOut",
				})
			}
		}, container)

		return () => ctx.revert()
	}, [])

	useEffect(() => {
		if (animateTermsContainer) {
			tl.current.play()
		} else {
			tl.current.progress(0).pause()
		}
	}, [animateTermsContainer])

	return (
		<section className="w-full h-full flex flex-col justify-center items-center">
			<Helmet>
				<title>Register | Amazing Grooming Pet</title>
				<meta
					name="description"
					content="Get registered, it won't take long, we promise"
				/>
			</Helmet>
			<main
				className={`w-full  flex ${
					locationSelected
						? "flex-col lg:flex-row lg:gap-0 h-full lg:h-[calc(100vh-90px)]"
						: "flex-row h-[100vh] md:h-[calc(100vh-90px)]"
				}`}
				ref={container}
			>
				<div className={`bg-white w-full h-full lg:w-[60%] flex flex-col`}>
					<span className="w-full text-main border-b-[1px] border-main block pt-phone px-phone md:px-tabler lg:px-desktop">
						👤 New client registration
					</span>
					{/* Tags selected options */}
					<div className="w-full p-phone md:px-tablet lg:pl-desktop flex gap-[10px] flex-wrap">
						{locationSelected !== "" && (
							<Tag
								value={
									locationSelected === "cambridge" ? "Cambridge" : "Milton"
								}
								onClick={() => setLocationSelected("")}
								hasIcon
							/>
						)}
					</div>

					{/* Form */}
					<div className="w-full h-full flex flex-col p-phone md:p-tablet lg:px-desktop">
						{!locationSelected && (
							<City
								onClickCambridge={() => {
									setIsRegistrationInProcess(true)
									setLocationSelected("cambridge")
									setErrorMsg({ message: "", fields: [] })
								}}
								onClickMilton={() => {
									setLocationSelected("milton")
									setIsRegistrationInProcess(true)
									setErrorMsg({ message: "", fields: [] })
								}}
							/>
						)}
						{locationSelected && (
							<form
								onSubmit={handleFormSubmit}
								className="w-full h-full flex flex-col gap-[10px]"
							>
								<h5 className="text-black font-black text-[20px] md:text-[30px] mb-0 relative">
									Owner information:
									<span className="absolute -top-[70%] md:-top-[60%] text-gray text-base left-0 font-normal">
										* Fields required
									</span>
								</h5>
								<div className="w-full h-full flex flex-col lg:justify-between gap-[50px] lg:gap-0">
									{/* Inputs */}
									<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[20px]">
										<CustomInput
											placeholder="* Last Name"
											type="text"
											value={lastName}
											name="lastName"
											onChange={(e) => setLastName(e.target.value)}
											isMissing={errorMsg.fields.includes("Last Name")}
										/>
										<CustomInput
											type="text"
											placeholder="* First Name"
											value={firstName}
											name="firstName"
											onChange={(e) => setFirstName(e.target.value)}
											isMissing={errorMsg.fields.includes("First Name")}
										/>
										<CustomInput
											type="text"
											placeholder="* Primary phone"
											name="primaryPhoneNumber"
											value={primaryPhoneNumber}
											onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
											isMissing={errorMsg.fields.includes("Primary phone")}
										/>
										<CustomInput
											type="text"
											placeholder="Cellphone (optional)"
											name="cellphone"
											value={cellphone}
											onChange={(e) => setCellphone(e.target.value)}
										/>
										<CustomInput
											type="text"
											placeholder="Emergency phone (optional)"
											name="workPhone"
											value={workPhone}
											onChange={(e) => setWorkPhone(e.target.value)}
											fullWidth
										/>
										<CustomInput
											type="email"
											placeholder="* Email"
											name="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											isMissing={errorMsg.fields.includes("Email")}
											fullWidth
										/>
									</div>

									{/* Button */}
									<div className="flex flex-col gap-[10px] relative hidden lg:block">
										<span className="text-sm text-gray">
											By providing my email address, I am authorizing Amazing
											pet grooming to send me important information.
										</span>
										{loadingAxiosReq ? (
											<LoadPage />
										) : (
											<CustomButton fullWidth hasArrow>
												Submit
											</CustomButton>
										)}

										<span className="text-base absolute -bottom-[50%] left-0 text-red-400">
											{errorMsg.message}
										</span>
									</div>
								</div>
							</form>
						)}
					</div>
				</div>

				{/* Right side */}
				<div
					className={`h-full relative rounded-l-default overflow-hidden termsContainer ${
						locationSelected
							? "w-full lg:w-[50%] block p-[30px] lg:border-[1px] border-main"
							: "w-[40%] hidden lg:block"
					}`}
					style={
						!locationSelected
							? {
									background: `radial-gradient(50% 50.00% at 50% 50.00%, rgba(101, 191, 248, 0.50) 0%, #65BFF8 100%)`,
							  }
							: {
									background: "white",
							  }
					}
					onMouseEnter={() => {
						if (animateTermsContainer) {
							tl.current.progress(0).pause()
						}
					}}
					onMouseLeave={() => {
						if (animateTermsContainer) {
							tl.current.play()
						}
					}}
				>
					{!locationSelected ? (
						<img
							src="/images/calendar-3d.png"
							className="w-[80%] absolute right-0 bottom-0"
						/>
					) : (
						<TermsAndConditions
							toggleChangeTermsAndConditions={toggleChangeTermsAndConditions}
							value={notChecked}
						/>
					)}
				</div>
				{/* Button */}
				{locationSelected && (
					<div className="px-6 flex flex-col gap-[10px] relative lg:hidden">
						<span className="text-sm text-gray">
							By providing my email address, I am authorizing Amazing pet
							grooming to send me important information.
						</span>
						{loadingAxiosReq ? (
							<LoadPage />
						) : (
							<Element name="terms-and-conditions-btn-wrapper">
								<CustomButton
									areAllFieldsReady={areAllFieldsReady}
									fullWidth
									hasArrow
									onClick={handleFormSubmit}
								>
									Submit
								</CustomButton>
							</Element>
						)}

						<span className="text-base px-3 absolute -bottom-[50%] left-0 text-red-400">
							{errorMsg.message}
						</span>
					</div>
				)}
			</main>
		</section>
	)
}

export default CustomerAddPageAllCities
