import { Form, FormGroup, Input } from "reactstrap"
import Select from "react-select"
import API from "../../utils/API"
import APICAMBRIDGE from "../../utils/APICAMBRIDGE"

const PetUpdateFormByStaff = ({
	pet,
	index,
	handlePetChange,
	yesOrNoOptions,
	genderOptions,
	petTypeOptions,
	isPetReady,
	mode,
	fetchData,
	clientId,
	setAddPetOpenModal,
	formType,
	updatePetHandler,
	setLoadingAxiosReq,
	city,
}) => {
	const addPetHandler = async (e) => {
		e.preventDefault()

		const petObj = {
			name: pet.name,
			breed: pet.breed,
			type: pet.type,
			age: pet.age,
			color: pet.color,
			gender: pet.gender,
			weight: pet.weight,

			// Daycare
			generalBehaviour: pet.generalBehaviour,
			notes: pet.notes,
			currentInjuries: pet.currentInjuries,
			hasAllergies: pet.hasAllergies,
			hasBordatellaVaccine: pet.hasBordatellaVaccine,
			allergiesContent: pet.allergiesContent,
			hasRegularVaccine: pet.hasRegularVaccine,
			isFixed: pet.isFixed,
		}

		if (mode === "daycare") {
			if (
				!pet.fixedDate ||
				!pet.bordatellaExpDate ||
				!pet.regularShotsExpireDate
			) {
				return alert("Missing some required dates")
			} else {
				petObj.fixedDate = pet.fixedDate
				petObj.bordatellaExpDate = pet.bordatellaExpDate
				petObj.regularShotsExpireDate = pet.regularShotsExpireDate
			}
		}
		setLoadingAxiosReq(true)
		const definedURL = city === "milton" ? API : APICAMBRIDGE
		await definedURL
			.addPet(clientId, petObj)
			.then(() => {
				fetchData()
				setTimeout(() => {
					setLoadingAxiosReq(false)
					setAddPetOpenModal(false)
				}, 1000)
			})
			.catch((err) => console.log(err))
	}

	return (
		<>
			{formType === "add" ? (
				<div
					className={`px-8 py-4 mt-2 rounded-md shadow-lg ${
						isPetReady ? "bg-green-200" : "bg-white"
					} bg-opacity-70`}
				>
					<Form onSubmit={addPetHandler} className="row">
						<div
							className={`${mode === "daycare" ? "col-md-6" : "col-md-12"} `}
						>
							<FormGroup>
								<label className="text-sm">Pet Name</label>
								<Input
									onChange={(e) => handlePetChange("name", e.target.value)}
									type="text"
									name="name"
									defaultValue={pet.name || ""}
								/>
								<label className="text-sm">Pet Type</label>
								<Select
									value={{
										value: pet.type,
										label: pet.type,
									}}
									options={petTypeOptions}
									isSearchable={false}
									onChange={(value) => handlePetChange("type", value.value)}
								/>
								<label className="text-sm">Breed</label>
								<Input
									onChange={(e) => handlePetChange("breed", e.target.value)}
									type="text"
									name="breed"
									value={pet.breed}
								/>
								<label className="text-sm">Gender</label>
								<Select
									value={{
										value: pet.gender,
										label: pet.gender,
									}}
									options={genderOptions}
									isSearchable={false}
									onChange={(value) => handlePetChange("gender", value.value)}
								/>
								<label className="text-sm">Color</label>
								<Input
									onChange={(e) => handlePetChange("color", e.target.value)}
									type="text"
									name="color"
									value={pet.color || ""}
								/>
								<label className="text-sm">Pet Date of Birth</label>
								<Input
									onChange={(e) => handlePetChange("age", e.target.value)}
									type="date"
									name="age"
									value={pet.age || ""}
								/>
								<label className="text-sm">Aprox weight (lbs)</label>
								<Input
									id="weight"
									onChange={(e) => handlePetChange("weight", e.target.value)}
									type="number"
									min={0}
									name="weight"
									value={pet.weight || ""}
								/>
								<label className="text-sm">
									General behavior with other dogs/people
								</label>
								<Input
									onChange={(e) =>
										handlePetChange("generalBehaviour", e.target.value)
									}
									type="text"
									name="generalBehaviour"
									value={pet.generalBehaviour || ""}
								/>
							</FormGroup>
						</div>
						{mode === "daycare" && (
							<>
								<div className="col-md-6">
									<FormGroup>
										<div className="border p-2 mb-2 rounded-md">
											<label className="text-sm">Is Spayed or Neutered</label>
											<Select
												value={{
													value: pet.isFixed,
													label: pet.isFixed === true ? "Yes" : "No",
												}}
												options={yesOrNoOptions}
												isSearchable={false}
												onChange={(value) =>
													handlePetChange("isFixed", value.value)
												}
											/>
											{pet.isFixed === true ? (
												<>
													<label className="text-sm">
														When was Spayed/Neutered{" "}
														<span className="text-xs">(YYYY-MM-DD)</span>
													</label>
													<Input
														onChange={(e) =>
															handlePetChange("fixedDate", e.target.value)
														}
														type="date"
														name="fixedDate"
														value={pet.fixedDate || ""}
													/>
												</>
											) : (
												<p className="bg-yellow-300 p-2 text-center rounder-sm">
													Spayed / Neutered is Mandatory!
												</p>
											)}
										</div>
										<label className="text-sm">Has allergies?</label>
										<Select
											className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
											value={{
												value: pet.hasAllergies,
												label: pet.hasAllergies === true ? "Yes" : " No",
											}}
											options={yesOrNoOptions}
											isSearchable={false}
											onChange={(value) =>
												handlePetChange("hasAllergies", value.value)
											}
										/>
										{pet.hasAllergies && (
											<>
												<label className="text-sm">List any allergies</label>
												<Input
													className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
													onChange={(e) =>
														handlePetChange("allergiesContent", e.target.value)
													}
													type="text"
													name="allergiesContent"
													value={pet.allergiesContent || ""}
												/>
											</>
										)}
										<label className="text-sm">
											Any current injury or concern? Explain
										</label>
										<Input
											onChange={(e) =>
												handlePetChange("currentInjuries", e.target.value)
											}
											type="text"
											name="currentInjuries"
											value={pet.currentInjuries}
										/>
										<div className="border p-2 mb-2 rounded-md">
											<label className="text-sm">Has Bordetella Vaccine?</label>
											<Select
												className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
												value={{
													value: pet.hasBordatellaVaccine,
													label:
														pet.hasBordatellaVaccine === true ? "Yes" : " No",
												}}
												options={yesOrNoOptions}
												isSearchable={false}
												onChange={(value) =>
													handlePetChange("hasBordatellaVaccine", value.value)
												}
											/>
											{pet.hasBordatellaVaccine ? (
												<>
													<label className="text-sm">
														Bordetella Vaccine Exp Date{" "}
														<span className="text-xs">(YYYY-MM-DD)</span>
													</label>
													<Input
														onChange={(e) =>
															handlePetChange(
																"bordatellaExpDate",
																e.target.value
															)
														}
														type="date"
														name="bordatellaExpDate"
														value={pet.bordatellaExpDate}
													/>
												</>
											) : (
												<p className="bg-yellow-300 p-2 text-center rounder-sm">
													Bordetella vaccine is Mandatory!
												</p>
											)}
										</div>
										<label className="text-sm">
											Regular shots Exp. Date{" "}
											<span className="text-xs">(YYYY-MM-DD)</span>
										</label>
										<Input
											onChange={(e) =>
												handlePetChange(
													"regularShotsExpireDate",
													e.target.value
												)
											}
											type="date"
											name="regularShotsExpireDate"
											value={pet.regularShotsExpireDate || ""}
										/>
									</FormGroup>
								</div>
							</>
						)}
					</Form>
				</div>
			) : (
				<div key={pet.id} className={`${index > 0 ? "mt-4" : ""}`}>
					<div
						className={`px-8 py-4 mt-2 rounded-md shadow-lg ${
							isPetReady ? "bg-green-200" : "bg-white"
						} bg-opacity-70`}
					>
						{pet.id && (
							<h5 className="text-center text-md">
								<strong>
									{pet.name && pet.name.toUpperCase()} -{" "}
									{pet.breed && pet.breed.toUpperCase()}
								</strong>
							</h5>
						)}

						<hr />
						<Form className="row">
							<div
								className={`${mode === "daycare" ? "col-md-6" : "col-md-12"}`}
							>
								<FormGroup>
									<label className="text-sm">Pet Name</label>
									<Input
										onChange={(e) => handlePetChange("name", e.target.value)}
										type="text"
										name="name"
										defaultValue={pet.name || ""}
									/>
									<label className="text-sm">Pet Type</label>
									<Select
										defaultValue={{
											value: pet.type,
											label: pet.type && pet.type,
										}}
										options={petTypeOptions}
										isSearchable={false}
										onChange={(value) => handlePetChange("type", value.value)}
									/>
									<label className="text-sm">Breed</label>
									<Input
										onChange={(e) => handlePetChange("breed", e.target.value)}
										type="text"
										name="breed"
										defaultValue={pet.breed || ""}
									/>
									<label className="text-sm">Gender</label>
									<Select
										defaultValue={{
											value: pet.gender,
											label: pet.gender ? pet.gender : "",
										}}
										options={genderOptions}
										isSearchable={false}
										onChange={(value) => handlePetChange("gender", value.value)}
									/>
									<label className="text-sm">Notes / warnings</label>
									<Input
										onChange={(e) => handlePetChange("notes", e.target.value)}
										type="text"
										name="notes"
										defaultValue={pet.notes || ""}
									/>
									<label className="text-sm">Color</label>
									<Input
										onChange={(e) => handlePetChange("color", e.target.value)}
										type="text"
										name="color"
										defaultValue={pet.color || ""}
									/>
									<label className="text-sm">Pet Date of birth</label>
									<Input
										onChange={(e) => handlePetChange("age", e.target.value)}
										type="date"
										name="age"
										defaultValue={pet.age || ""}
									/>
									<label className="text-sm">Aprox weight (lbs)</label>
									<Input
										id="petBreedToEdit"
										onChange={(e) => handlePetChange("weight", e.target.value)}
										type="number"
										min={0}
										name="weight"
										defaultValue={pet.weight || ""}
									/>
									<label className="text-sm">
										General behavior with other dogs/people
									</label>
									<Input
										onChange={(e) =>
											handlePetChange("generalBehaviour", e.target.value)
										}
										type="text"
										name="generalBehaviour"
										defaultValue={pet.generalBehaviour || ""}
									/>
								</FormGroup>
							</div>
							{mode === "daycare" && (
								<>
									<div className="col-md-6">
										<FormGroup>
											<div className="border p-2 mb-2 rounded-md">
												<label className="text-sm">Is Spayed or Neutered</label>
												<Select
													defaultValue={{
														value: pet.isFixed,
														label: pet.isFixed === true ? "Yes" : "No",
													}}
													options={yesOrNoOptions}
													isSearchable={false}
													onChange={(value) =>
														handlePetChange("isFixed", value.value)
													}
												/>
												{pet.isFixed === true ? (
													<>
														<label className="text-sm">
															When was Spayed/Neutered{" "}
															<span className="text-xs">(YYYY-MM-DD)</span>
														</label>
														<Input
															onChange={(e) =>
																handlePetChange("fixedDate", e.target.value)
															}
															type="date"
															name="fixedDate"
															defaultValue={pet.fixedDate}
														/>
													</>
												) : (
													<p className="bg-yellow-300 p-2 text-center rounder-sm">
														Spayed / Neutered is Mandatory!
													</p>
												)}
											</div>
											<label className="text-sm">Has allergies?</label>
											<Select
												className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
												defaultValue={{
													value: pet.hasAllergies,
													label: pet.hasAllergies === true ? "Yes" : " No",
												}}
												options={yesOrNoOptions}
												isSearchable={false}
												onChange={(value) =>
													handlePetChange("hasAllergies", value.value)
												}
											/>
											{pet.hasAllergies && (
												<>
													<label className="text-sm">List any allergies</label>
													<Input
														className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
														onChange={(e) =>
															handlePetChange(
																"allergiesContent",
																e.target.value
															)
														}
														type="text"
														name="allergiesContent"
														defaultValue={pet.allergiesContent || ""}
													/>
												</>
											)}
											<label className="text-sm">
												Any current injury or concern? Explain
											</label>
											<Input
												onChange={(e) =>
													handlePetChange("currentInjuries", e.target.value)
												}
												type="text"
												name="currentInjuries"
												defaultValue={pet.currentInjuries || ""}
											/>
											<div className="border p-2 mb-2 rounded-md">
												<label className="text-sm">
													Has Bordetella Vaccine?
												</label>
												<Select
													className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
													defaultValue={{
														value: pet.hasBordatellaVaccine,
														label:
															pet.hasBordatellaVaccine === true ? "Yes" : " No",
													}}
													options={yesOrNoOptions}
													isSearchable={false}
													onChange={(value) =>
														handlePetChange("hasBordatellaVaccine", value.value)
													}
												/>
												{pet.hasBordatellaVaccine ? (
													<>
														<label className="text-sm">
															Bordetella Vaccine Exp Date{" "}
															<span className="text-xs">(YYYY-MM-DD)</span>
														</label>
														<Input
															onChange={(e) =>
																handlePetChange(
																	"bordatellaExpDate",
																	e.target.value
																)
															}
															type="date"
															name="bordatellaExpDate"
															defaultValue={pet.bordatellaExpDate || ""}
														/>
													</>
												) : (
													<p className="bg-yellow-300 p-2 text-center rounder-sm">
														Bordetella vaccine is Mandatory!
													</p>
												)}
											</div>
											<label className="text-sm">
												Regular shots Exp. Date{" "}
												<span className="text-xs">(YYYY-MM-DD)</span>
											</label>
											<Input
												onChange={(e) =>
													handlePetChange(
														"regularShotsExpireDate",
														e.target.value
													)
												}
												type="date"
												name="regularShotsExpireDate"
												defaultValue={pet.regularShotsExpireDate || ""}
											/>
										</FormGroup>
									</div>
								</>
							)}
						</Form>
					</div>
				</div>
			)}
			<button
				className="btn btn-block btn-info"
				onClick={formType === "add" ? addPetHandler : updatePetHandler}
			>
				{formType === "add" ? "Add Pet" : "Save changes"}
			</button>
		</>
	)
}

export default PetUpdateFormByStaff
