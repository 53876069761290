import React from "react"
import ReportRow from "./ReportRow"

const ReportContainer = ({
	totalSalesAllEmployees,
	datePickerStart,
	datePickerEnd,
	reportReadyDaycare,
	reportReadyGroomer1,
	reportReadyGroomer2,
	reportReadyGroomer3,
	reportReadyGroomer4,
	reportReadyGroomer5,
	reportReadyGroomer6,
	reportReadyGroomer7,
	reportReadyGroomer8,
	reportReadyGroomer9,
}) => {
	const reports = [
		{ data: reportReadyDaycare, type: "daycare" },
		{ data: reportReadyGroomer1, type: "groomer" },
		{ data: reportReadyGroomer2, type: "groomer" },
		{ data: reportReadyGroomer3, type: "groomer" },
		{ data: reportReadyGroomer4, type: "groomer" },
		{ data: reportReadyGroomer5, type: "groomer" },
		{ data: reportReadyGroomer6, type: "groomer" },
		{ data: reportReadyGroomer7, type: "groomer" },
		{ data: reportReadyGroomer8, type: "groomer" },
		{ data: reportReadyGroomer9, type: "groomer" },
	]

	return (
		<div className="report-table-container mt-3 overflow-auto">
			{datePickerStart && datePickerEnd && (
				<div className="text-center mb-2 text-lg">
					<span className="mr-4 font-bold">Date Start: {datePickerStart}</span>
					<span className="font-bold mr-4">Date End : {datePickerEnd}</span>
					{totalSalesAllEmployees != null &&
					totalSalesAllEmployees !== "" &&
					!isNaN(totalSalesAllEmployees) ? (
						<span className="font-bold">
							Total Sales All Employees: $
							{Number(totalSalesAllEmployees).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					) : (
						<span className="font-bold">Total Sales All Employees: -- </span>
					)}
				</div>
			)}

			{(reportReadyDaycare ||
				reportReadyGroomer1 ||
				reportReadyGroomer2 ||
				reportReadyGroomer3 ||
				reportReadyGroomer4 ||
				reportReadyGroomer5 ||
				reportReadyGroomer6 ||
				reportReadyGroomer7 ||
				reportReadyGroomer8 ||
				reportReadyGroomer9) && (
				<table className="table table-striped">
					<thead>
						<tr>
							<th>Empl Name / Service</th>
							<th>Total Sales</th>
							<th>Total Commission</th>
							<th>Total Tips</th>
							<th>Worked on Day Off</th>
							<th>Grand Total</th>
							<th>Amount for Paystub</th>
							<th>Amount in Cash</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{reports.map(
							(report, index) =>
								report.data && (
									<ReportRow
										key={index}
										report={report.data}
										reportType={report.type}
									/>
								)
						)}
					</tbody>
				</table>
			)}
		</div>
	)
}

export default ReportContainer
