import React from "react";

const options = [
	{
		value: "schedule",
		label: (
			<span>
				<i className="fas fa-circle text-danger"></i> Block this time slot
			</span>
		),
	},
	{
		value: "cancel",
		label: (
			<span>
				<i className="fa fa-minus-circle text-danger"></i> Cancel Appointment
			</span>
		),
	},
	{
		value: "online",
		label: (
			<span>
				<i className="fas fa-circle circleIconDog"></i> Open Time for
				Appointments ONLINE
			</span>
		),
	},
	// {
	//   value: "onlineCat",
	//   label: (
	//     <span>
	//       <i className="fas fa-circle circleIconCat"></i> Open Time for Cat's
	//       Appointments
	//     </span>
	//   ),
	// },
	{
		value: "staffOnly",
		label: (
			<span>
				<i className="fas fa-circle circleIconInternal"></i> Open Spot
				Internally (STAFF ONLY)
			</span>
		),
	},
	// {
	//   value: "cat",
	//   label: (
	//     <span>
	//       <i className="fas fa-cat text-warning"></i> CAT
	//     </span>
	//   ),
	// },
	//bath Dogs
	{
		value: "bathSmallDog",
		label: (
			<span className="options_smallDog">
				<i className="fas fa-bath"></i> <i className="fas fa-dog"></i> SMALL
			</span>
		),
	},
	{
		value: "bathMediumDog",
		label: (
			<span className="options_mediumDog">
				<i className="fas fa-bath"></i> <i className="fas fa-dog"></i> MEDIUM
			</span>
		),
	},
	{
		value: "bathBigDog",
		label: (
			<span className="options_bigDog">
				<i className="fas fa-bath"></i> <i className="fas fa-dog"></i> LARGE
			</span>
		),
	},

	{
		value: "app",
		label: (
			<span className="options_smallDogGrooming">
				<i className="fas fa-cut"></i> <i className="fas fa-dog"></i> SMALL
			</span>
		),
	},
	{
		value: "mediumDog",
		label: (
			<span className="options_mediumDogGrooming">
				<i className="fas fa-cut"></i> <i className="fas fa-dog"></i> MEDIUM
			</span>
		),
	},
	{
		value: "bigDog",
		label: (
			<span className="options_bigDogGrooming">
				<i className="fas fa-cut"></i> <i className="fas fa-dog"></i> LARGE
			</span>
		),
	},
	//Requests
	{
		value: "bathSmallDogRequest",
		label: (
			<span className="options_smallDog">
				<i className="fas fa-bath"></i> <i className="fas fa-dog"></i> SMALL
				REQUEST <i className="far fa-smile"></i>
			</span>
		),
	},
	{
		value: "bathMediumDogRequest",
		label: (
			<span className="options_mediumDog">
				<i className="fas fa-bath"></i> <i className="fas fa-dog"></i> MEDIUM
				REQUEST <i className="far fa-smile"></i>
			</span>
		),
	},
	{
		value: "bathBigDogRequest",
		label: (
			<span className="options_bigDog">
				<i className="fas fa-bath"></i> <i className="fas fa-dog"></i> LARGE
				&nbsp; REQUEST <i className="far fa-smile"></i>
			</span>
		),
	},
	{
		value: "appRequest",
		label: (
			<span className="options_smallDogGrooming-request">
				<i className="fas fa-cut"></i> <i className="fas fa-dog"></i> SMALL
				REQUEST <i className="far fa-smile"></i>
			</span>
		),
	},
	{
		value: "mediumDogRequest",
		label: (
			<span className="options_mediumDogGrooming-request">
				<i className="fas fa-cut"></i> <i className="fas fa-dog"></i> MEDIUM
				REQUEST <i className="far fa-smile"></i>
			</span>
		),
	},
	{
		value: "bigDogRequest",
		label: (
			<span className="options_bigDogGrooming-request">
				<i className="fas fa-cut"></i> <i className="fas fa-dog"></i> LARGE
				REQUEST <i className="far fa-smile"></i>
			</span>
		),
	},
];

export default options;
