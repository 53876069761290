const ReleaseForm = ({ agree, handleCheckboxChange, isReleaseFormReady }) => {
	return (
		<div
			className={`mt-6 p-6 rounded-md shadow-lg ${
				isReleaseFormReady ? "bg-green-200" : "bg-white "
			} bg-opacity-70`}
		>
			<h3 className="my-2 text-center ">
				Grooming Contract, Release, and Agreement
			</h3>
			<div className="release-form-daycare p-8 rounded-md  h-500 overflow-y-auto pb-8">
				<p className="mb-4">
					The health of your pet is our primary concern. In case of emergency,
					you, the owner, designates AMAZING PET GROOMING as an agent and
					understands that AMAZING PET GROOMING will do whatever is appropiate
					for the well-being of your pet's health while in our care. If your pet
					becomes sick or injured and requires professional attention, we will
					attempt to contact you as soon as possible. However, if we are unable
					to reach you, AMAZING PET GROOMING at its sole discretion, may engage
					the services of any qualified Veterinarian in town and any expense
					shall be paid by you (the owner). AMAZING PET GROOMING will not be
					liable for any accident or injury to your pet caused while using the
					proper grooming procedures.
				</p>

				<p className="mb-4">
					AMAZING PET GROOMING has the right to refuse any services at any time.
					In the event that your pet is too stressed or becomes dangerous to
					groom, AMAZING PET GROOMING has the right to refuse grooming services,
					stop grooming services, or cancel grooming services at any time
					before, during, or after grooming and the owner will be charged a
					grooming fee (for what was done up until that point). For the safety
					of the pet being groomed, as well as the professional pet groomer, it
					is asked that you{" "}
					<span className="terms-and-conditions-important">
						Do not interrupt the groomer during the grooming process.
					</span>{" "}
					If you arrive to pick up your pet and it is still being groomed,
					please{" "}
					<span className="terms-and-conditions-important">
						DO NOT talk to your pet or allow him/her to see you.{" "}
					</span>{" "}
					Please step outside for a few moments until the groom is completed.
					Every effort will be made to insure your pet is groomed as safely as
					possible, but an excited pet can be dangerous to continue to work on.
					If you insist on talking to your pet or the groomer, we reserve the
					right to end the grooming session, even if the groom is not completed
					and the full grooming price will be charged to the owner.
				</p>

				<p className="mb-4">
					AMAZING PET GROOMING will not be held responsible for allergic
					reactions resulting from manufacturer-recommended usage of such
					products. Although a pet may experience an allergic reaction to
					grooming procedures at any given time, flea and tick products are
					often associated with a higher incidence of reactions. Please consult
					a veterinarian prior to having your pet treated if you have any
					questions or concerns regarding your pet's sensitivity to such
					treatments.
				</p>

				<p className="mb-4">
					AMAZING PET GROOMING will not be held responsible for any pre-existing
					medical condition or the aggravation of those conditions, including:
					arthritis, heart-disease, diabetes, obesity, infections, skin
					problems, or any other medical problem that may be affected by the
					grooming process.
					<br />
					<span className="terms-and-conditions-important-vaccines">
						Any new puppy / kitty clients being serviced in our salon must be up
						to date on all required vaccinations. Adult / senior, dogs / cats
						must be current on Rabies and Distemper. Although we don't require
						proof, you certify that this is true and it can be verified at any
						time.
					</span>
				</p>
				<p className="mb-4">
					My dogs/cats may be videotaped, photographed, and recorded when on
					Amazing Pet Grooming’s premises, and Amazing Pet Grooming shall be the
					exclusive owner of the results and proceeds of such taping,
					photography, and recordings with the rights, throughout the world, of
					unlimited use, and to copyright, to use and license in any manner.
				</p>
			</div>
			<div className="m-8">
				<label className="flex text-lg ">
					<input
						type="checkbox"
						checked={agree}
						onChange={handleCheckboxChange}
						className="mr-2 w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
					/>
					<span className="font-bold">
						I agree to abide by the rules and regulations of Amazing Pet
						Grooming premises.
					</span>
				</label>
			</div>
		</div>
	)
}

export default ReleaseForm
