import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
	UPDATE_FAILED,
	CLIENT_NOT_FOUND,
	INVALID_TOKEN,
	EXPIRED_TOKEN,
	NO_TOKEN,
	FORMS_UPDATED_ALREADY,
	UPDATE_FAILED_MSG,
	CLIENT_NOT_FOUND_MSG,
	INVALID_TOKEN_MSG,
	NO_TOKEN_MSG,
	FORMS_UPDATED_ALREADY_MSG,
	EXPIRED_TOKEN_MSG,
} from "../../utils/constants"

const FailureReleaseForm = () => {
	const [errorMsg, setErrorMsg] = useState("")
	const navigate = useNavigate()
	const { error } = useParams()
	useEffect(() => {
		switch (error) {
			case UPDATE_FAILED:
				setErrorMsg(UPDATE_FAILED_MSG)
				break
			case CLIENT_NOT_FOUND:
				setErrorMsg(CLIENT_NOT_FOUND_MSG)
				break
			case INVALID_TOKEN:
				setErrorMsg(INVALID_TOKEN_MSG)
				break
			case EXPIRED_TOKEN:
				setErrorMsg(EXPIRED_TOKEN_MSG)
				break
			case NO_TOKEN:
				setErrorMsg(NO_TOKEN_MSG)
				break
			case FORMS_UPDATED_ALREADY:
				setErrorMsg(FORMS_UPDATED_ALREADY_MSG)
				break
			default:
				setErrorMsg(UPDATE_FAILED_MSG)
				break
		}
	}, [error])

	return (
		<div className="mt-40 text-center">
			<h1>❌ Oops!</h1>
			<p>{errorMsg} Please contact support.</p>
			<button
				onClick={() => navigate("/")}
				className="px-10 py-2 text-lg rounded-md text-white"
				style={{
					backgroundColor: "#f44336",
				}}
			>
				Go to Homepage
			</button>
		</div>
	)
}

export default FailureReleaseForm
