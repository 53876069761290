import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const ModalGeneralPurpose = ({
	isModalOpen,
	toggleModal,
	modalContent: ModalContent,
	modalSize,
	modalProps,
}) => {
	return (
		<Modal
			style={{ maxHeight: "90vh", overflowY: "auto" }}
			modalClassName="mt-16"
			size={modalSize}
			isOpen={isModalOpen}
			toggle={toggleModal}
		>
			<ModalHeader toggle={toggleModal}></ModalHeader>
			<ModalBody
				style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto" }}
			>
				{ModalContent && <ModalContent {...modalProps} />}
			</ModalBody>
		</Modal>
	)
}

export default ModalGeneralPurpose
