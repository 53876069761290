import React from "react"
import { useNavigate } from "react-router-dom"

const SuccessReleaseForm = () => {
	const navigate = useNavigate()

	return (
		<div className="mt-40 text-center">
			<h1>🎉 Success!</h1>
			<p>Your release form has been successfully submitted. Thank you!</p>
			<button
				onClick={() => navigate("/")}
				className="px-10 py-2 text-lg rounded-md text-white"
				style={{
					backgroundColor: "#03bafc",
				}}
			>
				Go to Homepage
			</button>
		</div>
	)
}

export default SuccessReleaseForm
