import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import ImageGradient from "../ImageGradient";
import { gsap } from "gsap";
import axios from "axios";
import moment from "moment";

const Groomer = ({ onClick, groomer, petType }) => {
  const tl = useRef(null);
  const container = useRef(null);
  const [appointment, setAppointment] = useState(null);

  useEffect(() => {
    getSoonestAppointment();
  }, [groomer]);

  const getSoonestAppointment = async () => {
    const groomerName = groomer.name.toLowerCase();
    const city = localStorage.getItem("CITY").toLowerCase();

    await axios
      .get(
        `/schedule/calendar/closest_appointment/${city}/${petType}/${groomerName}`
      )
      .then((res) => {
        setAppointment(res.data);
      })
      .catch((err) => console.log(err));
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline({ paused: true }).to(".groomerImage", {
        scale: 1.03,
        boxShadow: "2px 4px 12px 0px rgba(0, 0, 0, 0.10)",
        duration: 0.2,
      });
    }, container);

    return () => ctx.revert();
  }, []);

  const appointmentObj = appointment && (
    <div className="p-1 text-sm text-center card">
      <p className="m-auto">
        First available appointment: <br />
        <b>
          {moment(appointment.start).format("MMMM Do YYYY")} <br />
          {moment(appointment.start).format("h:mm a")}
        </b>
      </p>
    </div>
  );

  return (
    <div
      className="w-full flex flex-col gap-[10px] items-center cursor-pointer"
      onClick={onClick}
      onMouseEnter={() => tl.current.play()}
      onMouseLeave={() => tl.current.reverse()}
      ref={container}
    >
      <div className="w-[150px] h-[150px] rounded-full overflow-hidden groomerImage">
        <ImageGradient image={groomer.image} color={groomer.color} bigImage />
      </div>

      <h5 className="text-base font-normal text-black">
        {groomer.name === "bath_Cambridge" ? "Bather" : groomer.name}
      </h5>
      {appointmentObj}
    </div>
  );
};

export default Groomer;
