import React from "react"

const CustomInput = ({
	type,
	value,
	name,
	onChange,
	placeholder,
	fullWidth = false,
	isMissing = false,
	color = "#6B6B6B",
	readOnlyInput,
}) => {
	return (
		<input
			readOnly={readOnlyInput}
			className={`w-full h-fit px-phone py-[10px] rounded-default border-[1px] bg-transparent 
      ${fullWidth && "md:col-span-2"}
          ${
						isMissing
							? "border-red-400 placeholder:text-red-400"
							: color === "#6B6B6B"
							? "border-gray placeholder:text-gray"
							: "border-main placeholder:text-main"
					}
      `}
			placeholder={placeholder}
			type={type}
			value={value}
			name={name}
			onChange={onChange}
		/>
	)
}

export default CustomInput
