import React, { useRef, useLayoutEffect } from "react";
import ImageGradient from "../ImageGradient";
import { Back, gsap } from "gsap";
import { HiPencil } from "react-icons/hi";

const ValidationCard = ({ color, image, bigImage, text, onClick }) => {
  const tl = useRef(null);
  const container = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline({ paused: true });
      tl.current.to(".editBg", { duration: 0.2, opacity: 1 });
      tl.current.to(
        ".editIcon",
        {
          scale: 1,
          duration: 0.2,
          ease: Back.easeOut.config(1.7),
        },
        "-=0.1"
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <div
      className="w-full h-full flex flex-col gap-[10px] items-center"
      ref={container}
    >
      <div
        className="w-full h-[100px] md:h-[150px] lg:h-[100px] rounded-default overflow-hidden relative cursor-pointer"
        onClick={onClick}
        onMouseEnter={() => {
          tl.current.duration(0.4);
          tl.current.play();
        }}
        onMouseLeave={() => {
          tl.current.duration(0.2);
          tl.current.reverse();
        }}
      >
        <ImageGradient
          color={color}
          image={image}
          bigImage={bigImage}
          centered={!bigImage}
        />
        <div className="absolute top-0 w-full h-full opacity-0 bg-black/70 editBg">
          <div className="absolute transform scale-0 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 editIcon">
            <HiPencil size={20} color="#65BFF8" />
          </div>
        </div>
      </div>
      <h5 className="mb-0 text-base font-normal text-center text-black">
        {text === "bath_Cambridge" ? "Bather" : text}
      </h5>
    </div>
  );
};

export default ValidationCard;
