import { Form, FormGroup, Input } from "reactstrap"
import Select from "react-select"

const PetPendingDetails = ({
	pets,
	handlePetChange,
	redInputHandler,
	yesOrNoOptions,
	isPetReady,
}) => {
	return (
		<>
			{pets &&
				pets.map((pet, index) => {
					return (
						<div key={pet.id} className={`${index > 0 ? "mt-4" : ""}`}>
							<div
								className={`px-8 py-4 mt-2 rounded-md shadow-lg ${
									isPetReady ? "bg-green-200" : "bg-white"
								} bg-opacity-70`}
							>
								{pet.id && (
									<h5 className="text-center text-md">
										<strong>
											{pet.name && pet.name.toUpperCase()} -{" "}
											{pet.breed && pet.breed.toUpperCase()}
										</strong>
									</h5>
								)}

								<hr />
								<Form className="row">
									<>
										<div className="col-md-12">
											<FormGroup>
												<div className="border p-2 mb-2 rounded-md">
													<label className="text-sm">
														Is Spayed or Neutered
													</label>
													<Select
														className={
															"mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
														}
														defaultValue={{
															value: pet.isFixed,
															label: pet.isFixed === true ? "Yes" : "No",
														}}
														options={yesOrNoOptions}
														isSearchable={false}
														onChange={(value) =>
															handlePetChange(index, "isFixed", value.value)
														}
													/>
													{pet.isFixed === true ? (
														<>
															<label className="text-sm">
																When was Spayed/Neutered{" "}
																<span className="text-xs">(YYYY-MM-DD)</span>
															</label>
															<Input
																className={redInputHandler(pet.fixedDate)}
																onChange={(e) =>
																	handlePetChange(
																		index,
																		"fixedDate",
																		e.target.value
																	)
																}
																type="date"
																name="fixedDate"
																defaultValue={pet.fixedDate}
															/>
														</>
													) : (
														<p className="bg-yellow-300 p-2 text-center rounder-sm">
															Spayed / Neutered is Mandatory!
														</p>
													)}
												</div>
												<label className="text-sm">Has allergies?</label>
												<Select
													className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
													defaultValue={{
														value: pet.hasAllergies,
														label: pet.hasAllergies === true ? "Yes" : " No",
													}}
													options={yesOrNoOptions}
													isSearchable={false}
													onChange={(value) =>
														handlePetChange(index, "hasAllergies", value.value)
													}
												/>
												{pet.hasAllergies && (
													<>
														<label className="text-sm">
															List any allergies
														</label>
														<Input
															className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
															onChange={(e) =>
																handlePetChange(
																	index,
																	"allergiesContent",
																	e.target.value
																)
															}
															type="text"
															name="allergiesContent"
															defaultValue={pet.allergiesContent || ""}
														/>
													</>
												)}
												<label className="text-sm">
													Any current injury or concern? Explain
												</label>
												<Input
													className={redInputHandler(pet.currentInjuries)}
													onChange={(e) =>
														handlePetChange(
															index,
															"currentInjuries",
															e.target.value
														)
													}
													type="text"
													name="currentInjuries"
													defaultValue={pet.currentInjuries || ""}
												/>
												<div className="border p-2 mb-2 rounded-md">
													<label className="text-sm">
														Has Bordetella Vaccine?
													</label>
													<Select
														className="mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500"
														defaultValue={{
															value: pet.hasBordatellaVaccine,
															label:
																pet.hasBordatellaVaccine === true
																	? "Yes"
																	: " No",
														}}
														options={yesOrNoOptions}
														isSearchable={false}
														onChange={(value) =>
															handlePetChange(
																index,
																"hasBordatellaVaccine",
																value.value
															)
														}
													/>
													{!pet.hasBordatellaVaccine && (
														<p className="bg-yellow-300 p-2 text-center rounder-sm">
															Bordetella vaccine is Mandatory!
														</p>
													)}
												</div>
											</FormGroup>
										</div>
										<div className="form-group mt-2 w-full mx-3">
											<label className="text-sm">
												Vaccine Record Photo (If no photo is uploaded, physical
												evidence is needed when dropping off your dog)
											</label>
											<input
												className={`py-1 w-full pl-1`}
												type="file"
												accept="image/*"
												onChange={(e) =>
													handlePetChange(
														index,
														"vaccineRecordImgUrl",
														e.target.files[0]
													)
												}
											/>
										</div>
										<div className="form-group mt-2 w-full mx-3">
											<label className="text-sm">
												Bordetella Vaccine Record Photo (If no photo is
												uploaded, physical evidence is needed when dropping off
												your dog)
											</label>
											<input
												className={`py-1 w-full pl-1`}
												type="file"
												accept="image/*"
												onChange={(e) =>
													handlePetChange(
														index,
														"bordatellaImgUrl",
														e.target.files[0]
													)
												}
											/>
										</div>
									</>
								</Form>
							</div>
						</div>
					)
				})}
		</>
	)
}

export default PetPendingDetails
