const BurgerMenu = ({ isMobileMenuOpen, handleMobileMenuClicked }) => {
	return (
		<div
			className="burger-icon w-[50px] z-[9999] h-[50px] flex justify-center items-center fixed top-4 right-4"
			onClick={handleMobileMenuClicked}
		>
			{/* Top Line */}
			<span
				className={`absolute w-[30px] h-[5px] bg-main rounded-[5px] transition-transform duration-300 ${
					isMobileMenuOpen
						? "rotate-45 translate-y-[5px]"
						: "-translate-y-[10px]"
				}`}
			/>
			{/* Middle Line */}
			<span
				className={`absolute w-[30px] h-[5px] bg-main rounded-[5px] transition-opacity duration-300 ${
					isMobileMenuOpen ? "opacity-0" : "opacity-100"
				}`}
			/>
			{/* Bottom Line */}
			<span
				className={`absolute w-[30px] h-[5px] bg-main rounded-[5px] transition-transform duration-300 ${
					isMobileMenuOpen
						? "-rotate-45 translate-y-[5px]"
						: "translate-y-[10px]"
				}`}
			/>
		</div>
	)
}

export default BurgerMenu
