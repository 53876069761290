import React from "react"
import { Form, FormGroup, Input, Label } from "reactstrap"

const VetDetailsForm = ({
	redInputHandler,
	vetName,
	vetPhone,
	vetClinicName,
	vetAddress,
	setVetName,
	setVetPhone,
	setVetClinicName,
	setVetAddress,
	isVetButtonReady,
}) => (
	<div
		className={`mt-6 p-6 rounded-md shadow-lg ${
			isVetButtonReady ? "bg-green-200" : "bg-white "
		} bg-opacity-70`}
	>
		<h3 className="my-2 text-center">
			<i className="fas fa-user-md"></i> Vet Information
		</h3>
		<Form>
			<p>* Fields required</p>
			<hr />
			<FormGroup>
				<div className="form-group">
					<Label>* Vet Name: </Label>
					<Input
						type="text"
						name="vetName"
						defaultValue={vetName ?? ""}
						className={redInputHandler(vetName)}
						onChange={(e) => setVetName(e.target.value)}
					/>
				</div>
				<div className="form-group">
					<Label>* Vet Phone: </Label>
					<Input
						type="text"
						name="vetPhone"
						defaultValue={vetPhone}
						className={redInputHandler(vetPhone)}
						onChange={(e) => setVetPhone(e.target.value)}
					/>
				</div>
				<div className="form-group">
					<Label>* Vet Clinic Name: </Label>
					<Input
						type="text"
						name="vetClinicName"
						defaultValue={vetClinicName}
						className={redInputHandler(vetClinicName)}
						onChange={(e) => setVetClinicName(e.target.value)}
					/>
				</div>
				<div className="form-group">
					<Label>* Vet Address: </Label>
					<Input
						className={redInputHandler(vetAddress)}
						type="text"
						name="vetAddress"
						defaultValue={vetAddress}
						onChange={(e) => setVetAddress(e.target.value)}
					/>
				</div>
			</FormGroup>
		</Form>
	</div>
)

export default VetDetailsForm
