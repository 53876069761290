import React, { useLayoutEffect, useRef } from "react";
import ImageGradient from "../ImageGradient";
import { gsap } from "gsap";

const PetType = ({ onClick, image, color, petType }) => {
  const tl = useRef(null);
  const container = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline({ paused: true });
      tl.current.to(container.current, {
        scale: 1.03,
        duration: 0.2,
        boxShadow: "2px 4px 12px 0px rgba(0, 0, 0, 0.10)",
      });
      tl.current.to(
        ".dark-tone",
        {
          opacity: 0,
          duration: 0.2,
        },
        "-=0.2"
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <div className="w-full h-full flex flex-col gap-[10px] items-center justify-center">
      <div
        className="w-full h-full rounded-default cursor-pointer relative overflow-hidden"
        onClick={onClick}
        onMouseEnter={() => tl.current.play()}
        onMouseLeave={() => tl.current.reverse()}
        ref={container}
      >
        <ImageGradient image={image} color={color} bigImage />
        <div className="absolute w-full h-full bg-black/20 top-0 left-0 dark-tone" />
      </div>
      <h5 className="text-black font-normal text-base m-0">{petType}</h5>
    </div>
  );
};

export default PetType;
