import ReleaseDaycareForm_Content from "./ReleaseDaycareForm_Content"
import ReleaseGroomingForm_Content from "./ReleaseGroomingForm_Content"

const ReleaseForm = ({
	agree,
	handleCheckboxChange,
	isReleaseFormReady,
	isGroomingAndDaycare,
}) => {
	return (
		<div
			className={`mt-6 p-6 rounded-md shadow-lg ${
				isReleaseFormReady ? "bg-green-200" : "bg-white "
			} bg-opacity-70`}
		>
			<h3 className="my-2 text-center ">Contract, Release, and Agreement</h3>
			<div className="release-form-daycare p-8 rounded-md  h-500 overflow-y-auto pb-8">
				<h6 className="font-bold">Daycare</h6>
				<ReleaseDaycareForm_Content />
				{isGroomingAndDaycare && (
					<>
						<h6 className="font-bold">Grooming</h6>
						<ReleaseGroomingForm_Content isWithinDaycareRelease />
					</>
				)}
			</div>
			<div className="m-8">
				<label className="flex text-lg ">
					<input
						type="checkbox"
						checked={agree}
						onChange={handleCheckboxChange}
						className="mr-2 w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
					/>
					<span className="font-bold">
						I agree to abide by the rules and regulations of Amazing Pet
						Grooming premises.
					</span>
				</label>
			</div>
		</div>
	)
}

export default ReleaseForm
