import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalHeader, Form, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axios from "axios";
import moment from "moment";

const GROOMER_1 = "paola";
const GROOMER_2 = "claudia";
const GROOMER_3 = "diana";
const GROOMER_4 = "frank";
const GROOMER_5 = "baths";
const GROOMER_6 = "peppa";
const GROOMER_7 = "adriana";
const GROOMER_8 = "mercedes";
const RECEPTIONIST = "courtney";
const RECEPTIONIST2 = "elizabeth";

const ModalForCommissions = ({
	openModal,
	toggle,
	mode,
	salaryIdForUpdate,
	refreshSearchResults,
	salarySearchResults,
}) => {
	const accessString = localStorage.getItem("JWT");
	const [groomerName, setGroomerName] = useState("");
	const [salaryType, setSalaryType] = useState("");
	const [baseCommissionPercent, setBaseCommissionPercent] = useState(50);
	const [hoursWorked, setHoursWorked] = useState("");
	const [hoursWage, setHoursWage] = useState("");
	const [date, setDate] = useState("");
	const [clientNumber, setClientNumber] = useState("");
	const [clientPhone, setClientPhone] = useState("");
	const [nameBreed, setNameBreed] = useState("");
	const [cost, setCost] = useState("");
	const [arrivalTime, setArrivalTime] = useState("");
	const [pickupTime, setPickupTime] = useState("");
	const [tip, setTip] = useState("");
	const [jobPosition, setJobPosition] = useState("");
	const [dayOff, setDayOff] = useState("");
	const [errMsg, setErrMsg] = useState("");
	const [employeesNames, setEmployeesNames] = useState([]);
	const [disableInput, setDisableInput] = useState(true);
	const [salaryId, setSalaryId] = useState("");

	useEffect(() => {
		getAllEmployees();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setSalaryId(salaryIdForUpdate);
		if (salaryIdForUpdate) {
			getSalaryData(salaryIdForUpdate);
		}
	}, [salaryId, salaryIdForUpdate]);

	const getSalaryData = async (id) => {
		await axios
			.get(`/auth/report/retrieve/oneEmployee/${id}`)
			.then((res) => {
				setClientNumber(res.data.clientNumber ? res.data.clientNumber : "N/A");
				setClientPhone(res.data.clientPhone ? res.data.clientPhone : "N/A");
				setNameBreed(res.data.nameBreed ? res.data.nameBreed : "N/A");
				setArrivalTime(res.data.arrivalTime ? res.data.arrivalTime : "N/A");
				setPickupTime(res.data.pickupTime ? res.data.pickupTime : "N/A");
				setCost(res.data.cost ? res.data.cost : "N/A");
				setTip(res.data.tip ? res.data.tip : "N/A");
				setGroomerName(res.data.groomerName ? res.data.groomerName : "N/A");
				setJobPosition(res.data.jobPosition ? res.data.jobPosition : "N/A");
				setSalaryType(res.data.salaryType ? res.data.salaryType : "N/A");
				setDisableInput(false);
				setDayOff(res.data.dayOff);
				setDate(moment(res.data.date).toDate());
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		if (!openModal && mode !== "edit") {
			clearInputs();
			setGroomerName("");
			setDate("");
		}
	}, [openModal, mode]);

	useEffect(() => {
		if (mode === "edit") {
			return;
		}
		clearInputs();
		if (groomerName.value === GROOMER_1 || groomerName.value === GROOMER_2) {
			setDisableInput(false);
			setSalaryType("commission");
			setBaseCommissionPercent(50);
			setJobPosition("manager");
			if (groomerName.value === GROOMER_1) {
				setDayOff(3);
			} else {
				setDayOff(2);
			}
		}
		if (
			groomerName.value === GROOMER_3 ||
			groomerName.value === GROOMER_4 ||
			groomerName.value === GROOMER_6 ||
			groomerName.value === GROOMER_7 ||
			groomerName.value === GROOMER_8
		) {
			setDisableInput(false);
			setSalaryType("commission");
			setBaseCommissionPercent(50);
			setJobPosition("groomer");
			if (groomerName.value === GROOMER_3) {
				setDayOff(1);
			} else if (groomerName.value === GROOMER_4) {
				setDayOff(1);
			} else if (groomerName.value === GROOMER_7) {
				setDayOff(6);
			} else {
				setDayOff(6);
			}
		}
		if (
			groomerName.value === GROOMER_5 ||
			groomerName.value === RECEPTIONIST ||
			groomerName.value === RECEPTIONIST2
		) {
			setDisableInput(false);
			setSalaryType("hourly");
			setDayOff(7);
			setClientNumber("N/A client number");
			setClientPhone("N/A phone");
			setNameBreed("N/A Pet");
			setArrivalTime("N/A arrival time");
			setPickupTime("N/A pickup time");
			if (
				groomerName.value === RECEPTIONIST ||
				groomerName.value === RECEPTIONIST2
			) {
				setJobPosition("receptionist");
				setCost("N/A cost");
				setTip("N/A tip");
			} else {
				setJobPosition("bather");
			}
		}
	}, [groomerName, mode]);

	const clearInputs = () => {
		setDisableInput(true);
		setSalaryType("");
		setJobPosition("");
		setDayOff();
		setClientNumber("");
		setClientPhone("");
		setNameBreed("");
		setArrivalTime("");
		setPickupTime("");
		setCost("");
		setTip("");
		setErrMsg("");
	};

	const getAllEmployees = async () => {
		await axios
			.get("/auth/employees", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => {
				let onlyEmployees = res.data.filter((user) => {
					return (
						user.jobType === "groomer1" ||
						user.jobType === "groomer2" ||
						user.jobType === "groomer3" ||
						user.jobType === "groomer4" ||
						user.jobType === "groomer5" ||
						user.jobType === "groomer6" ||
						user.jobType === "groomer7" ||
						user.jobType === "groomer8" ||
						user.jobType === "customer" ||
						user.jobType === "receptionist" ||
						user.jobType === "manager"
					);
				});
				// setAllEmployees(onlyEmployees);
				const filterNames = onlyEmployees.map((item) => {
					return { value: item.firstName, label: item.firstName.toUpperCase() };
				});
				setEmployeesNames(filterNames);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const addCommissionHandler = async (e) => {
		e.preventDefault();
		const commissionObj = {
			groomerName: groomerName.value,
			salaryType,
			baseCommissionPercent,
			hoursWorked: hoursWorked ? hoursWorked : 0,
			hoursWage: hoursWage ? hoursWage : 0,
			date: moment(date).format("YYYY-MM-DD"),
			clientNumber: clientNumber ? clientNumber : "none",
			clientPhone: clientPhone ? clientPhone : "none",
			nameBreed: nameBreed ? nameBreed : "none",
			cost: cost ? Number(cost) : 0,
			tip: tip ? Number(tip) : 0,
			arrivalTime: arrivalTime ? arrivalTime : "none",
			pickupTime: pickupTime ? pickupTime : "none",
			called: false,
			jobPosition,
			dayOff,
		};
		if (!cost) {
			return alert("Cost field is required!");
		}
		if (!tip) {
			return alert("Tip field is required!");
		}
		if (!date) {
			return alert("Date field is required!");
		}
		await axios
			.post(`/auth/salaries/add`, commissionObj)
			.then(() => {
				if (salarySearchResults.length) {
					refreshSearchResults();
				}
				clearInputs();
				setGroomerName("");
				setDate("");
				toggle();
			})
			.catch((err) => console.log(err));
	};

	const editCommission = async (e) => {
		e.preventDefault();
		const commissionObj = {
			groomerName: groomerName.value,
			salaryType,
			baseCommissionPercent,
			hoursWorked: hoursWorked ? hoursWorked : 0,
			hoursWage: hoursWage ? hoursWage : 0,
			date: moment(date).format("YYYY-MM-DD"),
			clientNumber: clientNumber ? clientNumber : "none",
			clientPhone: clientPhone ? clientPhone : "none",
			nameBreed: nameBreed ? nameBreed : "none",
			cost: cost ? Number(cost) : 0,
			tip: tip ? Number(tip) : 0,
			arrivalTime: arrivalTime ? arrivalTime : "none",
			pickupTime: pickupTime ? pickupTime : "none",
			called: false,
			jobPosition,
			dayOff,
		};
		if (!cost) {
			return alert("Cost field is required!");
		}
		if (!tip) {
			return alert("Tip field is required!");
		}
		if (!date) {
			return alert("Date field is required!");
		}
		await axios
			.put(`/auth/salaries/update/${salaryIdForUpdate}`, commissionObj)
			.then(() => {
				if (salarySearchResults.length) {
					refreshSearchResults();
				}
				toggle();
			})
			.catch((err) => console.log(err));
	};
	return (
		<Modal
			modalClassName="modal-add-clients"
			isOpen={openModal}
			toggle={toggle}
		>
			<ModalHeader toggle={toggle}>
				{mode.toUpperCase()} commission or Salary
			</ModalHeader>
			<ModalBody>
				<Form onSubmit={mode === "add" ? addCommissionHandler : editCommission}>
					{mode === "add" ? (
						<Select
							value={groomerName}
							options={employeesNames}
							placeholder="Employee Name"
							isSearchable={false}
							onChange={(value) => setGroomerName(value)}
						/>
					) : (
						<p>
							<b>Employee Name: {groomerName.toUpperCase()}</b>
						</p>
					)}
					<div className="d-flex justify-content-between align-items-center">
						<label className="mr-2 pt-2">
							{mode === "add" ? "Date:" : "Current Date:"}
						</label>
						<DatePicker
							className="form-commission"
							selected={date}
							onChange={(date) => setDate(date)}
							dateFormat="yyyy-MM-dd"
							placeholderText={mode === "add" ? "Date" : "Enter new date"}
						/>
					</div>
					{jobPosition && salaryType && (
						<>
							<p className="mt-3">
								Job Position: <b>{jobPosition && jobPosition.toUpperCase()}</b>
							</p>
							<p>
								Salary Type: <b>{salaryType && salaryType.toUpperCase()}</b>
							</p>
						</>
					)}

					{salaryType === "commission" ? (
						<>
							<p>
								Commission Percent: <b>{baseCommissionPercent}%</b>
							</p>
							<p>
								Day Off:{" "}
								<b>
									{dayOff === 1
										? "MONDAY"
										: dayOff === 2
										? "TUESDAY"
										: dayOff === 3
										? "WEDNESDAY"
										: dayOff === 4
										? "THURSDAY"
										: dayOff === 5
										? "FRIDAY"
										: dayOff === 6
										? "SATURDAY"
										: "SUNDAY"}
								</b>
							</p>
						</>
					) : salaryType === "hourly" ? (
						<>
							<Input
								className="form-commission"
								value={hoursWorked}
								name="hoursWorked"
								onChange={(e) => setHoursWorked(e.target.value)}
								placeholder="Number of hours worked"
								type="text"
							/>
							<Input
								className="form-commission"
								value={hoursWage}
								name="hoursWage"
								onChange={(e) => setHoursWage(e.target.value)}
								placeholder="Employee Wage (per hour)"
								type="text"
							/>
						</>
					) : null}
					<div className="input-label-wrapper">
						<label className="">Cost: </label>
						<input
							readOnly={
								disableInput ||
								groomerName.value === RECEPTIONIST ||
								groomerName.value === RECEPTIONIST2
							}
							value={cost}
							name="cost"
							onChange={(e) => setCost(e.target.value)}
							placeholder="Cost"
							type="text"
						/>
					</div>
					<div className="input-label-wrapper">
						<label className="">Tip:</label>
						<input
							readOnly={
								disableInput ||
								groomerName.value === RECEPTIONIST ||
								groomerName.value === RECEPTIONIST2
							}
							value={tip}
							name="tip"
							onChange={(e) => setTip(e.target.value)}
							placeholder="Tip"
							type="text"
						/>
					</div>
					<div className="input-label-wrapper">
						<label htmlFor="">Client #: </label>
						<input
							readOnly={
								disableInput ||
								groomerName.value === GROOMER_5 ||
								groomerName.value === RECEPTIONIST ||
								groomerName.value === RECEPTIONIST2
							}
							value={clientNumber}
							name="clientNumber"
							onChange={(e) => setClientNumber(e.target.value)}
							placeholder="Client Number"
							type="text"
						/>
					</div>
					<div className="input-label-wrapper">
						<label htmlFor="">Client Phone: </label>
						<input
							readOnly={
								disableInput ||
								groomerName.value === GROOMER_5 ||
								groomerName.value === RECEPTIONIST ||
								groomerName.value === RECEPTIONIST2
							}
							value={clientPhone}
							name="clientPhone"
							onChange={(e) => setClientPhone(e.target.value)}
							placeholder="Client Phone"
							type="text"
						/>
					</div>
					<div className="input-label-wrapper">
						<label htmlFor="">Name Breed: </label>
						<input
							readOnly={
								disableInput ||
								groomerName.value === GROOMER_5 ||
								groomerName.value === RECEPTIONIST ||
								groomerName.value === RECEPTIONIST2
							}
							value={nameBreed}
							name="nameBreed"
							onChange={(e) => setNameBreed(e.target.value)}
							placeholder="Pet Name and breed"
							type="text"
						/>
					</div>
					<div className="input-label-wrapper">
						<label htmlFor="">Arrival time: </label>
						<input
							readOnly={
								disableInput ||
								groomerName.value === GROOMER_5 ||
								groomerName.value === RECEPTIONIST ||
								groomerName.value === RECEPTIONIST2
							}
							value={arrivalTime}
							name="arrivalTime"
							onChange={(e) => setArrivalTime(e.target.value)}
							placeholder="Arrival Time"
							type="text"
						/>
					</div>
					<div className="input-label-wrapper">
						<label htmlFor="">Pickup Time: </label>
						<input
							readOnly={
								disableInput ||
								groomerName.value === GROOMER_5 ||
								groomerName.value === RECEPTIONIST ||
								groomerName.value === RECEPTIONIST2
							}
							value={pickupTime}
							name="pickupTime"
							onChange={(e) => setPickupTime(e.target.value)}
							placeholder="Pickup Time"
							type="text"
						/>
					</div>

					<Button
						disabled={disableInput}
						className="addPet-btn"
						block
						color="warning"
					>
						Add
					</Button>
					<p className="error-message-registration text-align-center margin-top-small">
						{errMsg}
					</p>
				</Form>
			</ModalBody>
		</Modal>
	);
};

export default ModalForCommissions;
