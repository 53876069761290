import { useState, useEffect } from "react"
import CommentsTable from "./CommentsTable"
import LoadPage from "../LoadingPage"
import axios from "axios"
import { MdAddPhotoAlternate } from "react-icons/md"
import { FaScissors, FaPaw } from "react-icons/fa6"
import PetFormByStaff from "../../pages/ClientRegistration/PetFormByStaffCambridge"
import Select from "react-select"
import {
	yesOrNoOptions,
	genderOptions,
	petTypeOptions,
} from "../../utils/constants"
import { optionsGroomers } from "../../pages/Schedule/Schedule.options"
import API from "../../utils/APICAMBRIDGE"
import {
	Label,
	Button,
	Form,
	FormGroup,
	Input,
	Modal,
	ModalBody,
} from "reactstrap"
import CommentUpdateForm from "./CommentUpdateForm"
import momentTimeZone from "moment-timezone"

const PetInformationCard = ({
	pet,
	commentsData,
	fetchData,
	enrollmentType,
	setEnrollmentType,
}) => {
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [vaccineImg, setVaccineImg] = useState("")
	const [vaccineImgType, setVaccineImgType] = useState("")
	const [showUpdateForm, setShowUpdateForm] = useState(false)

	// COMMENTS
	const [date, setDate] = useState("")
	const [groomer, setGroomer] = useState("")
	const [commentToBeEdited, setCommentToBeEdited] = useState(null)
	const [comment, setComment] = useState("")
	const [openGroomingComments, setOpenGroomingComments] = useState(false)

	// MODALS
	const [openVaccineModal, setOpenVaccineModal] = useState(false)
	const [petOpenModal, setPetOpenModal] = useState(null)

	// PET
	const [petToUpdateIndex, setPetToUpdateIndex] = useState(null)
	const [petDataToUpdate, setPetDataToUpdate] = useState(null)

	useEffect(() => {
		if (pet) {
			setPetDataToUpdate(pet)
		}
	}, [pet])

	const handleImageUpload = async (e, imgType) => {
		const file = e.target.files[0] || petDataToUpdate[imgType]
		const PetId = pet.id
		const fd = new FormData()
		fd.append("file", file)
		fd.append("upload_preset", "amazing")
		try {
			setLoadingAxiosReq(true)
			const res = await axios.post(
				"https://api.cloudinary.com/v1_1/amazing-pet-grooming/image/upload",
				fd
			)
			let newPetImg = {
				[imgType]: res.data.secure_url,
			}

			await axios.put(`/api/pets_cambridge/${PetId}`, newPetImg)
			fetchData()
			setLoadingAxiosReq(false)
		} catch (error) {
			console.log(error)
			setLoadingAxiosReq(false)
		}
	}

	const handleAddCommentSubmit = async (e, petId) => {
		e.preventDefault()
		if (!date || !comment) {
			return alert("Values cannot be empty!")
		}
		setLoadingAxiosReq(true)
		let commentObj = {
			comment: comment,
			date: date,
			groomer: groomer,
		}
		await API.addComment(petId, commentObj)
			.then(() => {
				setComment("")
				setDate("")
				setGroomer("")
				fetchData()
				setTimeout(() => {
					// setOpenGroomingComments(false)
					setLoadingAxiosReq(false)
				}, 1000)
			})
			.catch((error) => {
				setLoadingAxiosReq(false)
				console.log(error)
			})
	}

	const redInputHandler = (value) => {
		return `${
			value == null || value == "" ? "border-red-input" : ""
		} mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500`
	}

	const handlePetChange = (property, value) => {
		setPetDataToUpdate({ ...petDataToUpdate, [property]: value })
	}

	const updatePetHandler = async (e) => {
		e.preventDefault()
		const id = petDataToUpdate.id
		setLoadingAxiosReq(true)

		await axios
			.put(`/api/pets_cambridge/${id}`, petDataToUpdate)
			.then(() => {
				fetchData()
				setTimeout(() => {
					setPetOpenModal(false)
					setLoadingAxiosReq(false)
				}, 1000)
			})
			.catch((err) => {
				console.log(err)
				setLoadingAxiosReq(false)
			})
	}

	const deletePet = async () => {
		if (
			window.confirm(`Are you sure you wish to delete this Pet permanently?`)
		) {
			const petSoftDelete = {
				deletedAt: momentTimeZone(new Date()).format("YYYY/MM/DD hh:mm"),
				deletedBy: localStorage.getItem("USERNAME"),
			}

			await axios
				.put(`/api/pets_cambridge/${pet.id}`, petSoftDelete)
				.then(() => {
					fetchData()
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	return (
		<div className="card py-2 my-2">
			<h5 className="capitalize text-center border-b border-b-slate-200 pb-1">
				{pet.name}
			</h5>
			<div>
				<div className="flex px-4 sm:px-2 md:flex-row flex-col items-center justify-around">
					{/* Left Section */}
					<div>
						<div className="w-[100px]">
							{pet.petImg ? (
								<label className="cursor-pointer px-1 border">
									<input
										type="file"
										accept="image/*"
										className="hidden"
										onChange={(e) => handleImageUpload(e, "petImg")}
									/>
									<img className="w-full" src={pet.petImg} alt="Pet" />
									<span className="mt-2 text-lg flex justify-center">
										<MdAddPhotoAlternate />
									</span>
								</label>
							) : (
								<label className="cursor-pointer border px-1">
									<input
										type="file"
										accept="image/*"
										className="hidden"
										onChange={(e) => handleImageUpload(e, "petImg")}
									/>
									<img
										className="w-full"
										src={`${
											pet.type.toLowerCase() === "dog"
												? "/images/dog_black.png"
												: "/images/cat_black.png"
										}`}
										alt="Dog and cat"
									/>
									<span className="mt-2 text-lg flex justify-center">
										<MdAddPhotoAlternate />
									</span>
								</label>
							)}
						</div>

						<h6 className="text-md mt-2">
							Pet Name:
							<span
								className={`capitalize ${
									commentsData > 0 ? "text-green-500" : ""
								}`}
							>
								<b> {pet && pet.name}</b>
							</span>
						</h6>
						<h6 className="text-md">
							Breed:{" "}
							<span className="capitalize">
								<b>{pet && pet.breed}</b>
							</span>
						</h6>
						<h6 className="text-md">
							Type:{" "}
							<span className="capitalize">
								<b>{pet && pet.type}</b>
							</span>
						</h6>
						<h6 className="text-md md:max-w-[300px] max-h-[100px] overflow-auto">
							Warnings:{" "}
							<span className="notesForPets">
								<b>{pet && pet.notes}</b>
							</span>
						</h6>
						<h6 className="text-md">
							Weight:
							<span className="capitalize">
								<b> {pet && pet.weight}</b>
							</span>
						</h6>
						{pet.color && (
							<h6 className="text-md capitalize">
								Color:{" "}
								<span>
									<b>{pet && pet.color}</b>
								</span>
							</h6>
						)}
					</div>
					{/* Right Section */}
					<div className="flex flex-col">
						{pet.age && (
							<h6 className="text-md">
								DOB:{" "}
								<span>
									<b>{pet && pet.age}</b>
								</span>
							</h6>
						)}
						{pet.fixedDate && (
							<h6 className="text-md">
								Neutered / Spayed on:{" "}
								<span>
									<b>{pet && pet.fixedDate}</b>
								</span>
							</h6>
						)}
						{pet.generalBehaviour && (
							<h6 className="text-md">
								General Behaviour:{" "}
								<span className="capitalize">
									<b>{pet && pet.generalBehaviour}</b>
								</span>
							</h6>
						)}
						<div className="border p-1 mt-2">
							{pet.regularShotsExpireDate && (
								<h6 className="text-md">
									Regular Shots exp date:
									<span>
										<b> {pet && pet.regularShotsExpireDate}</b>
									</span>
								</h6>
							)}
							{pet.vaccineRecordImgUrl ? (
								<div className="p-2 flex flex-col">
									<button
										onClick={() => {
											setVaccineImg(pet.vaccineRecordImgUrl)
											setVaccineImgType("Regular Shots Record")
											setOpenVaccineModal(true)
										}}
										className="bg-cyan-600 rounded-sm text-white"
									>
										View Regular Shots
									</button>
									<label className="cursor-pointer">
										<input
											type="file"
											accept="image/*"
											className="hidden"
											onChange={(e) =>
												handleImageUpload(e, "vaccineRecordImgUrl")
											}
										/>
										<span className="mt-2 text-white flex justify-center bg-blue-300 w-full">
											<span className="text-center">Update Record Image</span>
											<span className="mt-2 text-lg flex justify-center">
												<MdAddPhotoAlternate />
											</span>
										</span>
									</label>
								</div>
							) : (
								<>
									<span>Regular Vaccines</span>
									<label className="cursor-pointer justify-center flex">
										<input
											type="file"
											accept="image/*"
											className="hidden"
											onChange={(e) =>
												handleImageUpload(e, "vaccineRecordImgUrl")
											}
										/>
										<span className="mt-2 text-white flex justify-center bg-blue-300 w-full">
											<span className="text-center">Update Record Image</span>
											<span className="mt-2 text-lg flex justify-center">
												<MdAddPhotoAlternate />
											</span>
										</span>
									</label>
								</>
							)}
						</div>

						<div className="border p-1 mt-2">
							{pet.bordatellaExpDate && (
								<h6 className="text-md text-center mt-2">
									Bordetella exp date:{" "}
									<span>
										<b>{pet && pet.bordatellaExpDate}</b>
									</span>
								</h6>
							)}
							{pet.bordatellaImgUrl ? (
								<div className="p-2 flex flex-col">
									<button
										onClick={() => {
											setVaccineImg(pet.bordatellaImgUrl)
											setVaccineImgType("Bordetella Shots Record")
											setOpenVaccineModal(true)
										}}
										className="bg-cyan-600 rounded-sm text-white"
									>
										View Bordetella Shots
									</button>
									<label className="cursor-pointer">
										<input
											type="file"
											accept="image/*"
											className="hidden"
											onChange={(e) => handleImageUpload(e, "bordatellaImgUrl")}
										/>
										<span className="mt-2 text-white flex justify-center bg-blue-300 w-full">
											<span className="text-center">Update Record Image</span>
											<span className="mt-2 text-lg flex justify-center">
												<MdAddPhotoAlternate />
											</span>
										</span>
									</label>
								</div>
							) : (
								<>
									<span>Bordetella Vaccine</span>
									<label className="cursor-pointer justify-center flex">
										<input
											type="file"
											accept="image/*"
											className="hidden"
											onChange={(e) => handleImageUpload(e, "bordatellaImgUrl")}
										/>
										<span className="mt-2 text-white flex justify-center bg-blue-300 w-full">
											<span className="text-center">Update Record Image</span>
											<span className="mt-2 text-lg flex justify-center">
												<MdAddPhotoAlternate />
											</span>
										</span>
									</label>
								</>
							)}
						</div>
					</div>
				</div>
				<hr />
				{/* Action Buttons */}
				<div className="flex flex-row flex-wrap justify-evenly px-2">
					<button
						onClick={() => setOpenGroomingComments(true)}
						className="py-1 px-3 bg-yellow-500 text-white rounded-md"
					>
						<FaScissors className="inline-block" /> Comments
					</button>
					{/* <button className="py-1 px-3 bg-pink-400 text-white rounded-md">
						<IoTennisball className="inline-block" /> Comments Daycare
					</button> */}
					<button
						onClick={() => setPetOpenModal(true)}
						className="py-1 px-14 bg-blue-400 text-white rounded-md"
					>
						Update Pet <FaPaw className="inline-block ml-1" />
					</button>
					{localStorage.getItem("JOBTYPE") === "admin" && (
						<button
							onClick={deletePet}
							className="py-1 px-14 bg-red-500 text-white rounded-md"
						>
							Delete Pet
						</button>
					)}
				</div>
			</div>

			{/* Update Pet */}
			<Modal
				size="lg"
				isOpen={petOpenModal}
				toggle={() => setPetOpenModal(!petOpenModal)}
			>
				<ModalBody>
					<div
						onClick={() => setPetOpenModal(false)}
						className="text-right cursor-pointer p-1"
					>
						X
					</div>
					<div className="flex justify-between">
						<button
							className="bg-pink text-white rounded-md px-2 py-1"
							onClick={() =>
								setEnrollmentType(
									enrollmentType === "daycare" || enrollmentType === "full"
										? "grooming"
										: "daycare"
								)
							}
						>
							Click here for{" "}
							{enrollmentType === "daycare" || enrollmentType === "full"
								? "grooming"
								: "daycare"}{" "}
							Details{" "}
							{enrollmentType === "daycare" && (
								<span>
									only <FaScissors className="inline-block" />
								</span>
							)}
						</button>
						<p className="capitalize text-lg bg-purple-500 text-white px-2 font-bold">
							{enrollmentType === "full" ? "Full enrollment" : enrollmentType}
						</p>
					</div>
					<PetFormByStaff
						redInputHandler={redInputHandler}
						pet={petDataToUpdate}
						index={petToUpdateIndex}
						yesOrNoOptions={yesOrNoOptions}
						genderOptions={genderOptions}
						petTypeOptions={petTypeOptions}
						handlePetChange={handlePetChange}
						isPetReady={false}
						formType={"edit"}
						mode={enrollmentType === "full" ? "daycare" : enrollmentType}
						updatePetHandler={updatePetHandler}
					/>
				</ModalBody>
			</Modal>

			{loadingAxiosReq && <LoadPage />}
			{/* Comments Grooming */}
			<Modal
				size="xl"
				isOpen={openGroomingComments}
				toggle={() => setOpenGroomingComments(!openGroomingComments)}
			>
				<ModalBody>
					<div>
						<div className="flex justify-between">
							<h5 className="px-2 rounded-sm capitalize bg-blue-800 text-white">
								Pet Name: {pet.name}
							</h5>
							<span
								className="cursor-pointer"
								onClick={() => setOpenGroomingComments(false)}
							>
								X
							</span>
						</div>
						{commentToBeEdited ? (
							<>
								<button
									onClick={() => setCommentToBeEdited(null)}
									className="bg-cyan-500 mb-2 text-lg px-4 py-1 rounded-md text-white"
								>
									&#x2190; Back
								</button>
								<CommentUpdateForm
									commentToBeEdited={commentToBeEdited}
									optionsGroomers={optionsGroomers}
									setCommentToBeEdited={setCommentToBeEdited}
									fetchData={fetchData}
								/>
							</>
						) : (
							<>
								<Form className="bg-orange-100 rounded-md px-4 py-2">
									<FormGroup>
										<h4 className="text-left text-md">Add a comment</h4>
										<div className="row">
											<div className="col-xl-3 col-md-3">
												<Label>Date Format: YYYY/MM/DD</Label>
												<Input
													onChange={(e) => setDate(e.target.value)}
													type="date"
													name="date"
													defaultValue={commentsData.date}
												/>
											</div>
											<div className="col-xl-7 col-md-5 commentSectionAddComment">
												<Label>Comments</Label>
												<Input
													placeholder="Enter Notes"
													onChange={(e) => setComment(e.target.value)}
													type="text"
													name="comment"
													defaultValue={commentsData.comment}
												></Input>
											</div>
											<div className="absolute right-6 top-14">
												<Select
													name="form-field-name"
													options={optionsGroomers}
													placeholder="Groomer:"
													isSearchable={false}
													onChange={(value) => setGroomer(value.value)}
												/>
											</div>
										</div>
										{loadingAxiosReq ? (
											<LoadPage />
										) : (
											<Button
												onClick={(e) => handleAddCommentSubmit(e, pet.id)}
												block
												value={pet.id}
												className="addCommentBtn"
												color="warning"
											>
												Add Comment
											</Button>
										)}
									</FormGroup>
								</Form>
								<h4 className="mt-4">Notes History</h4>
								<CommentsTable
									showUpdateForm={showUpdateForm}
									fetchData={fetchData}
									setShowUpdateForm={setShowUpdateForm}
									commentsData={commentsData}
									setOpenGroomingComments={setOpenGroomingComments}
									setCommentToBeEdited={setCommentToBeEdited}
								/>
							</>
						)}
					</div>
				</ModalBody>
			</Modal>

			{/* Vaccine Records Image */}
			<Modal
				size="xl"
				isOpen={openVaccineModal}
				toggle={() => setOpenVaccineModal(!openVaccineModal)}
			>
				<ModalBody>
					<h4 className="capitalize flex justify-between">
						{vaccineImgType}{" "}
						<span
							className="cursor-pointer"
							onClick={() => setOpenVaccineModal(false)}
						>
							X
						</span>
					</h4>
					<hr />
					<img
						className="f-full m-auto"
						src={vaccineImg}
						alt="vaccine record"
					/>
				</ModalBody>
			</Modal>
		</div>
	)
}

export default PetInformationCard
