import { useEffect, useState, useContext } from "react"
import { Button } from "reactstrap"
import "./style.scss"
import {
	yesOrNoOptions,
	genderOptions,
	petObj,
	petTypeOptions,
} from "../../utils/constants"
import axios from "axios"
import PetDetails from "./PetDetails"
import OwnerFormComponent from "./OwnerDetailsForm"
import VetDetailsForm from "./VetDetailsForm"
import FloatingWindow from "./FloatingWindow"
import ReleaseFormDaycare from "./ReleaseFormDaycare"
import ReleaseFormGrooming from "./ReleaseFormGrooming"
import LoadPage from "../../components/LoadingPage"
import { v4 as uuidv4 } from "uuid"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import { CiWarning } from "react-icons/ci"

export default function ClientRegistration({ mode }) {
	const { setShowBookTrialDayModal } = useContext(RegistrationContext)
	const userAccDetails = localStorage.getItem("FOUND_ACCOUNT")
	const CITY = localStorage.getItem("CITY")

	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)

	// Owner States
	const [clientID, setClientID] = useState("")
	const [lastName, setLastName] = useState("")
	const [firstName, setFirstName] = useState("")
	const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("")
	const [cellphone, setCellphone] = useState("")
	const [workPhone, setWorkPhone] = useState("")
	const [email, setEmail] = useState("")
	const [email2, setEmail2] = useState("")
	const [address, setAddress] = useState("")
	const [isPasswordTemporary, setIsPasswordTemporary] = useState("")
	const [emailError, setEmailError] = useState("")
	const [isSubscribed, setIsSubscribed] = useState(true)

	// Pet States
	const [pets, setPets] = useState([])
	const [isOwnerButtonReady, setIsOwnerButtonReady] = useState(false)
	const [isVetButtonReady, setIsVetButtonReady] = useState(false)
	const [isPetButtonReady, setIsPetButtonReady] = useState(false)
	const [isReleaseFormReady, setIsReleaseFormReady] = useState(false)

	// Vet States
	const [vetName, setVetName] = useState("")
	const [vetPhone, setVetPhone] = useState("")
	const [vetClinicName, setVetClinicName] = useState("")
	const [vetAddress, setVetAddress] = useState("")
	const [agreeReleaseForm, setAgreeReleaseForm] = useState(false)

	// useEffect(() => {
	// 	if (mode !== "daycare") {
	// 		localStorage.removeItem("FOUND_ACCOUNT")
	// 	}
	// }, [userAccDetails])

	useEffect(() => {
		if (!CITY) {
			window.location.href = "/"
		}
		window.scrollTo(0, 0)
		if (pets.length === 0) {
			addPetForm()
		}
	}, [CITY])

	const handleCheckboxChange = () => {
		setAgreeReleaseForm(!agreeReleaseForm)
	}
	const handleCheckboxMarketingChange = () => {
		setIsSubscribed(!isSubscribed)
	}

	const removePetFormHandler = (temporaryId) => {
		if (window.confirm("Are you sure you want to remove this pet form?")) {
			const filteredPets = pets.filter((pet) => {
				return pet.temporaryId !== temporaryId
			})
			setPets(filteredPets)
		}
	}

	const addPetForm = () => {
		if (pets.length > 0) {
			if (window.confirm("Are you sure you want to add another pet?")) {
				const newPet = { ...petObj, temporaryId: uuidv4() }
				setPets([...pets, newPet])
			}
		} else {
			const newPet = { ...petObj, temporaryId: uuidv4() }
			setPets([...pets, newPet])
		}
	}

	useEffect(() => {
		enablePetButtonHandler()
	}, [pets])

	useEffect(() => {
		enableOwnerButtonHandler()
	}, [lastName, firstName, primaryPhoneNumber, email])

	useEffect(() => {
		if (agreeReleaseForm) {
			setIsReleaseFormReady(true)
		} else {
			setIsReleaseFormReady(false)
		}
	}, [agreeReleaseForm])

	const redInputHandler = (value) => {
		return `${
			value == null || value == "" ? "border-red-input" : ""
		} mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500`
	}

	const enablePetButtonHandler = () => {
		if (pets.length === 0) {
			setIsPetButtonReady(false)
			return
		}
		const isAnyFieldEmpty = pets.some(
			({
				name,
				breed,
				// age,
				// gender,
				// weight,
				// fixedDate,
				// currentInjuries,
				// generalBehaviour,
				hasBordatellaVaccine,
				hasRegularVaccine,
			}) =>
				[
					name,
					breed,
					// age,
					// gender,
					// weight,
					// fixedDate,
					// currentInjuries,
					// generalBehaviour,
					hasBordatellaVaccine,
					hasRegularVaccine,
				].some((field) => field === null || field === "" || field === false)
		)
		const isAnyGroomingFieldEmpty = pets.some(({ name, breed }) =>
			[name, breed].some((field) => field === null || field === "")
		)

		if (mode === "daycare") {
			setIsPetButtonReady(!isAnyFieldEmpty)
		} else {
			setIsPetButtonReady(!isAnyGroomingFieldEmpty)
		}
	}

	const enableOwnerButtonHandler = () => {
		const isEmailValid = email && email.includes("@")

		if (
			lastName &&
			firstName &&
			primaryPhoneNumber
			// email &&
			// email2 &&
			// address &&
			// address.length > 5 &&
			// isEmailValid
		) {
			setIsOwnerButtonReady(true)
		} else {
			setIsOwnerButtonReady(false)
		}
	}

	useEffect(() => {
		if (userAccDetails) {
			const parsedUserAccDetails = JSON.parse(userAccDetails)
			setPets(
				CITY === "milton"
					? parsedUserAccDetails.Pets
					: parsedUserAccDetails.PetCambridges
			)
			setClientID(parsedUserAccDetails.id)
			setLastName(parsedUserAccDetails.lastName)
			setFirstName(parsedUserAccDetails.firstName)
			setPrimaryPhoneNumber(parsedUserAccDetails.primaryPhoneNumber)
			setCellphone(parsedUserAccDetails.cellphone)
			setWorkPhone(parsedUserAccDetails.workPhone)
			setAddress(parsedUserAccDetails.address)
			setEmail(parsedUserAccDetails.email)
			setIsPasswordTemporary(parsedUserAccDetails.isPasswordTemporary || true)
			if (CITY === "milton") {
				if (parsedUserAccDetails.Vet) {
					setVetName(parsedUserAccDetails.Vet.vetName)
					setVetPhone(parsedUserAccDetails.Vet.vetPhone)
					setVetClinicName(parsedUserAccDetails.Vet.vetClinicName)
					setVetAddress(parsedUserAccDetails.Vet.vetAddress)
				}
			} else {
				if (parsedUserAccDetails.VetCambridge) {
					setVetName(parsedUserAccDetails.VetCambridge.vetName)
					setVetPhone(parsedUserAccDetails.VetCambridge.vetPhone)
					setVetClinicName(parsedUserAccDetails.VetCambridge.vetClinicName)
					setVetAddress(parsedUserAccDetails.VetCambridge.vetAddress)
				}
			}
		}
	}, [userAccDetails])

	const fileUploadHandler = async (index, values, vaccineType) => {
		try {
			const updatedImages = await Promise.all(
				values.map(async (value) => {
					const fd = new FormData()
					fd.append("file", value)
					fd.append("upload_preset", "amazing")

					const res = await axios.post(
						"https://api.cloudinary.com/v1_1/amazing-pet-grooming/image/upload",
						fd
					)

					return {
						type:
							vaccineType === "bordatella"
								? "bordatellaImgUrl"
								: "vaccineRecordImgUrl",
						url: res.data.secure_url,
					}
				})
			)

			return updatedImages
		} catch (err) {
			console.log(err)
			return []
		}
	}

	const handlePetChange = async (index, property, value) => {
		const updatedPets = [...pets]
		updatedPets[index] = {
			...updatedPets[index],
			[property]: value,
		}
		setPets(updatedPets)
	}

	const petsList = pets.length ? (
		pets.map((pet, index) => (
			<div
				key={pet.id || pet.temporaryId || `index-${index}`}
				className={`${index > 0 ? "mt-4" : ""}`}
			>
				<PetDetails
					pet={pet}
					index={index}
					handlePetChange={handlePetChange}
					yesOrNoOptions={yesOrNoOptions}
					genderOptions={genderOptions}
					petTypeOptions={petTypeOptions}
					redInputHandler={redInputHandler}
					isPetReady={isPetButtonReady}
					mode={mode}
					removePetFormHandler={removePetFormHandler}
				/>
			</div>
		))
	) : (
		<div className="col">
			<h2 className="noPetsYetTitle">No Pets have been added yet</h2>
		</div>
	)

	const validatePhoneNumber = (phoneNumber) => {
		var phoneRegex = /^\d{10}$/

		if (phoneRegex.test(phoneNumber)) {
			return true
		} else {
			return false
		}
	}

	const saveAllForms = async () => {
		const CITY = localStorage.getItem("CITY")

		const isPrimaryPhoneValid = validatePhoneNumber(primaryPhoneNumber)
		const isCellPhoneValid = validatePhoneNumber(cellphone)
		const isWorkPhoneValid = validatePhoneNumber(workPhone)
		if (primaryPhoneNumber && !isPrimaryPhoneValid) {
			return alert(
				"Invalid Primary phone number. Please enter a 10-digit number without spaces or special characters."
			)
		}
		if (cellphone && !isCellPhoneValid) {
			return alert(
				"Invalid Cellphone number. Please enter a 10-digit number without spaces or special characters or simply leave it blank."
			)
		}
		if (workPhone && !isWorkPhoneValid) {
			return alert(
				"Invalid Work phone number. Please enter a 10-digit number without spaces or special characters or simply leave it blank."
			)
		}

		const dataObj = {
			clientID,
			lastName,
			firstName,
			primaryPhoneNumber,
			address,
			email,
			cellphone,
			workPhone,
			isPasswordTemporary,
			isSubscribed,
			pets,
			vetName: "not saved yet",
			vetPhone: "not saved yet",
			vetClinicName: "not saved yet",
			vetAddress: "not saved yet",
		}

		if (mode === "daycare") {
			dataObj.signedDaycareRelease = true
			dataObj.signedGroomingRelease = true
		} else {
			dataObj.signedGroomingRelease = true
		}

		if (email && email !== email2) {
			return alert(
				"Please verify that your email is correct and that both fields match."
			)
		}

		setLoadingAxiosReq(true)

		if (mode === "daycare") {
			await Promise.all(
				pets.map(async (pet, index) => {
					const updatedImagesBordatella = await fileUploadHandler(
						index,
						[pet.bordatellaImgUrl],
						"bordatella"
					)

					const updatedImagesRegularShots = await fileUploadHandler(
						index,
						[pet.vaccineRecordImgUrl],
						"regularShots"
					)

					const foundPetBordatella = updatedImagesBordatella.find(
						(img) => img.type === "bordatellaImgUrl"
					)

					const foundPetRegularShots = updatedImagesRegularShots.find(
						(img) => img.type === "vaccineRecordImgUrl"
					)

					dataObj.pets[index] = {
						...pet,
						bordatellaImgUrl: foundPetBordatella
							? foundPetBordatella.url
							: pet.bordatellaImgUrl,
						vaccineRecordImgUrl: foundPetRegularShots
							? foundPetRegularShots.url
							: pet.vaccineRecordImgUrl,
						type: "dog",
					}
				})
			)
		}

		try {
			if (!clientID) {
				await axios
					.post(`/api/new_clients/${CITY}`, dataObj)
					.then(() => {
						setLoadingAxiosReq(false)
						window.location.href = "/client-dashboard"
					})
					.catch((err) => {
						if (err.response.status === 403) {
							alert(err.response.data.error)
						}
						setLoadingAxiosReq(false)
					})
			} else {
				await axios
					.put(`/api/clients/upload/${CITY}`, dataObj)
					.then(() => {
						setLoadingAxiosReq(false)
						window.location.href = "/client-dashboard"
					})
					.catch((err) => {
						console.log(err)
						setLoadingAxiosReq(false)
					})
			}
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="md:mt-24 mt-12">
			<h1
				className="text-main text-[24px] leading-[30px] md:text-[60px] md:leading-[70px] font-black text-center"
				id="servicesTitle"
			>
				<span className="capitalize">{mode}</span> <br />
				Registration
			</h1>
			<>
				<p className="text-xl md:text-3xl mt-4 text-center bg-yellow-200 p-2">
					Complete your details below{" "}
					<span className="capitalize">{`(${CITY})`}</span>
				</p>
				{mode === "daycare" && (
					<div className="text-center">
						<div className="mt-2">
							<CiWarning className="inline-block mr-2 text-yellow-600 text-2xl" />{" "}
							We require that your dogs attend a free trial session before you
							register
							<CiWarning className="inline-block ml-2 text-yellow-600 text-2xl" />{" "}
							<br />
							This, will ensure the daycare environment is the right fit for
							them.
							<span
								onClick={() => {
									setShowBookTrialDayModal(true)
								}}
								className="text-blue-500 ml-2 cursor-pointer"
							>
								Click here to Initiate free trial session booking
							</span>
						</div>
					</div>
				)}

				<hr />
				<div className="px-8 sm:w-2/3 w-full mx-auto">
					<div className="row ">
						<div className="col-md-12">
							<h3 className="my-2 text-xl md:text-3xl text-center">
								<i className="fas fa-user"></i> Owner Information
							</h3>
							<OwnerFormComponent
								redInputHandler={redInputHandler}
								isOwnerButtonReady={isOwnerButtonReady}
								setLastName={setLastName}
								setFirstName={setFirstName}
								setPrimaryPhoneNumber={setPrimaryPhoneNumber}
								setCellphone={setCellphone}
								setWorkPhone={setWorkPhone}
								setAddress={setAddress}
								setEmail={setEmail}
								setEmail2={setEmail2}
								lastName={lastName}
								firstName={firstName}
								primaryPhoneNumber={primaryPhoneNumber}
								cellphone={cellphone}
								workPhone={workPhone}
								address={address}
								email={email}
								email2={email2}
								emailError={emailError}
								agreeMarketing={isSubscribed}
								handleCheckboxMarketingChange={handleCheckboxMarketingChange}
							/>

							{mode === "daycare" ? (
								<ReleaseFormDaycare
									setAgree={setAgreeReleaseForm}
									handleCheckboxChange={handleCheckboxChange}
									isReleaseFormReady={isReleaseFormReady}
									isGroomingAndDaycare
								/>
							) : (
								<ReleaseFormGrooming
									setAgree={setAgreeReleaseForm}
									handleCheckboxChange={handleCheckboxChange}
									isReleaseFormReady={isReleaseFormReady}
								/>
							)}
						</div>

						<div className="col-md-12 mt-2 pet-list-container-edit-client">
							<h3 className="text-center">
								<i className="fas fa-paw"></i> Pets Information
							</h3>
							{petsList}
							<Button onClick={addPetForm} className=" w-full" color="info">
								<i className="fas fa-plus"></i> Add Additional{" "}
								{mode === "daycare" ? "Dogs" : "Pets"}{" "}
								<i className="fas fa-paw"></i>
							</Button>
						</div>
					</div>
					{loadingAxiosReq ? (
						<LoadPage />
					) : (
						<FloatingWindow
							isOwnerReady={isOwnerButtonReady}
							isPetReady={isPetButtonReady}
							isReleaseFormReady={isReleaseFormReady}
							saveAllForms={saveAllForms}
						/>
					)}
				</div>
			</>
		</div>
	)
}
