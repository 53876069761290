import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ModalToAddClients = (props) => {
	return (
		<Modal
			isOpen={props.isOpen}
			toggle={props.toggle}
			modalClassName="modal-add-clients"
			city={props.city}
		>
			<ModalHeader toggle={props.toggle}>
				Add Clients for <strong>{props.city}</strong>{" "}
				<i className="fas fa-user-plus"></i>
			</ModalHeader>
			<ModalBody>
				<div className="container">
					<h4>Option disabled</h4>
					<p>
						Clients MUST get registered themselves, as they need to read and
						agree with our terms and conditions.
					</p>
					<p>
						Please instruct all clients to go to{" "}
						<b>www.amazingpetgrooming.ca</b> and select Register from the menu.{" "}
						<br />
						This can be done using the computer in the reception or from their
						mobile devices.
					</p>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default ModalToAddClients;
