import ReleaseGroomingForm_Content from "../ClientRegistration/ReleaseGroomingForm_Content"

export default function Index() {
	return (
		<div className="container pt-24 mx-auto my-8">
			<section id="daycare-terms" className="mb-8">
				<h2 className="text-2xl font-bold mb-4">Daycare Terms</h2>
				<p className="mb-4">
					This agreement outlines the terms between Amazing Pet Grooming Daycare
					("the Business") and the dog owner or their representative ("the
					Owner") for the provision of day care services ("the Services") for
					the Owner's dog.
				</p>
				<p className="mb-4">
					The Owner affirms that their dog is free from all diseases and
					illnesses.
				</p>

				<p className="mb-4">
					The Business reserves the right to deny entry to any animal at its
					discretion. If the Business perceives the dog as unwell and/or
					contagious during the visit, the dog will be isolated, and immediate
					notification will be given to the Owner. The dog will remain isolated
					until collection arrangements are made.
				</p>
				<p className="mb-4">
					Any behavioral conditions or past observations affecting the dog's
					ability to integrate into a group setting must be communicated
					promptly to an Amazing Pet Grooming Daycare staff member during the
					dog's visit. These behaviors may include, but are not limited to,
					fear-aggression, social assertiveness, excessive barking, excessive
					mounting, aversion to specific breeds, and aversion to strangers. Such
					behavioral information aids in ensuring the safety of all dogs and
					staff members.
				</p>
				<p className="mb-4">
					The Business, at its discretion, may request full payment for the
					booking amount before check-in. Failure to make the payment may result
					in the cancellation of the booking.
				</p>
				<p className="mb-4">
					The Business reserves the right to adjust fees and charges without
					notice, except during an ongoing daycare session.
				</p>
				<p className="mb-4">
					The Owner agrees to pay all applicable fees and charges specified by
					the Business at the beginning of daycare and upon notice, either
					verbally or in writing during check-in. Late collection fees and
					overnight boarding fees (if such services are needed and the business
					needs to find a suitable facility that provides this service in town)
					may be charged if the Owner fails to collect the pet on time.
				</p>
				<p className="mb-4">
					The Owner accepts responsibility for all costs and charges related to
					special services requested by the Business and any veterinary expenses
					during the pet's stay. In case of serious illness and inability to
					contact the Owner, the veterinary surgeon's decision on treatment is
					final, with resulting fees borne by the Owner.
				</p>
				<p className="mb-4">
					The Owner assumes sole responsibility for their pet's actions while in
					the Business's care, indemnifying the Business from any liability. The
					Owner is also liable for any loss or damage to the Business property
					caused by their pet.
				</p>
				<p className="mb-4">
					All charges under this agreement must be settled before the pet leaves
					the Business premises unless the Owner holds an active Membership,
					subject to the terms outlined in the Membership terms and conditions.
					Pre-paid bulk visits (Passes) are available for approved dogs and
					expire 12 months after purchase, non-refundable and non-transferable.
					The Business reserves the right to deny Passes purchases. Charges will
					be processed through our online platform or another selected platform
					used by the Business.
				</p>
				<p className="mb-4">
					The Business, its employees, or agents are not responsible for Canine
					Influenza, injury, death, loss, or damage to any animal under its
					control.
				</p>
				<p className="mb-4">
					Attendance at our daycare implies acknowledgment and acceptance of the
					Amazing Pet Grooming Daycare terms and conditions, in addition to
					assessments and socialization criteria, subject to change without
					prior notice. By accepting these terms, the Owner certifies the
					accuracy of all information provided about their dog during
					registration.
				</p>
			</section>

			<section id="grooming-terms">
				<h2 className="text-2xl font-bold mb-4">Grooming Terms</h2>
				<ReleaseGroomingForm_Content
					isWithinDaycareRelease={false}
					includeVaccinePart={false}
				/>
			</section>

			<section id="membership-terms" className="mb-8">
				<h2 className="text-2xl font-bold mb-4">Membership Terms</h2>
				<p className="mb-4">
					By enrolling in a Membership, you agree to the following Terms and
					Conditions.
				</p>
				<p className="mb-4">
					<strong>Payment Schedule:</strong> The first Weekly Membership Fee is
					charged on the day of purchase, providing membership perks until
					midnight on the next Saturday. Subsequent charges occur every Saturday
					for goods or services received during the week, along with the Weekly
					Membership Fee.
				</p>
				<p className="mb-4">
					<strong>Payment Amounts:</strong> You will be informed of the Weekly
					Membership Fee, Additional Daycare Fee, and applicable discounts upon
					membership purchase. Prices may change at our discretion with a 14-day
					notice, giving you the option to end your membership penalty-free.
				</p>
				<p className="mb-4">
					<strong>Debit or Credit Card Authorization:</strong> By purchasing a
					membership, you authorize us to charge your debit or credit card
					through our financial institution via our online payment platform.
				</p>
				<p className="mb-4">
					<strong>Cancelling your membership:</strong> You may cancel after the
					minimum membership period (21 days for standard memberships) by
					providing written instructions via email. Cancelling before the
					minimum period incurs charges for the unused weeks.
				</p>
				<p className="mb-4">
					<strong>Your obligations:</strong> Ensure sufficient funds in your
					nominated account for timely payments. Insufficient funds may result
					in fees charged by your financial institution and additional costs
					incurred by us.
				</p>
				<p className="mb-4">
					<strong>Dispute:</strong> Notify us promptly of any debit errors. We
					will investigate and, if necessary, refund your account via the online
					payments platform.
				</p>
			</section>

			<section id="digital-social-media" className="mb-8">
				<h2 className="text-2xl font-bold mb-4">Digital & Social Media</h2>
				<p className="mb-4">
					All photographic images or digital recordings taken of the facility,
					dogs, and staff, along with their prints and copyrights, are the
					property of Amazing Pet Grooming Daycare. You acknowledge and agree
					that you relinquish any further rights to the material, including
					moral rights and copyright, and that the material becomes the sole
					ownership of Amazing Pet Grooming Daycare.
				</p>
				<p className="mb-4">
					If you wish to restrict the use of any media format, please notify us
					in writing with your name, your dog's name, and the date. Otherwise,
					we consider any media formats our right to use as we see fit.
				</p>
			</section>

			<section id="website" className="mb-8">
				<h2 className="text-2xl font-bold mb-4">Website</h2>
				<p className="mb-4">
					These Website Standard Terms and Conditions govern your use of the
					Amazing Pet Grooming Daycare website at amazingpetgrooming.ca By using
					this website, you accept all the terms and conditions outlined herein.
					Do not use this website if you disagree with any provisions of these
					Website Standard Terms and Conditions. Users under 18 years old are
					not permitted to use this website.
				</p>
				<p className="mb-4">
					<strong>Intellectual Property Rights:</strong> Amazing Pet Grooming
					and/or its licensors retain all intellectual property rights and
					materials on this website, granting you a limited license for viewing
					purposes.
				</p>
				<p className="mb-4">
					<strong>Restrictions:</strong> Prohibited activities include
					publishing website material elsewhere, selling or commercializing it,
					public performance, damaging use, violation of laws, and engaging in
					data mining or advertising.
				</p>
				<p className="mb-4">
					<strong>Your Content:</strong> "Your Content" refers to material you
					display on this website, granting Amazing Pet Grooming Daycare a
					license to use, reproduce, adapt, publish, translate, and distribute
					it. Your Content must not violate third-party rights.
				</p>
				<p className="mb-4">
					<strong>No Warranties:</strong> This website is provided "as is," with
					no representations or warranties from Amazing Pet Grooming Daycare.
				</p>
				<p className="mb-4">
					<strong>Limitation of Liability:</strong> Amazing Pet Grooming
					Daycare, its officers, directors, and employees are not liable for
					issues arising from your website use. Indirect liabilities are also
					excluded.
				</p>
			</section>
		</div>
	)
}
