import axios from "axios"
import React, { useState } from "react"
import Select from "react-select"
import { Link } from "react-router-dom"
import { saveAs } from "file-saver"
import { ThreeDots } from "react-loader-spinner"
import moment from "moment"

const cityOptions = [
	{ label: "Milton", value: "milton" },
	{ label: "Cambridge", value: "cambridge" },
]

export default function DaycarePassesList() {
	const [isLoading, setIsLoading] = useState(false)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [city, setCity] = useState("")
	const [daycarePasses, setDaycarePasses] = useState([])
	const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" })

	const getDaycarePassesList = async (e) => {
		e.preventDefault()
		const accessString = localStorage.getItem("JWT")

		if (!city) {
			return alert("Please select a city!")
		}
		if (!startDate || !endDate) {
			return alert("Please add the date range!")
		}
		if (new Date(endDate) <= new Date(startDate)) {
			return alert("End date cannot be earlier than start date")
		}

		const url =
			city === "milton"
				? "/api/daycare_passes"
				: "/api/cambridge/daycare_passes"
		setIsLoading(true)

		try {
			const response = await axios.get(`${url}/${startDate}/${endDate}`, {
				headers: { jwt: `${accessString}` },
			})
			setDaycarePasses(response.data)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.error("Error fetching Daycare Passes:", error)
			alert("An error occurred while fetching Daycare passes.")
		}
	}

	const sortData = (key) => {
		let direction = "asc"
		if (sortConfig.key === key && sortConfig.direction === "asc") {
			direction = "desc"
		}
		setSortConfig({ key, direction })

		const sortedDaycarePasses = [...daycarePasses].sort((a, b) => {
			if (a[key] < b[key]) {
				return direction === "asc" ? -1 : 1
			}
			if (a[key] > b[key]) {
				return direction === "asc" ? 1 : -1
			}
			return 0
		})
		setDaycarePasses(sortedDaycarePasses)
	}

	const getSortIcon = (key) => {
		if (sortConfig.key === key) {
			return sortConfig.direction === "asc" ? "▲" : "▼"
		}
		return ""
	}

	const convertToCSV = (data) => {
		const headers = [
			"Client ID",
			"Pass Name",
			"Type",
			"Purchase Source",
			"Value",
			"Balance",
			"Original # of Visits",
			"# of Visits Left",
			"Expiry Date",
			"Purchased Date",
		]

		const rows = data.map((pass) => [
			pass.ClientId,
			pass.name,
			pass.type,
			pass.purchaseSource,
			pass.value,
			pass.balance,
			pass.originalNumOfVisits,
			pass.numOfVisitsLeft,
			moment(pass.expireDate).format("YYYY-MM-DD"),
			moment(pass.createdDate).format("YYYY-MM-DD"),
		])

		const csvContent = [
			headers.join(","),
			...rows.map((row) => row.join(",")),
		].join("\n")

		return csvContent
	}
	const downloadCSV = () => {
		const csvContent = convertToCSV(daycarePasses)
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
		saveAs(blob, `daycarePasses_list_${city}.csv`)
	}

	return (
		<div className="mt-12 p-2">
			<h3 className="text-center">Daycare Purchased Passes</h3>
			<hr />
			<div className="grid grid-cols-1 md:grid-cols-4 gap-4">
				<div>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="startDate"
					>
						Start Date
					</label>
					<input
						value={startDate || ""}
						onChange={(e) => setStartDate(e.target.value)}
						id="startDate"
						type="date"
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
					/>
				</div>

				<div>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="endDate"
					>
						End Date
					</label>
					<input
						value={endDate || ""}
						onChange={(e) => setEndDate(e.target.value)}
						id="endDate"
						type="date"
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
					/>
				</div>

				<div>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="city"
					>
						Select City
					</label>
					<Select
						options={cityOptions}
						onChange={(value) => setCity(value.value)}
					/>
				</div>

				<div className="flex">
					<button
						onClick={getDaycarePassesList}
						className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
					>
						Generate List
					</button>
				</div>
			</div>
			<hr />

			{isLoading ? (
				<div className="flex justify-center mt-16">
					<ThreeDots
						height="18"
						width="60"
						radius="9"
						color="#65BFF8"
						ariaLabel="three-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			) : daycarePasses.length > 0 ? (
				<div className="mt-8 overflow-x-auto">
					<button
						onClick={downloadCSV}
						className="bg-slate-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
					>
						Download CSV
					</button>
					<table className="min-w-full bg-white border">
						<thead>
							<tr className="bg-gray-200 text-gray-700">
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("ClientId")}
								>
									Client # {getSortIcon("ClientId")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("name")}
								>
									Pass Name {getSortIcon("name")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("type")}
								>
									Type {getSortIcon("type")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("purchaseSource")}
								>
									Purchase Source {getSortIcon("purchaseSource")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("value")}
								>
									Orig. Value {getSortIcon("value")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("value")}
								>
									Discount Applied {getSortIcon("value")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("value")}
								>
									Client Paid {getSortIcon("value")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("balance")}
								>
									Balance {getSortIcon("balance")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("originalNumOfVisits")}
								>
									Orig # of Visits {getSortIcon("originalNumOfVisits")}
								</th>
								<th className="py-2 px-4 border"># Of visits left</th>
								<th className="py-2 px-4 border cursor-pointer">Expiry Date</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("createdDate")}
								>
									Purchased Date
								</th>
							</tr>
						</thead>
						<tbody>
							{daycarePasses.map((pass, index) => (
								<tr key={index} className="text-left">
									<td className="py-2 px-4 border">
										<Link
											target="__blank"
											to={`/clients/${city}/${pass.ClientId}`}
										>
											{pass.ClientId}
										</Link>
									</td>
									<td className="py-2 px-4 border">{pass.name}</td>
									<td className="py-2 px-4 border">{`${pass.type}`}</td>
									<td className="py-2 px-4 border">{pass.purchaseSource}</td>
									<td className="py-2 px-4 border">${pass.value}</td>
									<td className="py-2 px-4 border">
										{pass.discountName ? pass.discountName : "No discount"}
									</td>
									<td className="py-2 px-4 border">
										${pass.purchasedAt ? pass.purchasedAt : pass.value}
									</td>
									<td className="py-2 px-4 border">${pass.balance}</td>
									<td className="py-2 px-4 border">
										{pass.originalNumOfVisits}
									</td>
									<td className="py-2 px-4 border">{pass.numOfVisitsLeft}</td>
									<td className="py-2 px-4 border">
										{moment(pass.expireDate).format("YYYY-MM-DD")}
									</td>
									<td className="py-2 px-4 border">
										{moment(pass.createdDate).format("YYYY-MM-DD")}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="text-center">No results yet</div>
			)}
		</div>
	)
}
