import React, { useLayoutEffect, useRef } from "react";
import ImageInText from "./ImageInText";
import { ScrollTrigger } from "gsap/all";
import { Back, Power4, gsap } from "gsap";
import SplitType from "split-type";

const Divider = () => {
  const container = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);

      //Title split
      const splitTitleDividerMainParent = new SplitType("#dividerMainText", {
        types: "lines",
        lineClass: "lineParentDividerMain",
      });
      const splitTitle = new SplitType(".lineParentDividerMain", {
        types: "lines",
      });

      const tl = gsap.timeline({
        defaults: { ease: Power4.easeOut },
        scrollTrigger: {
          trigger: container.current,
          start: "top 80%",
        },
      });

      tl.fromTo(
        splitTitle.lines,
        { y: 100, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          stagger: 0.1,
        }
      );
      tl.fromTo(
        ["#dividerText", ".unorganizedList"],
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.4, stagger: 0 },
        "-=0.5"
      );
      tl.fromTo(
        ".crazyDog",
        {
          scale: 0.5,
          x: 200,
          y: 200,
          opacity: 0,
        },
        {
          scale: 1,
          x: 0,
          y: 0,
          opacity: 1,
          duration: 1,
          ease: Back.easeInOut.config(1),
        },
        "-=1.1"
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <section
      className="relative w-full overflow-hidden bg-white p-phone md:p-tablet lg:p-desktop trigger"
      ref={container}
    >
      <h1
        className="text-black font-black text-[45px] leading-[45px] md:text-[60px] md:leading-[60px] lg:text-[80px] lg:leading-[80px] mb-[50px]"
        id="dividerMainText"
      >
        PROFESSIONAL{" "}
        <ImageInText image="/images/dog-text.png" color="#8F00FF" />
        DOG <br /> AND CAT{" "}
        <ImageInText image="/images/cat-text.png" color="#FF9900 " />
        GROOMING.
      </h1>

      <p className="text-black text-[20px] mb-[20px]" id="dividerText">
        Amazing Pet Grooming counts with a team you can trust with over 20 years
        of experience.
      </p>
      <ul className="w-full flex flex-col gap-[5px] text-black unorganizedList">
        <li className="dividerListItem">
          🐾 Nothing to hide... Open concept area.
        </li>
        <li className="dividerListItem">🐾 Super friendly staff.</li>
        <li className="dividerListItem">🐾 Easy booking system.</li>
        <li className="dividerListItem">
          🐾 2 locations. (Milton & Cambridge).
        </li>
      </ul>
      <img
        src="/images/crazy-dog.png"
        className="absolute bottom-0 right-0 w-[30%] crazyDog"
      />
    </section>
  );
};

export default Divider;
