import React from "react";
import { optionsCatServices } from "../../../pages/Schedule/Schedule.options";
import BookingCard from "../BookingCard";

const CatServices = ({ functionCalled }) => {
  return (
    <>
      {/* Form question */}
      <h5 className="text-black font-black text-[30px] md:text-[45px]">
        Choose type of service:
      </h5>

      {/* Form choises */}
      <div className="w-full h-full grid grid-cols-2 gap-[30px]">
        {optionsCatServices.map((option, index) => {
          if (index === optionsCatServices.length - 1 && index % 2 === 0) {
            return (
              <div
                className="w-full h-full"
                style={{
                  gridColumn: "1 / span 2",
                }}
                key={option.value}
              >
                <BookingCard
                  title={option.value}
                  description={option.label}
                  image={option.image}
                  onClick={() => functionCalled(option)}
                />
              </div>
            );
          } else {
            return (
              <BookingCard
                title={option.value}
                description={option.label}
                image={option.image}
                onClick={() => functionCalled(option)}
                key={option.value}
                column
              />
            );
          }
        })}
      </div>
    </>
  );
};

export default CatServices;
