import React, { useContext } from "react"
import { IoMdCart } from "react-icons/io"
import { RegistrationContext } from "../../contexts/registrationStatusContext"

function FloatingCartIcon({ position }) {
	const { cart, setCartModal } = useContext(RegistrationContext)

	const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0)

	return (
		<div
			className={`${
				position === "fixed_custom"
					? "fixed top-4 right-36"
					: position + " top-4 right-1"
			} top-4 right-1 bg-white rounded-full w-14 h-14 flex items-center justify-center shadow-lg z-100 cursor-pointer hover:scale-110 transition-transform`}
			onClick={() => {
				setCartModal(true)
			}}
		>
			<IoMdCart size={30} className="text-gray-700" />
			{totalItems > 0 && (
				<span className="absolute top-1 right-1 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center shadow-md">
					{totalItems}
				</span>
			)}
		</div>
	)
}

export default FloatingCartIcon
