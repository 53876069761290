import React from "react";
import {
  AiOutlineGoogle,
  AiOutlineInstagram,
  AiOutlineFacebook,
} from "react-icons/ai";

const SocialMedia = ({ className }) => {
  return (
    <ul className={`flex gap-[5px] mb-0 ${className}`}>
      <li className="cursor-pointer hover:scale-105 duration-500">
        <a
          href="https://www.google.com/search?hl=es-419&gl=co&q=Amazing+Pet+Grooming,+724+Main+St+E,+Milton,+ON+L9T+3P6,+Canad%C3%A1&ludocid=11628696026132387141&lsig=AB86z5WWFiwaazhePIJ7jeahX2Hg#lrd=0x882b6f0649aa7edb:0xa1616d6c9e9e1545,3"
          target="_blank"
        >
          <AiOutlineGoogle size="20" color="#2A2A2A" />
        </a>
      </li>
      <li className="cursor-pointer hover:scale-105 duration-500">
        <a href="https://www.instagram.com/amazingpetgrooming/" target="_blank">
          <AiOutlineInstagram size="20" color="#2A2A2A" />
        </a>
      </li>
      <li className="cursor-pointer hover:scale-105 duration-500">
        <a
          href="https://www.facebook.com/amazingpetgroomingmilton/"
          target="_blank"
        >
          <AiOutlineFacebook size="20" color="#2A2A2A" />
        </a>
      </li>
    </ul>
  );
};

export default SocialMedia;
