import React, { useLayoutEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import LocationCard from "../../components/LocationCard";
import { locations } from "../../utils/constants";
import SocialMedia from "../../components/SocialMedia";
import { gsap, Power4, Back } from "gsap";
import SplitType from "split-type";

const index = () => {
  const container = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({ defaults: { ease: Power4.easeOut } });

      //Title split
      const splitTitleParent = new SplitType("#contactTitle", {
        types: "lines",
        lineClass: "lineParentContactTitle",
      });
      const splitTitle = new SplitType(".lineParentContactTitle", {
        types: "lines",
      });

      //email split

      tl.fromTo(
        splitTitle.lines,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          delay: 0.2,
          stagger: 0.2,
        }
      );
      tl.fromTo(
        ".contactInfo",
        {
          y: 10,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        },
        "-=0.5"
      );
      tl.fromTo(
        ".mapContact",
        {
          y: 10,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        },
        "-=0.5"
      );
      tl.fromTo(
        ".contactImg",
        {
          scale: 0.5,
          x: -200,
          opacity: 0,
        },
        {
          scale: 1,
          x: -10,
          opacity: 1,
          duration: 0.5,
          ease: Back.easeInOut.config(1),
        },
        "-=0.8"
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <section
      className="w-full h-full lg:h-[calc(100vh-90px)] p-phone md:px-tablet md:pt-tabler lg:px-desktop lg:pt-tablet flex flex-col gap-[50px] bg-white"
      ref={container}
    >
      <Helmet>
        <title>Contact Us | Amazing Grooming Pet</title>
        <meta
          name="description"
          content="Two locations to choose from, Cambridge and Milton."
        />
      </Helmet>
      <main className="w-full h-full flex flex-col gap-[50px] relative">
        <h4
          className="text-main pt-12 text-[38px] leading-[50px] md:text-[60px] md:leading-[70px] font-black text-center"
          id="contactTitle"
        >
          Contact us
        </h4>

        {/* Table */}
        <div className="w-full h-full">
          {/* Top table */}
          <div className="w-full h-[5%] p-phone rounded-t-default flex justify-between items-center border-[1px] border-black">
            <a
              href="mailto:info@amazingpetgrooming.ca "
              className="text-black hover:no-underline contactInfo"
            >
              info@amazingpetgrooming.ca
            </a>
            <SocialMedia className="contactInfo" />
          </div>

          {/* Bottom table */}
          <div className="w-full h-[90%] p-phone border-x-[1px] border-b-[1px] border-black rounded-b-default flex flex-col lg:flex-row gap-[50px] relative">
            {locations.map((location) => (
              <LocationCard
                location={location}
                key={location.address}
                className="mapContact"
              />
            ))}

            {/* Divider line */}
            <div className="absolute bg-black w-full h-[1px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:w-[1px] lg:h-full lg:top-0 lg:translate-y-0" />
          </div>
        </div>
      </main>
      <img
        className="absolute left-0 -bottom-[5%] lg:bottom-[5%] w-[30%] lg:w-[15%] pointer-events-none contactImg"
        src="/images/dog-headphones.png"
      />
    </section>
  );
};

export default index;
