import React, { useEffect, useState } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import { isAfter, startOfDay } from "date-fns"
import moment from "moment-timezone"
import moment_moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import eventStyleGetter from "../CalendarOptions/calendarStyles"
import optionsAdmin from "../CalendarOptions/servicePaolaOptions"
import options from "../CalendarOptions/serviceOptions"
import optionsNoSchedule from "../CalendarOptions/serviceOptionsNoSchedule"
import optionsTrainee from "../CalendarOptions/serviceOptionsTrainee"
import { optionsDaycare } from "../CalendarOptions/optionsDaycare"
import { ThreeDots } from "react-loader-spinner"
import axios from "axios"
import {
	adminCalStyle,
	adminCalCambridgeStyle,
	emp1CalStyle,
	emp2CalStyle,
	emp3CalStyle,
	emp4CalStyle,
	emp5CalStyle,
	emp6CalStyle,
	emp7CalStyle,
	daycareCalStyle,
} from "../CalendarOptions/mainCalendarStyles"
import "./style.scss"
import MainCalModalUpdateEvent from "../../Modals/MainCalUpdateEvent"
import MainCalModalAddEvent from "../../Modals/MainCalAddEvent"
import {
	EXPIRED_CODE_ERROR,
	INVALID_GROOMING_COUPON_ERROR,
} from "../../../utils/constants"

moment.tz.setDefault("America/Toronto")
moment.locale("en-CA")

const localizer = momentLocalizer(moment)
const jobType = localStorage.getItem("JOBTYPE")

const eventsNotAllowedToBeEmptyTitle = [
	"bathSmallDog",
	"bathMediumDog",
	"bathBigDog",
	"app",
	"mediumDog",
	"bigDog",
	"bathSmallDogRequest",
	"bathMediumDogRequest",
	"bathBigDogRequest",
	"appRequest",
	"mediumDogRequest",
	"bigDogRequest",
	"cat",
	"cancel",
]

const userGroomers = [
	// "groomer1",
	"groomer2", // Diana
	"groomer3",
	"groomer4",
	"groomer5",
	"groomer6",
]

export const synchronizeAndUpdateCals = async (slotEvent, operation) => {
	const formattedDate = moment(slotEvent.start).format("YYYY-MM-DD")

	// Get the DaycareSpot for the day to get the id
	const daycareSpotsForDate = await axios.get(
		`/schedule/daycare_avail_spots/${formattedDate}`
	)

	// Take the first item in the array
	const foundDaycareSpot = daycareSpotsForDate.data[0]
	const updateOperation =
		operation === "add"
			? foundDaycareSpot.numOfAvailSpots + 1
			: foundDaycareSpot.numOfAvailSpots - 1

	// Build the daycare obj to update the num of spots
	const daycareObj = {
		numOfAvailSpots: updateOperation,
	}

	// Update the Daycare Spot record
	await axios
		.put(`/schedule/daycare_space/update/${foundDaycareSpot.id}`, daycareObj)
		.then(async () => {
			// Get the Calendar Record that holds the Daycare Info (Num of spots)
			const calDaycareEvents = await axios.get(
				`/schedule/calendars/get/${formattedDate}`
			)
			// Take the first item in the array
			const foundCalEvent = calDaycareEvents.data[0]
			// Build the Calendar to update Obj
			const updatedDaycareCalObj = {
				id: foundCalEvent.id,
				title: `${updateOperation} Spots available for Daycare`,
			}
			// Update the Calendar event
			await axios.put(
				`/schedule/calendar/update/${foundCalEvent.id}`,
				updatedDaycareCalObj
			)
		})
}

const Index = (props) => {
	const afternoonThreshold = moment("13:29:00", "HH:mm:ss")
	const [isLoadingCalendarEvents, setIsLoadingCalendarEvents] = useState(true)
	const [cal_events, setCal_events] = useState([])
	const [appointmentModifiers, setAppointmentModifiers] = useState([])
	const [appointment, setAppointment] = useState("")
	const [slotEvent, setSlotEvent] = useState("")
	const [eventIdToDelete, setEventIdToDelete] = useState("")
	const [errorOnModalCalendar, setErrorOnModalCalendar] = useState("")
	const [eventHistoryToggle, setEventHistoryToggle] = useState(false)
	const [modalToAddEvent, setModalToAddEvent] = useState(false)
	const [modalToEditEvent, setModalToEditEvent] = useState(false)
	const [modalToShowEventsResults, setModalToShowEventsResults] =
		useState(false)
	const [availableSpotsDate, setAvailableSpotsDate] = useState(null)
	const [numOfSpots, setNumOfSpots] = useState(40)
	const [breedInputEmpty, setBreedInputEmpty] = useState(false)
	const [titleInputEmpty, setTitleInputEmpty] = useState(false)
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [title, setTitle] = useState("")
	const [breedName, setBreedName] = useState("")
	const [start, setStart] = useState("")
	const [end, setEnd] = useState("")
	const [selectedCoupon, setSelectedCoupon] = useState(null)
	const [coupons, setCoupons] = useState([])
	const [showCoupons, setShowCoupons] = useState(false)
	const [soonestAppointment, setSoonestAppointment] = useState(null)
	const [soonestAppointmentCatPaola, setSoonestAppointmentCatPaola] =
		useState(null)
	const [daycareSpotId, setDaycareSpotId] = useState("")
	const [discount, setDiscount] = useState(null)

	const [lastModifiedBy, setLastModifiedBy] = useState(
		localStorage.getItem("USERNAME")
	)
	const [isUserResctricted, setIsUserRestricted] = useState(false)

	const isUserLimitedToCambridgeCal = localStorage.getItem(
		"LIMITED_ACCESS_TO_CAMBRIDGE"
	)

	useEffect(() => {
		if (props.triggerFetchEvents) {
			reFetchEvents()
		}
	}, [props.triggerFetchEvents])

	useEffect(() => {
		getSoonestAppointment()
	}, [])

	useEffect(() => {
		setIsUserRestricted(isUserLimitedToCambridgeCal)
	}, [isUserLimitedToCambridgeCal])

	useEffect(() => {
		reFetchEvents()
		if (!modalToEditEvent) {
			setTitle("")
			setBreedName("")
			setAppointment("")
			setSlotEvent("")
			setErrorOnModalCalendar("")
			setDiscount(null)
			setSelectedCoupon(null)
			setShowCoupons(false)
		}
		// eslint-disable-next-line
	}, [modalToEditEvent])
	useEffect(() => {
		if (!modalToAddEvent) {
			setTitle("")
			setBreedName("")
			setAppointment("")
			setSlotEvent("")
			setErrorOnModalCalendar("")
		}
		// eslint-disable-next-line
	}, [modalToAddEvent])

	const reFetchEvents = async () => {
		let groomerUrl
		if (!props.isBather) {
			groomerUrl = axios.get(
				`/schedule/calendars_all/${props.city}/${props.groomer}`
			)
		} else {
			groomerUrl = axios.get(
				`/schedule/calendars_all/${props.city}/${props.groomer}/tristen`
			)
		}

		await groomerUrl.then((response) => {
			const filteredEvents = response.data.filter(
				(event) => !event.deletedAt && !event.isBeingBooked
			)
			let appointments = filteredEvents
			for (let i = 0; i < appointments.length; i++) {
				if (!appointments[i].allDay) {
					appointments[i].start = convertDate(appointments[i].start)
					appointments[i].end = convertDate(appointments[i].end)
				} else {
					appointments[i].start = startOfDay(
						moment.utc(appointments[i].start).toDate()
					)
					appointments[i].end = startOfDay(
						moment.utc(appointments[i].end).toDate()
					)
				}

				if (userGroomers.includes(jobType) && appointments[i].breedName) {
					appointments[i].title = appointments[i].breedName
				} else if (
					appointments[i].appointment !== "online" &&
					appointments[i].appointment !== "onlineCat" &&
					appointments[i].appointment !== "onlineBath"
				) {
					if (
						(appointments[i].appointment === "schedule" ||
							appointments[i].appointment === "staffOnly") &&
						!appointments[i].title &&
						!appointments[i].breedName
					) {
						appointments[i].title = ""
					} else {
						!appointments[i].title.includes("Spots available for Daycare")
							? (appointments[i].title = `${appointments[i].title} - ${
									appointments[i].appointment === "schedule"
										? "custom event"
										: appointments[i].breedName
							  }`)
							: `${appointments[i].title}`
					}
				}
			}

			setCal_events(appointments)
			setIsLoadingCalendarEvents(false)
		})
	}

	const convertDate = (date) => {
		return moment.tz(date, "America/Toronto").toDate()
	}

	const onSubmitModal = async (e) => {
		e.preventDefault()

		let obj = {
			title: title.toLowerCase(),
			breedName: breedName.toLowerCase(),
			start: slotEvent.start,
			end: slotEvent.end,
			appointment: appointment.value,
			lastModifiedBy: localStorage.getItem("USERNAME"),
			city: props.city,
			name: props.groomer,
		}
		if (props.appointmentDetailsObj) {
			obj.discountCode = props.appointmentDetailsObj.discountCode
		}

		if (props.groomer !== "daycare" && !obj.appointment) {
			return setErrorOnModalCalendar("Fill out all the required fields!")
		}

		if (
			appointment.value === "online" ||
			appointment.value === "onlineBath" ||
			appointment.value === "onlineCat" ||
			appointment.value === "staffOnly"
		) {
			if (title.length > 0 || breedName.length > 0) {
				if (title) {
					setTitleInputEmpty(true)
				} else if (breedName) {
					setBreedInputEmpty(true)
				}
				return alert(
					"Action is not Allowed! Online appointments must be totally empty!"
				)
			}
		}

		if (
			eventsNotAllowedToBeEmptyTitle.includes(appointment.value) &&
			(!title || title.replace(/\s/g, "").length === 0)
		) {
			return alert("This event's description cannot be empty")
		}

		if (
			eventsNotAllowedToBeEmptyTitle.includes(appointment.value) &&
			((userGroomers.includes(jobType) && !breedName) ||
				breedName.replace(/\s/g, "").length === 0)
		) {
			setBreedInputEmpty(true)
			return alert("Breed and Name cannot be empty")
		}

		if (props.groomer === "daycare") {
			obj.appointment = "daycare"
		}

		setLoadingAxiosReq(true)

		try {
			await axios.post(`/schedule/calendars_all/add`, obj).then(async () => {
				if (eventIdToDelete) {
					await handleSoftDeleteEvent(eventIdToDelete)
				}

				if (props.appointmentDetailsObj) {
					const appointmentId = props.appointmentDetailsObj.id
					await handleSoftDeleteEvent(appointmentId)
				}
				if (obj.appointment === "daycare") {
					await synchronizeAndUpdateCals(slotEvent, "subtract")
				}

				setSlotEvent("")
				setTitle("")
				setBreedName("")
				setAppointment("")
				setLastModifiedBy("")
				reFetchEvents()
				setModalToAddEvent(false)
				setModalToEditEvent(false)
				setModalToShowEventsResults(false)
				setLoadingAxiosReq(false)
				if (props.appointmentDetailsObj) {
					window.location.reload()
				}
			})
		} catch (error) {
			console.log(error)
			setErrorOnModalCalendar(`${error.response.data.error}`)
			setLoadingAxiosReq(false)
		}
	}

	const validateCodeHandler = async (code) => {
		try {
			const response = await axios.get(`/api/discount/${code}`)
			const { pass, msg, type, value, status, name, serviceType, expireDate } =
				response.data

			// Convert expireDate into a moment object
			const convertedDate = moment(expireDate, "YYYY-MM-DD")

			// Check if the coupon's expireDate is after the current date
			const isDateOnCouponValid = convertedDate.isAfter(moment())

			// Handle the logic based on the conditions
			if (!response) {
				return setDiscount({ name: "" })
			}

			if (serviceType === "daycare") {
				return setDiscount({ name: INVALID_GROOMING_COUPON_ERROR })
			}

			if (!isDateOnCouponValid) {
				return setDiscount({ name: EXPIRED_CODE_ERROR }) // Coupon is expired if date is not valid
			}

			if (pass && status === "active") {
				setDiscount({
					name,
					type,
					value,
					code,
				})
			} else {
				setDiscount({ name: `Client used an ${msg}` })
			}
		} catch (error) {
			console.error("Error validating code:", error)
		}
	}

	const handleEventGetter = async (event) => {
		const formattedDate = moment(event.start).format("YYYY-MM-DD")
		const daycareSpotsForDate = await axios.get(
			`/schedule/daycare_avail_spots/${formattedDate}`
		)
		if (daycareSpotsForDate.data.length) {
			setDaycareSpotId(daycareSpotsForDate.data[0].id)
			setNumOfSpots(daycareSpotsForDate.data[0].numOfAvailSpots)
		}

		if (props.isFromEditClients) {
			if (
				event.appointment !== "online" &&
				event.appointment !== "onlineCat" &&
				event.appointment !== "staffOnly" &&
				event.appointment !== "daycare"
			) {
				return
			}
			setSlotEvent({
				start: convertDate(event.start),
				end: convertDate(event.end),
			})
			setEventIdToDelete(event.id)
			setModalToAddEvent(!modalToAddEvent)
		} else {
			toggleModalToEdit()
		}
		await axios
			.get(`/schedule/calendar/one/${event.id}`)
			.then(async (res) => {
				if (res.data.discountCode) {
					await validateCodeHandler(res.data.discountCode)
				}
				if (userGroomers.includes(jobType)) {
					if (res.data.breedName) {
						setTitle(res.data.breedName)
					}
					setTitle(res.data.title)
				} else {
					setTitle(res.data.title)
				}

				setSlotEvent(res.data)
				setAppointment({
					value: res.data.appointment,
					label: res.data.appointment,
				})
				setBreedName(res.data.breedName)
				setStart(res.data.start)
				setEnd(res.data.end)
				setLastModifiedBy(res.data.lastModifiedBy)
				setAppointmentModifiers(res.data.AppointmentModifiers)
			})
			.catch((error) => console.log(error))
	}

	const handleSoftDeleteEvent = async (eventId) => {
		const clientDeletedBy = {
			deletedAt: moment(new Date()).format("YYYY/MM/DD hh:mm"),
			deletedBy: localStorage.getItem("USERNAME"),
			isBeingBooked: false,
		}
		await axios
			.put(`/schedule/event/soft_delete/${eventId}`, clientDeletedBy)
			.then(async () => {
				reFetchEvents()
				setModalToEditEvent(false)
				setLoadingAxiosReq(false)
				setAppointment("")
			})
			.catch((error) => {
				console.log(error)
				setLoadingAxiosReq(false)
			})
	}

	const handleDeleteEvent = async (id, slotEvent) => {
		if (window.confirm(`Are you sure you wish to complete this operation?`)) {
			setLoadingAxiosReq(true)

			if (title && props.groomer !== "daycare") {
				handleSoftDeleteEvent(id)
			} else {
				// console.log("To delete permanently");
				await axios
					.delete(`/schedule/calendar/delete/${id}`)
					.then(async () => {
						if (props.groomer === "daycare") {
							await synchronizeAndUpdateCals(slotEvent, "add")
						}
						reFetchEvents()
						setModalToEditEvent(false)
						setLoadingAxiosReq(false)
					})
					.catch((error) => {
						console.log(error)
						setLoadingAxiosReq(false)
					})
			}
		}
	}

	const onSubmitModalToEdit = async (e) => {
		e.preventDefault()

		let obj = {
			id: slotEvent.id,
			title: title
				? title.toLowerCase()
				: appointment.value === "schedule" || appointment.value === "staffOnly"
				? ""
				: null,
			breedName: breedName
				? breedName.toLowerCase()
				: appointment.value === "schedule" || appointment.value === "staffOnly"
				? ""
				: null,
			start: start,
			end: end,
			appointment: appointment.value,
			lastModifiedBy: localStorage.getItem("USERNAME"),
			isBeingBooked: false,
		}
		if (selectedCoupon) {
			obj.discountCode = selectedCoupon.code
		}
		if (props.appointmentDetailsObj) {
			obj.discountCode = props.appointmentDetailsObj.discountCode
		}
		if (
			appointment === "online" ||
			appointment === "onlineBath" ||
			appointment === "onlineCat" ||
			appointment === "staffOnly" ||
			appointment.value === "online" ||
			appointment.value === "onlineBath" ||
			appointment.value === "onlineCat" ||
			appointment.value === "staffOnly"
		) {
			//ONLINE title and breed-name events must be empty
			if (title || breedName) {
				if (title.length > 0 || breedName.length > 0) {
					if (title) {
						setTitleInputEmpty(true)
					}
					if (breedName) {
						setBreedInputEmpty(true)
					}
					return alert(
						"Action is not Allowed! Online appointments must be totally empty!"
					)
				}
			}
		} else if (appointment !== "schedule") {
			if (
				(typeof appointment === "object" && appointment.value !== "schedule") ||
				appointment !== "schedule" ||
				appointment !== "staffOnly" ||
				appointment.value !== "staffOnly"
			) {
				if (!title || !breedName) {
					if (!title) {
						setTitleInputEmpty(true)
					}
					if (!breedName) {
						setBreedInputEmpty(true)
					}
					return alert("Missing required fields!")
				}
			}
		}
		setLoadingAxiosReq(true)
		await axios
			.put(`/schedule/calendar/update/${slotEvent.id}`, obj)
			.then(async () => {
				if (eventIdToDelete) {
					await axios
						.delete(`/schedule/calendar/delete/${eventIdToDelete}`)
						.then(() => {
							reFetchEvents()
							setModalToEditEvent(false)
							if (props.appointmentDetailsObj) {
								window.location.reload()
							}
						})
						.catch((error) => {
							console.log(error)
							setLoadingAxiosReq(false)
						})
				}
				reFetchEvents()
				setModalToEditEvent(false)
				setLoadingAxiosReq(false)
				setAppointment("")
				setTitle("")
				setBreedName("")
				setErrorOnModalCalendar("")
			})
			.catch((error) => {
				setErrorOnModalCalendar(`${error.response.data.error}`)
				setLoadingAxiosReq(false)
			})
	}

	const toggleModalToEdit = async () => {
		if (!modalToEditEvent) {
			onCloseModal()
		}
		setModalToEditEvent(!modalToEditEvent)
	}

	const onCloseModal = () => {
		setTitle("")
		setBreedName("")
		setAppointment("")
		setSlotEvent("")
		setErrorOnModalCalendar("")
		setEventHistoryToggle(false)
		setBreedInputEmpty(false)
		setTitleInputEmpty(false)
	}

	const toggleHistoryHandler = (e) => {
		e.preventDefault()
		if (!appointmentModifiers.length) {
			return
		}
		return setEventHistoryToggle(!eventHistoryToggle)
	}

	const getSoonestAppointment = async () => {
		if (props.groomer && props.groomer.toLowerCase() === "paola") {
			await axios
				.get(
					`/schedule/calendar/closest_appointment/${props.city.toLowerCase()}/dog/paola`
				)
				.then(async (res) => setSoonestAppointment(res.data))
				.catch((err) => console.log(err))
			await axios
				.get(
					`/schedule/calendar/closest_appointment/${props.city.toLowerCase()}/cat/paola`
				)
				.then((res2) => setSoonestAppointmentCatPaola(res2.data))
				.catch((err) => console.log(err))
		} else {
			await axios
				.get(
					`/schedule/calendar/closest_appointment/${props.city.toLowerCase()}/dog/${
						props.groomer
					}`
				)
				.then((res) => setSoonestAppointment(res.data))
				.catch((err) => console.log(err))
		}
	}

	const appointmentObj = soonestAppointment && (
		<span className="p-1 m-auto text-sm text-center">
			&#128054; First available appointment: <br />
			<b>
				{moment(soonestAppointment.start).format("MMMM Do YYYY")} <br />
				{moment(soonestAppointment.start).format("h:mm a")}
			</b>
		</span>
	)
	const appointmentObjCatPaola = soonestAppointmentCatPaola && (
		<span className="p-1 m-auto text-sm text-center">
			&#128049; First available appointment: <br />
			<b>
				{moment(soonestAppointmentCatPaola.start).format("MMMM Do YYYY")} <br />
				{moment(soonestAppointmentCatPaola.start).format("h:mm a")}
			</b>
		</span>
	)

	const addDaycareHandler = async (e) => {
		e.preventDefault()
		if (numOfSpots < 2) {
			return alert("You cannot add less than 2 spots")
		}

		const daycareObj = {
			numOfAvailSpots: numOfSpots,
			date: availableSpotsDate.start,
		}
		const groomingObj = {
			title: `${numOfSpots} Spots available for Daycare`,
			start: startOfDay(moment.utc(availableSpotsDate.start).toDate()),
			end: startOfDay(moment.utc(availableSpotsDate.end).toDate()),
			appointment: "daycare",
			lastModifiedBy: localStorage.getItem("USERNAME"),
			city: props.city,
			name: props.groomer,
			fullDay: true,
		}
		await axios
			.post("/schedule/daycare_spot/add", daycareObj)
			.then(async () => {
				try {
					await axios
						.post(`/schedule/calendars_all/add`, groomingObj)
						.then(async () => {
							reFetchEvents()
							setModalToAddEvent(false)
							setLoadingAxiosReq(false)
						})
				} catch (error) {
					console.log(error)
					setLoadingAxiosReq(false)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const selectSlotHandler = async (slot) => {
		const formattedDate = moment(slot.start).format("YYYY-MM-DD")

		const response = await axios.get(
			`/schedule/daycare_avail_spots/${formattedDate}`
		)
		if (
			response.data.length &&
			response.data[0].numOfAvailSpots < 1 &&
			props.groomer === "daycare"
		) {
			return alert(
				"No more avilable spots available for this day! Add more spots or choose another day."
			)
		}
		setSlotEvent({
			start: convertDate(slot.start),
			end: convertDate(slot.end),
		})

		if (props.groomer === "daycare") {
			try {
				if (response.data && response.data.length < 1) {
					setAvailableSpotsDate(slot)
				} else {
					setAvailableSpotsDate(null)
				}
			} catch (error) {
				console.error("Error checking/existing date:", error)
			}
		}

		setModalToAddEvent(!modalToAddEvent)
	}

	const updateDaycareHandler = async (e, daycareId, calEventId) => {
		e.preventDefault()

		const daycareObj = {
			numOfAvailSpots: numOfSpots,
		}
		if (numOfSpots < 0) {
			return alert("You cannot add less than 0 spots!")
		}
		await axios
			.put(`/schedule/daycare_space/update/${daycareId}`, daycareObj)
			.then(async () => {
				const returnedDaycareData = await axios.get(
					`/schedule/daycare/getById/${daycareId}`
				)
				const calEventObj = {
					title: `${returnedDaycareData.data[0].numOfAvailSpots} Spots available for Daycare`,
					id: calEventId,
				}
				await axios.put(`/schedule/calendar/update/${calEventId}`, calEventObj)
				reFetchEvents()
				setNumOfSpots("")
				setModalToEditEvent(false)
			})
			.catch((err) => console.log(err))
	}

	const getCalendarStyle = () => {
		const { groomer, city, isBather } = props

		if (groomer === "paola" && city === "milton") {
			return adminCalStyle
		} else if (groomer === "paola" && city === "cambridge") {
			return adminCalCambridgeStyle
		} else if (groomer === "claudia") {
			return emp1CalStyle
		} else if (groomer === "diana") {
			return emp2CalStyle
		} else if (groomer === "frank") {
			return emp3CalStyle
		} else if (isBather) {
			return emp5CalStyle
		} else if (groomer === "adriana") {
			return emp4CalStyle
		} else if (groomer === "peppa") {
			return emp6CalStyle
		} else if (groomer === "mercedes") {
			return emp7CalStyle
		} else if (groomer === "daycare") {
			return daycareCalStyle
		} else {
			return emp5CalStyle // Default style
		}
	}

	const calendarProps = {
		style: getCalendarStyle(),
		events: cal_events,
		onSelectSlot: (slot) => selectSlotHandler(slot),
		step: props.step,
		selectable: true,
		eventPropGetter: eventStyleGetter,
		timeslots: props.timeSlots,
		defaultView: "day",
		views:
			props.groomer === "daycare"
				? ["month", "day", "agenda"]
				: ["month", "day"],
		localizer: localizer,
		min: props.min,
		max: props.max,
		onSelectEvent: handleEventGetter,
	}

	if (!props.standardControls) {
		calendarProps.date = props.date ? new Date(props.date) : new Date()
		calendarProps.onNavigate = props.onNavigate
	}

	return (
		<div className="container">
			{jobType === "groomer2" && <div className="mt-4">Staff's profile</div>}

			<div className="row">
				{isLoadingCalendarEvents ? (
					<div
						style={
							props.groomer === "daycare"
								? { height: `900px` }
								: props.customHeight
								? { height: `${props.customHeight}px` }
								: { height: `800px` }
						}
						className={`col-md-12 calendars-wrapper border`}
					>
						<div className="flex justify-center mt-16">
							<ThreeDots
								height="18"
								width="60"
								radius="9"
								color="#65BFF8"
								ariaLabel="three-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					</div>
				) : (
					<div
						style={
							props.groomer === "daycare"
								? { height: `900px` }
								: props.customHeight
								? { height: `${props.customHeight}px` }
								: { height: `800px` }
						}
						className={`col-md-12 calendars-wrapper border`}
					>
						<div className="flex flex-col items-center justify-between xl:flex-row">
							<h3 className="pt-2 text-center">
								<span className="mr-2 capitalize">
									{props.isBather ? "BATHS" : props.groomer}
								</span>
								{props.city === "cambridge" ? "Cambridge" : "Milton"}
							</h3>
							<div className="flex text-center justify-evenly">
								<div className="inline-block m-1 border border-blue-950">
									{appointmentObj}
								</div>
								<div className="inline-block m-1 border border-blue-950">
									{appointmentObjCatPaola}
								</div>
							</div>
						</div>

						{isUserResctricted === true ||
						(isUserResctricted === "true" &&
							props.groomer === "paola" &&
							props.city === "milton") ? (
							<h3 className="p-3 mt-5 text-center rounded bg-secondary text-light">
								To book on Paola's Calendar, please call Cambridge @ 647 368
								7220
							</h3>
						) : (
							<Calendar {...calendarProps} />
						)}
					</div>
				)}
			</div>

			{/* Modal To add Event to a specific Calendar */}
			<MainCalModalAddEvent
				appointmentDetailsObj={props.appointmentDetailsObj}
				modalToAddEvent={modalToAddEvent}
				setModalToAddEvent={setModalToAddEvent}
				props={props}
				onSubmitModal={onSubmitModal}
				appointment={appointment}
				setAppointment={setAppointment}
				optionsAdmin={optionsAdmin}
				optionsTrainee={optionsTrainee}
				optionsDaycare={optionsDaycare}
				options={options}
				slotEvent={slotEvent}
				userGroomers={userGroomers}
				setTitle={setTitle}
				title={title}
				titleInputEmpty={titleInputEmpty}
				setTitleInputEmpty={setTitleInputEmpty}
				setBreedName={setBreedName}
				breedName={breedName}
				breedInputEmpty={breedInputEmpty}
				setBreedInputEmpty={setBreedInputEmpty}
				errorOnModalCalendar={errorOnModalCalendar}
				availableSpotsDate={availableSpotsDate}
				numOfSpots={numOfSpots}
				setNumOfSpots={setNumOfSpots}
				addDaycareHandler={addDaycareHandler}
				extraDetails={props.extraDetails}
			/>
			{/* Modal to add event to calendar ends here */}

			{/* Modal To Edit Events */}
			<MainCalModalUpdateEvent
				daycareSpotId={daycareSpotId}
				modalToEditEvent={modalToEditEvent}
				toggleModalToEdit={toggleModalToEdit}
				props={props}
				onSubmitModalToEdit={onSubmitModalToEdit}
				appointment={appointment}
				setAppointment={setAppointment}
				optionsAdmin={optionsAdmin}
				optionsTrainee={optionsTrainee}
				optionsNoSchedule={optionsNoSchedule}
				options={options}
				slotEvent={slotEvent}
				userGroomers={userGroomers}
				jobType={jobType}
				setTitle={setTitle}
				titleInputEmpty={titleInputEmpty}
				setTitleInputEmpty={setTitleInputEmpty}
				setBreedName={setBreedName}
				breedInputEmpty={breedInputEmpty}
				setBreedInputEmpty={setBreedInputEmpty}
				lastModifiedBy={lastModifiedBy}
				toggleHistoryHandler={toggleHistoryHandler}
				eventHistoryToggle={eventHistoryToggle}
				appointmentModifiers={appointmentModifiers}
				loadingAxiosReq={loadingAxiosReq}
				handleDeleteEvent={handleDeleteEvent}
				start={start}
				isDaycare={props.groomer === "daycare"}
				numOfSpots={numOfSpots}
				setNumOfSpots={setNumOfSpots}
				updateDaycareHandler={updateDaycareHandler}
				extraDetails={props.extraDetails}
				errorOnModalCalendar={errorOnModalCalendar}
				discount={discount}
				selectedCoupon={selectedCoupon}
				setSelectedCoupon={setSelectedCoupon}
				coupons={coupons}
				setCoupons={setCoupons}
				showCoupons={showCoupons}
				setShowCoupons={setShowCoupons}
			/>
			{/* Modal to edit events ends here */}
		</div>
	)
}

export default Index
