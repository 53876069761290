import { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const TeamMemberCard = ({ member }) => {
	const [isModalOpen, setModalOpen] = useState(false)
	const truncatedDescription =
		member.description.length > 100
			? `${member.description.slice(0, 180)}...`
			: member.description

	return (
		<div
			className={`w-full flex flex-col md:flex-row gap-[50px] md:items-stretch md:justify-between`}
		>
			<div
				className={`w-full rounded-md border-2 border-lightBlue  p-4 flex text-main flex-col justify-between`}
			>
				<div className="relative flex flex-col h-full">
					<div
						className="h-[260px] lg:h-[180px] rounded-md mb-4 flex justify-center items-end relative"
						style={{
							backgroundImage: `url(/images/about_bg.png)`,
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}
					>
						<img
							className="w-full sm:w-3/5 md:w-2/3 lg:w-1/2 object-cover h-[260px] lg:h-[180px]"
							src={member.image}
							alt="member image"
						/>
					</div>

					<div className="flex-1">
						<h5>{member.name}</h5>
						<p
							className="max-h-[300px] text-justify"
							dangerouslySetInnerHTML={{ __html: truncatedDescription }}
						></p>
						{member.description.length > 100 && (
							<button
								onClick={() => setModalOpen(true)}
								className="text-slate-400 underline mt-2"
							>
								View More
							</button>
						)}
					</div>
				</div>
			</div>

			<Modal
				style={{ maxHeight: "90vh", overflowY: "auto" }}
				modalClassName="mt-14"
				isOpen={isModalOpen}
				toggle={() => setModalOpen(!isModalOpen)}
			>
				<ModalHeader toggle={() => setModalOpen(!isModalOpen)}>
					<span className="text-main">{member.name}</span>
				</ModalHeader>
				<ModalBody>
					<div
						className="rounded-md mb-4 flex justify-end items-end"
						style={{
							backgroundImage: `url(/images/about_bg.png)`,
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}
					>
						<img
							className="w-1/2 mx-auto"
							src={member.image}
							alt="member image"
							style={{ objectFit: "contain" }}
						/>
					</div>
					<p
						className="text-main text-justify"
						dangerouslySetInnerHTML={{ __html: member.description }}
					></p>
				</ModalBody>
			</Modal>
		</div>
	)
}

export default TeamMemberCard
