import React, { useState } from "react"
import { ToastContainer, toast } from "react-toastify"

const ProductCard = ({ product, onAddToCart }) => {
	const [quantity, setQuantity] = useState(1)

	const notify = (msgContent) => toast.success(`${msgContent}`)

	const handleQuantityChange = (newQuantity) => {
		if (newQuantity <= 0) return
		setQuantity(newQuantity)
	}

	const handleAddToCart = () => {
		onAddToCart(product, quantity)
		notify(`${product.name} was added to cart!`)
		setTimeout(() => {
			setQuantity(1)
		}, 1000)
	}

	return (
		<div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
			<ToastContainer
				position="top-left"
				autoClose={2000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<img
				src={product.imgUrl}
				alt={product.name}
				className="w-full shadow-lg object-cover mb-4"
			/>
			<h2 className="text-lg font-bold">{product.name}</h2>
			<p className="text-gray-600">{product.description}</p>
			<p className="text-gray-900 font-semibold mt-2">
				${Number(product.price).toFixed(2)}
			</p>
			<div className="mt-4 flex items-center">
				<input
					type="number"
					min="1"
					value={quantity}
					onChange={(e) => handleQuantityChange(Number(e.target.value))}
					className="w-16 border rounded-md px-2 py-1 mr-2"
				/>
				<button
					onClick={handleAddToCart}
					className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
				>
					Add to Cart
				</button>
			</div>
		</div>
	)
}

export default ProductCard
