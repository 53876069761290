import React from "react";
import PetType from "../PetType";

const PetTypeScreen = ({ onClickDog, onClickCat }) => {
  return (
    <>
      <h5 className="text-black font-black text-[30px] md:text-[45px]">
        Choose your pet type:
      </h5>

      {/* Form choises */}
      <div className="w-full h-full md:h-1/2 grid grid-cols-1 md:grid-cols-2 gap-[50px]">
        <PetType
          petType={"Dog"}
          image={"/images/wet-dog.png"}
          color="#65BFF8"
          onClick={onClickDog}
        />
        <PetType
          petType={"Cat"}
          image={"/images/wet-cat.png"}
          color="#FF9900"
          onClick={onClickCat}
        />
      </div>
    </>
  );
};

export default PetTypeScreen;
