export const adminCalStyle = {
	height: "630px",
	marginBottom: "200px",
	background: "white",
	paddingTop: "15px",
	border: "1px solid #D25299",
}

export const adminCalCambridgeStyle = {
	height: "630px",
	marginBottom: "200px",
	background: "rgb(235, 232, 250)",
	paddingTop: "15px",
	border: "1px solid #D25299",
}

export const emp1CalStyle = {
	height: "630px",
	marginBottom: "200px",
	background: "#cce5ff",
	paddingTop: "15px",
	border: "1px solid #D25299",
}

export const emp2CalStyle = {
	height: "630px",
	marginBottom: "200px",
	background: "#ffe6ff",
	paddingTop: "15px",
	border: "1px solid #D25299",
}
export const emp3CalStyle = {
	height: "630px",
	marginBottom: "300px",
	background: "rgb(243, 245, 176)",
	paddingTop: "15px",
	border: "1px solid rgb(236, 252, 45)",
}
//Adriana
export const emp4CalStyle = {
	height: "630px",
	marginBottom: "300px",
	background: "rgb(219, 118, 122)",
	paddingTop: "15px",
	border: "1px solid rgb(193, 18, 84)",
}
//BATHER
export const emp5CalStyle = {
	height: "630px",
	marginBottom: "300px",
	background: "rgb(211, 245, 223)",
	paddingTop: "15px",
	border: "1px solid rgb(30, 77, 46)",
}
//Peppa
export const emp6CalStyle = {
	height: "630px",
	marginBottom: "300px",
	background: "rgb(255, 245, 223)",
	paddingTop: "15px",
	border: "1px solid rgb(100, 77, 46)",
}
//Mercedes
export const emp7CalStyle = {
	height: "630px",
	marginBottom: "300px",
	background: "rgb(247, 239, 216)",
	paddingTop: "15px",
	border: "1px solid rgb(100, 77, 46)",
}

// Daycare
export const daycareCalStyle = {
	height: "840px",
	marginBottom: "300px",
	background: "#d7f9fc",
	paddingTop: "15px",
	border: "1px solid rgb(100, 77, 46)",
}
