import React, { useRef, useState, useEffect, useLayoutEffect } from "react"
import { Helmet } from "react-helmet"
import { gsap, Power4 } from "gsap"
import SplitType from "split-type"

const index = () => {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
	const [zoomLevel, setZoomLevel] = useState(1)
	const [isHovering, setIsHovering] = useState(false)

	const grid = useRef(null)
	const tl = useRef(null)
	useEffect(() => {
		tl.current = gsap.timeline({ repeat: -1 })
		tl.current.to(grid.current, {
			backgroundPosition: "0px 0px",
			duration: 30,
			ease: "linear",
		})
	}, [isHovering])

	const handleMouseMove = (event) => {
		const { left, top, width, height } = event.target.getBoundingClientRect()
		const x = (event.clientX - left) / width
		const y = (event.clientY - top) / height
		setMousePosition({ x, y })
	}
	const handleMouseUp = () => {
		setZoomLevel(1)
		tl.current.play()
	}
	const handleMouseDown = () => {
		setZoomLevel(1.5)
		tl.current.pause()
	}

	const container = useRef(null)

	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline({ defaults: { ease: Power4.easeOut } })

			//Title split
			const splitTitleParent = new SplitType("#galleryTitle", {
				types: "lines",
				lineClass: "lineParentGalleryTitle",
			})
			const splitTitle = new SplitType(".lineParentGalleryTitle", {
				types: "lines",
			})

			tl.fromTo(
				splitTitle.lines,
				{
					y: 100,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					delay: 0.2,
					stagger: 0.2,
				}
			)
			tl.fromTo(
				".galleryContainer",
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
				},
				"-=0.5"
			)
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<div className="w-full h-[calc(100vh-90px)] md:pt-tabler lg:pt-tablet flex flex-col gap-[50px]">
			<Helmet>
				<title>Gallery | Amazing Grooming Pet</title>
			</Helmet>
			<main
				className="w-full h-full flex flex-col gap-[20px] relative"
				ref={container}
			>
				<h4
					className="text-main pt-14 text-[40px] leading-[50px] md:text-[70px] md:leading-[70px] font-black text-center"
					id="galleryTitle"
				>
					Gallery
				</h4>
				<div className="w-full h-full overflow-hidden relative cursor-zoom-in galleryContainer">
					<div
						className="w-full h-full max-h-[500px] infinite-grid-gallery relative"
						style={{
							backgroundImage: "url(/images/grid-gallery.png)",
							backgroundSize:
								"calc(4.84 * min(100vh, 500px)) min(100vh, 500px)",
							backgroundRepeat: "repeat-x",
							backgroundPosition: "left calc(4.84 * min(100vh, 500px)) top 0px",
							willChange: "background-position",
							transform: `scale(${zoomLevel})`,
							transformOrigin: `${mousePosition.x * 100}% ${
								mousePosition.y * 100
							}%`,
						}}
						onMouseDown={handleMouseDown}
						onMouseUp={handleMouseUp}
						onMouseMove={handleMouseMove}
						onMouseEnter={() => {
							tl.current.duration(80)
						}}
						onMouseLeave={() => {
							tl.current.duration(30)
						}}
						ref={grid}
					>
						<div
							className="w-full h-[10%] absolute left-0 bottom-0 pointer-events-none"
							style={{
								background:
									"linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
							}}
						/>
					</div>
				</div>
			</main>
		</div>
	)
}

export default index
