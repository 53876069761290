import { useEffect, useState } from "react"
import moment from "moment"
import axios from "axios"
import { synchronizeAndUpdateCals } from "../Calendars/MainCalendar"

export default function index({
	clientPhone1,
	clientPhone2,
	clientPhone3,
	clientEmail,
}) {
	const [data, setData] = useState([])

	useEffect(() => {
		getAppointments()
	}, [])

	const cancelAppointmentHandler = async (
		e,
		pet,
		appointmentStart,
		appointmentEnd,
		id,
		groomer,
		city
	) => {
		if (
			window.confirm(
				"Are you sure you want to cancel your appointment? This action cannot be reversed."
			)
		) {
			try {
				// Optimistically remove the appointment from the data state
				const updatedData = data.filter((appointment) => appointment.id !== id)
				setData(updatedData) // Immediately update the UI

				// Convert the start and end times to UTC before sending them to the backend
				const appointmentStartUTC = moment(appointmentStart)
					.utc()
					.format("YYYY-MM-DD HH:mm:ss")
				const appointmentEndUTC = moment(appointmentEnd)
					.utc()
					.format("YYYY-MM-DD HH:mm:ss")

				// Make the API call to cancel the appointment
				await axios.put(`/schedule/calendar/client_cancel/${id}`, {
					appointment: "cancel",
					lastModifiedBy: "clientPortal",
					id,
					email: clientEmail,
					pet,
					appDetailsStart: appointmentStartUTC, // Send as UTC
					appDetailsEnd: appointmentEndUTC, // Send as UTC
					name: groomer,
					city,
				})

				// Sync with the calendar
				const slotEvent = { start: appointmentStart }
				await synchronizeAndUpdateCals(slotEvent, "add")

				// Optionally refetch appointments to ensure data consistency
				await getAppointments()
			} catch (error) {
				// If there's an error, log it and optionally revert the optimistic update
				console.error("Error cancelling appointment:", error)

				// Optionally: revert the UI back to the original state (if needed)
				await getAppointments() // Refetch appointments in case of an error
			}
		}
	}

	const getAppointments = async () => {
		try {
			const nonEmptyPhones = [clientPhone1, clientPhone2, clientPhone3].filter(
				(phone) => phone
			)

			const res = await axios.get(
				`/schedule/calendar/search/all/${nonEmptyPhones.join("/")}`
			)

			const currentDate = new Date()
			const filteredEvents = res.data.filter(
				(event) =>
					!event.deletedAt &&
					!event.isBeingBooked &&
					event.appointment !== "cancel" &&
					new Date(event.start) > currentDate
			)

			console.log("Filtered events:", filteredEvents) // Check filtered events

			setData(filteredEvents) // Update state with filtered events
		} catch (err) {
			console.error("Error fetching appointments:", err)
		}
	}

	return (
		<div className="h-[400px] scroll-auto">
			<h5 className="mt-2 text-md">Your upcoming grooming appointments:</h5>
			<hr />
			<div className="flex flex-row flex-wrap gap-2">
				{data.length > 0 ? (
					data.map((app) => (
						<div
							className="border-blue-700 flex-1 shadow-lg border rounded-md px-4 flex flex-col py-4 max-w-sm text-black"
							key={app.id}
						>
							<div className="flex flex-row gap-4 justify-between">
								<span>
									Date and time:{" "}
									{moment(app.start).format("YYYY-MM-DD hh:mm a")}
								</span>
								{app.name === "daycare" ? (
									<span className="text-purple-500">DAYCARE</span>
								) : (
									<span className="text-cyan-400">GROOMING</span>
								)}
							</div>
							<span className="capitalize">Pet: {app.breedName}</span>
							<span className="capitalize">Groomer: {app.name}</span>
							<span className="capitalize">
								Location:{" "}
								{app.city === "milton"
									? "264 Bronte St S, Unit 15, Milton, ON"
									: "383 Elgin St N. Unit 1, Cambridge, ON "}
							</span>
							<button
								onClick={(e) =>
									cancelAppointmentHandler(
										e,
										app.breedName,
										moment(app.start).format("YYYY-MM-DD hh:mm a"),
										moment(app.end).format("YYYY-MM-DD hh:mm a"),
										app.id,
										app.name,
										app.city
									)
								}
								className="bg-red-500 mt-4 text-white px-4 py-1 rounded-md"
							>
								Cancel my appointment
							</button>
						</div>
					))
				) : (
					<div>No upcoming appointments.</div>
				)}
			</div>
		</div>
	)
}
