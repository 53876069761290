import React, { useRef } from "react"
import { gsap } from "gsap"
import { useLayoutEffect } from "react"

const BookingCard = ({
	image,
	title,
	description,
	onClick,
	column = false,
}) => {
	const tl = useRef(null)
	const container = useRef(null)

	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			tl.current = gsap.timeline({ paused: true })
			tl.current.to(container.current, {
				scale: 1.03,
				borderColor: "#65BFF8",
				backgroundColor: "#DDF2FF",
				boxShadow: "2px 4px 12px 0px rgba(0, 156, 255, 0.10)",
				duration: 0.2,
			})
			tl.current.to(
				".bgImage",
				{
					clipPath: "circle(100% at 50% 50%)",
					duration: 0.2,
				},
				"-=0.2"
			)
			tl.current.to(
				".imageCard",
				{
					borderColor: "#65BFF8",
					duration: 0.2,
				},
				"-=0.2"
			)
			tl.current.to(
				".textCard",
				{
					color: "#65BFF8",
					duration: 0.2,
				},
				"-=0.2"
			)
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<div
			className={`p-phone rounded-default border-[1px] border-lightGray flex items-center justify-start cursor-pointer gap-[20px] ${
				column ? "flex-col md:flex-row md:mb-0" : "flex-row"
			}}`}
			style={{ backgroundColor: "#FFFFFF" }}
			onClick={onClick}
			onMouseEnter={() => tl.current.play()}
			onMouseLeave={() => tl.current.reverse()}
			ref={container}
		>
			<div className="w-[70px] h-[70px] rounded-full border-[1px] border-lightGray flex items-center justify-center imageCard relative overflow-hidden">
				<img src={image} alt={title} className="w-[50px] rounded-full" />
				<div
					className="w-full h-full absolute bg-main bgImage -z-10"
					style={{ clipPath: "circle(0px at 50% 50%)" }}
				/>
			</div>
			<div className="flex-1 flex flex-col w-full">
				<h5 className="mb-0 text-lightGray font-medium textCard">{title}</h5>
				<p className="mb-0 text-lightGray textCard">{description}</p>
			</div>
		</div>
	)
}

export default BookingCard
