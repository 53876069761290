import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap, { Back, Bounce, Expo } from "gsap";

const FaqItem = ({ question, icon, children, onClick, isOpen }) => {
  const container = useRef(null);
  const animationTl = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      animationTl.current = gsap.timeline({ paused: true });

      animationTl.current.to(".answer-container", {
        height: "auto",
      });
    }, container);

    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        defaults: { ease: Back.easeOut.config(1.7) },
        scrollTrigger: {
          trigger: container.current,
          start: "top 90%",
        },
      });

      tl.fromTo(
        ".faqItem",
        {
          y: 50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.7,
        }
      );
    }, container);

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    if (isOpen) {
      animationTl.current.duration(0.7);
      animationTl.current.play();
    } else {
      animationTl.current.duration(0.3);
      animationTl.current.reverse();
    }
  }, [isOpen]);

  return (
    <div ref={container}>
      {" "}
      <div
        className="w-full flex flex-col px-[30px] py-[25px] bg-lightBlue rounded-default faqItem"
        onClick={onClick}
      >
        {/* Header */}
        <div className="flex flex-row items-center justify-between w-full cursor-pointer">
          <div className="flex flex-row items-center gap-[20px]">
            <div className="min-w-[50px] min-h-[50px] rounded-full bg-white flex items-center justify-center">
              <span className="text-[25px] leading-none">{icon}</span>
            </div>
            <h4 className="text-[25px] font-bold text-main mb-0 pr-[10px] md:pr-0">
              {question}
            </h4>
          </div>
          <span
            className={`${
              isOpen ? "rotate-90" : "rotate-0"
            } transition-all text-main font-black`}
          >
            v
          </span>
        </div>

        {/* Body */}
        <div className="md:px-[70px] text-main h-0 overflow-hidden answer-container">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FaqItem;
