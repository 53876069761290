import React from "react";
import "./style.scss";

const DeleteBtn = (props) => {
	return (
		<button className="btn btn-danger m-1" onClick={props.clearCommission}>
			Clear {props.groomerName}
		</button>
	);
};

export default DeleteBtn;
