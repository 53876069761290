import React from "react";

export const CustomCard = ({ children, className, oppositeColor = false }) => {
  return (
    <div
      className={`w-full relative border-[1px] border-main/30 p-[20px] rounded-default ${className} ${
        oppositeColor ? "bg-lightBlue" : "bg-white"
      }`}
      style={{
        boxShadow: "2px 4px 12px 0px rgba(0, 156, 255, 0.10)",
      }}
    >
      {children}
    </div>
  );
};
