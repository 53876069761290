import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import API from "../../utils/API"
import Button from "reactstrap/lib/Button"

const ModalToAddClients = (props) => {
	const [lastName, setLastName] = useState("")
	const [firstName, setFirstName] = useState("")
	const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("")
	const [cellphone, setCellphone] = useState("")
	const [workPhone, setWorkPhone] = useState("")
	const [email, setEmail] = useState("")
	const [errorMsg, setErrorMsg] = useState("")
	const [selectedCity, setSelectedCity] = useState("CAMBRIDGE")

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
	}
	// Add client form submit
	const handleFormSubmit = async (e) => {
		e.preventDefault()

		if (!lastName || !firstName || !primaryPhoneNumber) {
			return setErrorMsg("Please enter the required fields!")
		}

		let url

		if (selectedCity === "MILTON") {
			url = API.addClient
		} else {
			url = API.addClientCambridge
		}

		await url({
			lastName: capitalizeFirstLetter(lastName).replace(/[^A-Z0-9]/gi, ""),
			firstName: capitalizeFirstLetter(firstName).replace(/[^A-Z0-9]/gi, ""),
			primaryPhoneNumber: primaryPhoneNumber.replace(/[- )(]/g, ""),
			cellphone: cellphone.replace(/[- )(]/g, ""),
			workPhone: workPhone.replace(/[- )(]/g, ""),
			email: email,
		})
			.then((res) => {
				if (selectedCity === "MILTON") {
					window.location.href = `/clients/milton/${res.data.id}`
				} else {
					window.location.href = "/clients/cambridge/" + res.data.id
				}
			})
			.catch((err) => console.log(err))
	}
	return (
		<Modal
			isOpen={props.isOpen}
			toggle={props.toggle}
			modalClassName="modal-add-clients"
			city={selectedCity}
		>
			<ModalHeader toggle={props.toggle}>
				Add Clients <i className="fas fa-user-plus"></i>{" "}
				<span
					className={
						selectedCity === "CAMBRIDGE" ? "text-warning" : "text-info"
					}
				>
					{selectedCity}
				</span>
			</ModalHeader>
			<ModalBody>
				<div className="container">
					<div className="add-clients-modal-content">
						<div className="text-center">
							<button
								className="btn btn-info mr-2"
								onClick={() => setSelectedCity("MILTON")}
							>
								Milton
							</button>
							<button
								className="btn btn-warning"
								onClick={() => setSelectedCity("CAMBRIDGE")}
							>
								Cambridge
							</button>
						</div>
						<form className="form-group" onSubmit={(e) => handleFormSubmit(e)}>
							<h4
								className="grey-text text-darken-3"
								style={{
									textAlign: "center",
									marginTop: "15px",
								}}
							>
								Add a New Client <i className="fas fa-user-plus"></i>
							</h4>
							<p>* Fields required</p>
							<hr
								style={{
									background: "grey",
									marginTop: "10px",
								}}
							></hr>
							<div className="input-field">
								<label htmlFor="lastName">* Last Name</label>
								<input
									className="form-control"
									type="text"
									id="lastName"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
							<div className="input-field">
								<label htmlFor="firstName">* First Name</label>
								<input
									className="form-control"
									type="text"
									id="firstName"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</div>
							<div className="input-field">
								<label htmlFor="phone">
									* Primary Phone <i className="fas fa-phone"></i>
								</label>
								<input
									className="form-control"
									type="text"
									id="primaryPhoneNumber"
									value={primaryPhoneNumber}
									onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
								/>
							</div>
							<div className="input-field">
								<label htmlFor="cellphone">Cell</label>
								<input
									className="form-control"
									type="text"
									id="cellphone"
									value={cellphone}
									onChange={(e) => setCellphone(e.target.value)}
								/>
							</div>
							<div className="input-field">
								<label htmlFor="workPhone">Emergency</label>
								<input
									className="form-control"
									type="text"
									id="workPhone"
									value={workPhone}
									onChange={(e) => setWorkPhone(e.target.value)}
								/>
							</div>
							<div className="input-field">
								<label htmlFor="email">Email</label>
								<input
									className="form-control"
									type="email"
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="input-field">
								<Button
									color="warning"
									block
									style={{
										marginTop: "30px",
									}}
									className="btn-primary lighten-1 z-depth-0"
								>
									Add Client
								</Button>
							</div>
							<p
								style={{
									textAlign: "center",
									color: "red",
									marginTop: "15px",
								}}
							>
								{errorMsg}
							</p>
						</form>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default ModalToAddClients
