import React from "react";
import { Button, Modal, ModalBody, ModalHeader, Form, Input } from "reactstrap";
import LoadPage from "../../components/LoadingPage";
import moment from "moment";

const ModalToAdd = (props) => {
  return (
    <Modal
      modalClassName="modal-add-clients"
      isOpen={props.openModal}
      toggle={props.toggleModalToAddCommission}
    >
      <ModalHeader toggle={props.toggleModalToAddCommission}>
        Add Pets to{" "}
        <strong>
          {props.groomerName && String(props.groomerName).toUpperCase()}
        </strong>{" "}
        for Today
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={props.submitFormAll}>
          <Input
            className="form-commission"
            value={props.valueClientNumber}
            name="clientNumber"
            onChange={props.handleChange}
            placeholder="Client #"
            type="text"
          />
          <Input
            className="form-commission"
            value={props.valueClientPhone}
            name="clientPhone"
            onChange={props.handleChange}
            placeholder="Phone #"
            type="text"
          />
          <Input
            className="form-commission"
            value={props.valueNameBreed}
            name="nameBreed"
            onChange={props.handleChange}
            placeholder="Name - Breed"
            type="text"
          />
          <Input
            className="form-commission"
            value={props.valueArrivalTime}
            name="arrivalTime"
            onChange={props.handleChange}
            placeholder="Arrival Time"
            type="text"
          />
          <Input
            className="form-commission"
            value={props.valuePickupTime}
            name="pickupTime"
            onChange={props.handleChange}
            placeholder={`Pickup Time`}
            type="text"
          />

          {props.loadingAxiosReq ? (
            <LoadPage />
          ) : (
            <Button className="addPet-btn" block color="warning">
              Add
            </Button>
          )}
          <p className="error-message-registration text-align-center margin-top-small">
            {props.errMsg}
          </p>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalToAdd;
