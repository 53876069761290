import React, { useLayoutEffect, useRef } from "react";
import { ServiceCard } from "./ServiceCard";
import { services } from "../utils/constants";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export const Services = () => {
  const container = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const cards = gsap.utils.toArray(".serviceCard");
      gsap.fromTo(
        cards,
        {
          y: 50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          stagger: 0.1,
          scrollTrigger: {
            trigger: ".triggerContainer",
            start: "top 80%",
          },
        }
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <section
      className="w-full bg-lightBlue py-desktop md:py-desktop z-[200] flex flex-col gap-[100px]"
      id="services"
      ref={container}
    >
      {/* Title marquee */}
      <h3 className="w-full flex items-center justify-start overflow-x-clip">
        {/* This div allows the marquee to overflow in order not to show where each character appears or disappears */}
        <svg
          viewBox="0 0 1284 47"
          xmlns="http://www.w3.org/2000/svg"
          className="min-w-[110vw] overflow-visible select-none relative -left-[5%]"
        >
          <path
            d="M1 22.5005C191.5 90.5001 362.5 77 652.5 22.5005C858.269 -16.1695 1080.5 6.49999 1279 22.5005"
            fill="transparent"
            id="servicesCurve"
          />
          <text x="-2000">
            <textPath
              id="text-path"
              href="#servicesCurve"
              startOffset="0"
              className="text-[200px] md:text-[100px] font-black fill-main"
            >
              {/* Marquee text */}
              {(() => {
                const result = [];
                for (let i = 0; i < 20; i++) {
                  result.push("Our services ✼ ");
                }
                return result;
              })()}
            </textPath>
            <animate
              attributeName="x"
              dur="30s"
              values="-4000;0"
              repeatCount="indefinite"
            ></animate>
          </text>
        </svg>
      </h3>

      <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-phone md:px-tablet lg:px-desktop gap-[50px] triggerContainer">
        {services.map((service) => (
          <ServiceCard
            service={service}
            key={service.name}
            className="serviceCard opacity-0"
          />
        ))}
      </div>
    </section>
  );
};
