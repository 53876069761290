import React, { useEffect, useState, useContext } from "react"
import ProductCard from "../../components/ProductCard"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import axios from "axios"
import { useSearchParams } from "react-router-dom"
import "./style.scss"

const Store = () => {
	const [loading, setLoading] = useState(true)
	const [products, setProducts] = useState([])
	const [quantities, setQuantities] = useState({})
	const { setCart } = useContext(RegistrationContext)
	const [searchParams, setSearchParams] = useSearchParams()

	useEffect(() => {
		const paymentSuccess = searchParams.get("payment-success")

		if (paymentSuccess === "true") {
			setCart([])
		}
	}, [])

	useEffect(() => {
		setLoading(true)
		const fetchProducts = async () => {
			try {
				const response = await axios.get("/api/store/products")
				setTimeout(() => {
					setLoading(false)
				}, 1000)
				setProducts(response.data)
			} catch (error) {
				console.error("Error fetching products:", error)
			}
		}
		fetchProducts()
	}, [])

	const addToCart = (product, quantity) => {
		setCart((prevCart) => {
			const existingItem = prevCart.find((item) => item.id === product.id)
			if (existingItem) {
				return prevCart.map((item) =>
					item.id === product.id
						? { ...item, quantity: item.quantity + quantity }
						: item
				)
			} else {
				return [...prevCart, { ...product, quantity }]
			}
		})
	}

	const handleQuantityChange = (productId, newQuantity) => {
		setQuantities((prev) => ({
			...prev,
			[productId]: newQuantity,
		}))
	}

	return (
		<div className="container  mx-auto py-10 store_page">
			{loading ? (
				<div className="text-center py-36 mt-20 text-cyan-400">
					<h4>Loading Products...</h4>
				</div>
			) : (
				<>
					<h1 className="text-2xl font-bold mb-6 text-center">
						Current Products
					</h1>
					<hr />
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
						{products.length > 0 ? (
							products.map((product) => (
								<ProductCard
									key={product.id}
									product={product}
									onAddToCart={addToCart}
									quantity={quantities[product.id] || 1}
									onQuantityChange={(newQuantity) =>
										handleQuantityChange(product.id, newQuantity)
									}
								/>
							))
						) : (
							<p>No products yet</p>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default Store
