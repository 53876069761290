import React from "react"
import { optionsDogSize } from "../../../pages/Schedule/Schedule.options"
import PetSize from "../PetSize"

const DogSizeScreen = ({ functionCalled }) => {
	return (
		<>
			<h5 className="text-black font-black text-[30px] md:text-[45px]">
				What size?:
			</h5>

			{/* Form choices */}
			<div className="w-full flex items-center justify-center relative">
				<div
					className="w-full h-[50%] lg:h-[90%] border-b-[5px] border-main grid relative"
					style={{
						gridTemplateColumns: "repeat(19, minmax(0, 1fr))",
					}}
				>
					{optionsDogSize.map((option, index) => {
						switch (option.label) {
							case "Small":
								return (
									<PetSize
										key={index}
										start={2}
										end={4}
										size="Small"
										onClick={() => functionCalled(option)}
									/>
								)
							case "Medium":
								return (
									<PetSize
										key={index}
										start={5}
										end={8}
										size="Medium"
										onClick={() => functionCalled(option)}
									/>
								)
							case "Large":
								return (
									<PetSize
										key={index}
										start={9}
										end={13}
										size="Large"
										onClick={() => functionCalled(option)}
									/>
								)
							case "X-Large":
								return (
									<PetSize
										key={index}
										start={14}
										end={19}
										size="X-Lg"
										onClick={() => functionCalled(option)}
									/>
								)
							default:
								return null
						}
					})}

					{/* Bottom circles */}
					<div className="w-[25px] h-[25px] bg-main absolute -bottom-[15px] -left-[10px] rounded-full" />
					<div className="w-[25px] h-[25px] bg-main absolute -bottom-[15px] -right-[10px] rounded-full" />
				</div>
			</div>
		</>
	)
}

export default DogSizeScreen
