import React, { Component } from "react";
import LoadPage from "../../components/LoadingPage";
import { useParams } from "react-router-dom";
import {
	Label,
	Button,
	Form,
	FormGroup,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import "./style.scss";
import Select from "react-select";
import API from "../../utils/APICAMBRIDGE";
import { scroller, Element } from "react-scroll";
import axios from "axios";
import ModalToUpdateOwner from "../../components/Modals/UpdateClient";

let petTypeOptions = [
	{
		value: "Dog",
		label: "Dog",
	},
	{
		value: "Cat",
		label: "Cat",
	},
];

function withRouter(Children) {
	return (props) => {
		const match = { params: useParams() };
		return <Children {...props} match={match} />;
	};
}
class index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			error: false,
			pets: [],
			petName: undefined,
			breed: undefined,
			petImg: "",
			type: undefined,
			notes: "",
			firstName: "",
			lastName: "",
			primaryPhoneNumber: "",
			cellphone: "",
			workPhone: "",
			email: "",
			firstNameEdit: "",
			lastNameEdit: "",
			primaryPhoneNumberEdit: "",
			cellphoneEdit: "",
			workPhoneEdit: "",
			emailEdit: "",
			allowPhotoIsNotChecked: false,
			loadingAxiosReq: false,
			errorMsg: "",
			modalToAddPet: true,
			modalForEdittingPets: false,
			modalTofinishCustomer: false,
			modalToUpdateOwner: false,
			isCustomerDone: false,
			customerIsReady: false,
			PetId: null,
			ClientId: null,
			petNameToEdit: null,
			petBreedToEdit: null,
			petTypeToEdit: null,
			petNotesToEdit: null,
		};
	}

	async componentDidMount() {
		this.getClientInformation();
	}

	componentDidUpdate(prevProps) {
		const { pets, modalToAddPet } = this.state;

		if (
			pets.length > 0 &&
			!modalToAddPet &&
			(prevProps.pets !== pets || prevProps.modalToAddPet !== modalToAddPet)
		) {
			scroller.scrollTo("done-with-pets-button", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			});
		}
	}

	getClientInformation = async () => {
		await axios
			.get("/auth/customer_cambridge/" + this.props.match.params.regid)
			.then(async (res) => {
				// console.log(res);
				await this.setState({
					ClientId: res.data.id,
					pets: res.data.PetCambridges,
					firstName: res.data.firstName,
					lastName: res.data.lastName,
					primaryPhoneNumber: res.data.primaryPhoneNumber,
					cellphone: res.data.cellphone,
					workPhone: res.data.workPhone,
					email: res.data.email,
				});
			})
			.catch((err) => console.log(err));
	};

	onSelectedChangedToEdit = (value) => {
		this.setState({
			petTypeToEdit: value,
		});
	};

	onSelectedChanged = (value) => {
		this.setState({
			type: value,
		});
	};

	onChangeModalEdit = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	toggleChangeAllowPhoto = () => {
		if (this.state.allowPhotoIsNotChecked) {
			this.setState({
				errorMsg: "",
			});
		}
		this.setState({
			allowPhotoIsNotChecked: !this.state.allowPhotoIsNotChecked,
		});
		console.log(this.state.allowPhotoIsNotChecked);
	};

	//Add a Pet to Client
	onSubmitPetForm = async (e) => {
		e.preventDefault();

		if (!this.state.type || !this.state.petName || !this.state.breed) {
			return this.setState({
				errorMsg: "Please enter the required fields above!",
			});
		}

		let obj = {
			name: this.state.petName.replace(/^./, (str) => str.toUpperCase()),
			breed: this.state.breed.replace(/^./, (str) => str.toUpperCase()),
			type: this.state.type.value,
			notes: this.state.notes,
			allowPhoto: this.state.allowPhotoIsNotChecked,
		};

		let ClientId = this.state.ClientId;
		this.setState({
			loadingAxiosReq: true,
		});
		await API.addPet(ClientId, obj)

			.then((res) => {
				this.setState({
					pets: res.data,
					loadingAxiosReq: false,
				});
				this.getClientInformation();
				this.toggleModalForAddingPets();
			})
			.catch((error) => {
				this.setState({
					loadingAxiosReq: false,
				});
				console.log(error);
			});
	};

	toggleModalToUpdateOwner = async () => {
		await axios
			.get("/auth/customer_cambridge/" + this.props.match.params.regid)
			.then((res) => {
				this.setState({
					firstNameEdit: res.data.firstName,
					lastNameEdit: res.data.lastName,
					primaryPhoneNumberEdit: res.data.primaryPhoneNumber,
					cellphoneEdit: res.data.cellphone,
					workPhoneEdit: res.data.workPhone,
					emailEdit: res.data.email,
				});
			})
			.catch((err) => console.log(err));
		this.setState({
			modalToUpdateOwner: !this.state.modalToUpdateOwner,
		});
	};

	toggleModalForAddingPets = () => {
		if (this.state.modalToAddPet === false) {
			this.setState({
				petName: "",
				breed: "",
				type: "",
				notes: "",
				errorMsg: "",
			});
		}
		this.setState({
			modalToAddPet: !this.state.modalToAddPet,
			customerIsReady: false,
		});
	};

	handleCustomerDone = () => {
		this.setState({
			modalTofinishCustomer: true,
		});
		function sayUserSessionIsOver() {
			window.location.href = "/";
		}
		setTimeout(sayUserSessionIsOver, 9000);
	};

	toggleModalForEdittingPets = () => {
		if (this.state.modalForEdittingPets) {
			this.setState({
				PetId: "",
				petNameToEdit: "",
				petBreedToEdit: "",
				petTypeToEdit: "",
				petNotesToEdit: "",
			});
		}
		this.setState({
			modalForEdittingPets: !this.state.modalForEdittingPets,
		});
	};

	getPetIdForUpdateFunc = async ({ currentTarget }) => {
		this.toggleModalForEdittingPets();
		const id = currentTarget.value;
		await API.getPet(id)
			.then((res) => {
				this.setState({
					PetId: res.data.id,
					petNameToEdit: res.data.name,
					petBreedToEdit: res.data.breed,
					petTypeToEdit: res.data.type,
					petNotesToEdit: res.data.notes,
				});
			})
			.catch((error) => console.log(error));
	};

	updatePet = async (e) => {
		e.preventDefault();

		let petObj = {
			name: this.state.petNameToEdit.replace(/^./, (str) => str.toUpperCase()),
			breed: this.state.petBreedToEdit.replace(/^./, (str) =>
				str.toUpperCase()
			),
			type:
				typeof this.state.petTypeToEdit === "object"
					? this.state.petTypeToEdit.value
					: this.state.petTypeToEdit,
			notes: this.state.petNotesToEdit,
		};
		if (!petObj.name || !petObj.breed || !petObj.type) {
			return alert("Missing a required field!");
		}
		let id = this.state.PetId;
		await API.updatePet(id, petObj)
			.then(() => {
				this.getClientInformation();
				this.toggleModalForEdittingPets();
			})
			.catch((error) => console.log(error));
	};

	render() {
		const pets = this.state.pets;
		const petsList =
			pets.length > 0 ? (
				pets.map((pet) => {
					return (
						<div key={pet.id}>
							<div className="card pet-card-customer">
								<h4 className="text-align-center">
									<i className="fas fa-paw"></i> Pet Info
									<hr />
								</h4>
								<div className=" text-align-left">
									<p className="pl-2 label-customer-addPet-page">
										Pet Name:{" "}
										<span className="text-content-addPet-page-pet">
											{" "}
											{pet.name}
										</span>
										<br />
										Breed:{" "}
										<span className="text-content-addPet-page-pet">
											{pet.breed}
										</span>
										<br />
										Type:{" "}
										<span className="text-content-addPet-page-pet">
											{pet.type}
										</span>
										<br />
										Notes:{" "}
										<span className="text-content-addPet-page-pet">
											{" "}
											{pet.notes ? pet.notes : "Nothing"}
										</span>
									</p>
									<button
										className="btn btn-info btn-block"
										value={pet.id}
										onClick={this.getPetIdForUpdateFunc}
									>
										Update Pet
									</button>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div>
					<h4 className="text-align-center">
						<i className="fas fa-paw"></i> PETS
					</h4>
					No pets added yet, please add them by clicking the yellow Button
				</div>
			);

		return (
			<div className=" customer-add-pet-page-container__cambridge">
				<h1 className="text-align-center">
					<i className="fas fa-dog"></i> One last step{" "}
					<i className="fas fa-cat"></i>
				</h1>
				<hr className="hr-white" />
				<div className="register_pet_container">
					<div className="register_pet_client_container card">
						<h2 className="text-align-center">
							<i className="fas fa-user-circle"></i> Owner Information{" "}
							<button
								onClick={this.toggleModalToUpdateOwner}
								className="btn btn-warning"
							>
								Update Owner Info
							</button>
						</h2>
						<ModalToUpdateOwner
							clientId={this.state.ClientId}
							modalToUpdateOwner={this.state.modalToUpdateOwner}
							toggleModalToUpdateOwner={this.toggleModalToUpdateOwner}
							firstName={this.state.firstName}
							lastName={this.state.lastName}
							primaryPhoneNumber={this.state.primaryPhoneNumber}
							cellphone={this.state.cellphone}
							workPhone={this.state.workPhone}
							email={this.state.email}
							getClientInformation={this.getClientInformation}
						/>
						<span>Please add your pet(s), one at a time</span>
						<hr className="hr-black" />
						<p className="label-customer-addPet-page">
							First Name:{" "}
							<span className="text-content-addPet-page">
								{this.state.firstName}
							</span>{" "}
						</p>

						<p className="label-customer-addPet-page">
							Last Name:{" "}
							<span className="text-content-addPet-page">
								{" "}
								{this.state.lastName}
							</span>{" "}
						</p>
						<p className="label-customer-addPet-page">
							Primary Phone:{" "}
							<span className="text-content-addPet-page">
								{" "}
								{this.state.primaryPhoneNumber}
							</span>{" "}
						</p>
						<p className="label-customer-addPet-page">
							Cell:{" "}
							<span className="text-content-addPet-page">
								{this.state.cellphone}
							</span>{" "}
						</p>
						<p className="label-customer-addPet-page">
							Emergency Phone:{" "}
							<span className="text-content-addPet-page">
								{" "}
								{this.state.workPhone}
							</span>{" "}
						</p>
						<p className="label-customer-addPet-page">
							Email:{" "}
							<span className="text-content-addPet-page">
								{this.state.email}
							</span>{" "}
						</p>
						<Button
							className="add-pet-button-customer"
							onClick={this.toggleModalForAddingPets}
							color="warning"
						>
							<i className="fas fa-plus"></i> Click here to Add Pets{" "}
							<i className="fas fa-paw"></i>
						</Button>

						{this.state.customerIsReady ? (
							<>
								<h4 className="text-center">
									Final Step
									<br />
									<i className="fas fa-arrow-circle-down"></i>
								</h4>
								<Button
									block
									className="done-btn button-flashing-submit"
									onClick={this.handleCustomerDone}
									color="dark"
								>
									<strong>
										<i className="fas fa-check"></i> CLICK TO COMPLETE MY
										REGISTRATION {""}
										<i className="fas fa-paw"></i>
									</strong>
								</Button>
							</>
						) : null}
					</div>
					<div className="registration_pet_pet_wrapper">
						<h3 className="pets_list_title text-center mt-3">
							<i className="fas fa-arrow-circle-down"></i> My Pets{" "}
							<i className="fas fa-arrow-circle-down"></i>
						</h3>
						{petsList}
						{this.state.pets.length > 0 && !this.state.customerIsReady && (
							<Element name="done-with-pets-button">
								<Button
									onClick={this.handleCustomerDone}
									className="mt-8 btn-success btn-block button-flashing-submit"
								>
									<strong>Click here if you are done</strong>{" "}
								</Button>
							</Element>
						)}
						<Modal
							modalClassName="modal-cambridge-calendar"
							isOpen={this.state.modalToAddPet}
							toggle={this.toggleModalForAddingPets}
						>
							<ModalBody>
								<div className="form-registration-container">
									<button
										onClick={this.toggleModalForAddingPets}
										className="bg-slate-500 text-white px-1 absolute"
									>
										X
									</button>
									<form className="form-group" onSubmit={this.onSubmitPetForm}>
										<h4
											className="grey-text text-darken-3"
											style={{
												textAlign: "center",
												marginTop: "15px",
											}}
										>
											<i className="fas fa-paw"></i> Add your pet below <br />{" "}
											(One pet per form)
										</h4>

										<p>* Fields required</p>
										<hr
											style={{
												background: "grey",
												marginTop: "10px",
											}}
										></hr>
										<div className="input-field-registration">
											<input
												className="form-control"
												type="text"
												placeholder="* Pet Name"
												value={this.state.petName}
												name="petName"
												onChange={this.handleChange}
											/>
										</div>
										<div className="input-field-registration">
											<input
												className="form-control"
												type="text"
												placeholder="* Breed"
												value={this.state.breed}
												name="breed"
												onChange={this.handleChange}
											/>
										</div>
										<div className="input-field-registration">
											<Select
												value={this.state.type}
												options={petTypeOptions}
												placeholder={"* Select Pet Type"}
												isSearchable={false}
												onChange={this.onSelectedChanged}
												// styles={colourStyles}
											/>
										</div>
										<div className="input-field-registration">
											<textarea
												className="form-control"
												type="text"
												placeholder="Warnings / notes (Optional)"
												name="notes"
												value={this.state.notes}
												onChange={this.handleChange}
											/>
										</div>
										<p className="agree-with-photo">
											<input
												className="checkbox-registration"
												onChange={this.toggleChangeAllowPhoto}
												type="checkbox"
												name="checkbox"
												value={this.state.toggleChangeAllowPhoto}
											></input>{" "}
											<span className="photo-release ">
												(OPTIONAL) <i className="fas fa-camera-retro"></i> I
												authorize Amazing Pet grooming, to publish photographs
												taken of my pet(s), for use in the Company's print,
												online and video-based marketing materials, as well as
												other Company publications. I also understand that I
												will not receive financial compensation of any type
												associated with this material.
											</span>
										</p>

										{this.state.loadingAxiosReq ? (
											<LoadPage />
										) : (
											<div className="input-field-registration">
												<Button
													color="info"
													block
													style={{
														marginTop: "10px",
														fontWeight: "700",
														fontSize: "22px",
													}}
													className="btn-primary lighten-1 z-depth-0"
												>
													Submit
												</Button>
											</div>
										)}
									</form>
									<p className="error-message-registration text-align-center">
										{this.state.errorMsg}
									</p>
								</div>
							</ModalBody>
						</Modal>

						{/* Modal for editing pets */}
						<Modal
							modalClassName="update_owner_modal"
							className="col-sm-6"
							isOpen={this.state.modalForEdittingPets}
							toggle={this.toggleModalForEdittingPets}
						>
							<ModalBody>
								<button
									onClick={this.toggleModalForEdittingPets}
									className="bg-slate-500 text-white px-1 absolute"
								>
									X
								</button>
								<Form onSubmit={this.updatePet}>
									<FormGroup>
										<h2 className="titleComments">Edit Your Pet</h2>
										<Label>Name</Label>
										<Input
											id="petNameToEdit"
											onChange={this.onChangeModalEdit}
											type="text"
											name="petName"
											defaultValue={this.state.petNameToEdit}
										/>
										<Label className="mt-2">Breed</Label>
										<Input
											id="petBreedToEdit"
											onChange={this.onChangeModalEdit}
											type="text"
											name="breed"
											defaultValue={this.state.petBreedToEdit}
										/>
										<Label className="mt-2">
											Current Type:{" "}
											{this.state.petTypeToEdit &&
											typeof this.state.petTypeToEdit === "object"
												? this.state.petTypeToEdit.value
												: this.state.petTypeToEdit}
										</Label>
										<Select
											name="form-field-name"
											defaultValue={this.state.petTypeToEdit}
											options={petTypeOptions}
											isSearchable={false}
											onChange={this.onSelectedChangedToEdit}
										/>
										<Label className="mt-2">Notes</Label>
										<Input
											id="petNotesToEdit"
											onChange={this.onChangeModalEdit}
											type="text"
											name="petNotesToEdit"
											defaultValue={this.state.petNotesToEdit}
										/>

										<Button
											value={this.state.PetId}
											className="petEditBtn"
											color="warning"
										>
											Submit Changes
										</Button>
									</FormGroup>
								</Form>
							</ModalBody>
						</Modal>

						{/* Final Modal after registering */}
						<Modal
							modalClassName="modal-success-app"
							size="lg"
							isOpen={this.state.modalTofinishCustomer}
						>
							<ModalBody>
								<div className="count-down-bar">
									<h4 className="text-align-center count-down-bar_heading">
										Thank you! You have successfully registered{" "}
										<i className="fas fa-paw"></i>
									</h4>
									<video className="bg-video__content-pet" autoPlay muted loop>
										<source src="/images/dog-customer.mp4" type="video/mp4" />
										<source src="img/video.webm" type="video/webm" />
										Your browser is not supported!
									</video>
								</div>
							</ModalBody>
						</Modal>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(index);
