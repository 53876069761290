import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

const PetSize = ({ start, end, size, onClick }) => {
  const tl = useRef(null);
  const container = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline({ paused: true });
      tl.current.to(".radioCircle", {
        clipPath: "circle(100% at 50% 50%)",
        duration: 0.2,
      });
      tl.current.to(
        ".dogSizeImage",
        {
          y: -10,
          duration: 0.2,
        },
        "-=0.2"
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <div
      className="w-full h-full flex relative items-end cursor-pointer"
      style={{
        gridColumnStart: start,
        gridColumnEnd: end,
      }}
      onClick={onClick}
      onMouseEnter={() => tl.current.play()}
      onMouseLeave={() => tl.current.reverse()}
      ref={container}
    >
      <img
        src="/images/size-dog.png"
        className="w-full h-fit mb-[30px] select-none dogSizeImage"
      />
      <div className="w-[25px] h-[25px] border-[1px] border-main bg-white rounded-full absolute -bottom-[15px] left-1/2 transform -translate-x-1/2 overflow-hidden">
        <div className="w-full h-full relative">
          <div
            className="w-1/2 h-1/2 rounded-full absolute bg-main -z-10 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 radioCircle"
            style={{
              clipPath: "circle(0px at 50% 50%)",
            }}
          />
        </div>
      </div>
      <h5 className="absolute -bottom-[40px] text-base font-normal text-black text-center mb-0 left-1/2 transform -translate-x-1/2 petSizeText">
        {size}
      </h5>
    </div>
  );
};

export default PetSize;
