import React from "react";
import BookingCard from "../BookingCard";

const DogServices = ({ onClickGrooming, onClickBath }) => {
  return (
    <>
      {/* Form question */}
      <h5 className="text-black font-black text-[30px] md:text-[45px]">
        Choose type of service:
      </h5>

      {/* Form choises */}
      <div className="w-full h-full flex flex-col gap-[30px]">
        <BookingCard
          image="/images/grooming-icon.png"
          title="Grooming"
          description="Haircut, bath, brush, nails, ears."
          onClick={onClickGrooming}
        />
        <BookingCard
          image="/images/bath-icon.png"
          title="Bath"
          description="Bath, brush, nails, ears."
          onClick={onClickBath}
        />
      </div>
    </>
  );
};

export default DogServices;
