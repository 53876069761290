import { useEffect, useState } from "react"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import options from "../Calendars/CalendarOptions/serviceOptions"
import optionsAdmin from "../Calendars/CalendarOptions/servicePaolaOptions"
import optionsTrainee from "../Calendars/CalendarOptions/serviceOptionsTrainee"
import optionsNoSchedule from "../Calendars/CalendarOptions/serviceOptionsNoSchedule"
import axios from "axios"
import MainCalModalUpdateEvent from "../Modals/MainCalUpdateEvent"

const jobType = localStorage.getItem("JOBTYPE")

const userGroomers = [
	"groomer1", // Claudia
	"groomer2", // Diana
	"groomer3",
	"groomer4",
	"groomer5",
	"groomer6",
]

export default function Index({ clientPhone1, clientPhone2, clientPhone3 }) {
	const currentDate = moment().format("YYYY-MM-DD")
	const [data, setData] = useState([])

	// Update Event States
	const [modalToEditEvent, setModalToEditEvent] = useState(false)
	const [appointment, setAppointment] = useState("")
	const [slotEvent, setSlotEvent] = useState("")
	const [props, setProps] = useState({ groomer: "", isTrainee: false })
	const [title, setTitle] = useState("")
	const [breedName, setBreedName] = useState("")
	const [start, setStart] = useState("")
	const [end, setEnd] = useState("")
	const [breedInputEmpty, setBreedInputEmpty] = useState(false)
	const [titleInputEmpty, setTitleInputEmpty] = useState(false)
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [lastModifiedBy, setLastModifiedBy] = useState(
		localStorage.getItem("USERNAME")
	)
	const [eventHistoryToggle, setEventHistoryToggle] = useState(false)
	const [appointmentModifiers, setAppointmentModifiers] = useState([])

	useEffect(() => {
		getAppointments()
	}, [])

	const getAppointments = async () => {
		// Construct an array with non-empty phone numbers
		const nonEmptyPhones = [clientPhone1, clientPhone2, clientPhone3].filter(
			(phone) => phone
		)

		// Construct the URL dynamically based on non-empty phone numbers
		const url = `/schedule/calendar/search/all/${nonEmptyPhones.join("/")}`

		await axios
			.get(url)
			.then((res) => {
				const filteredEvents = res.data.filter(
					(event) => !event.deletedAt && !event.isBeingBooked
				)
				setData(filteredEvents)
			})
			.catch((err) => console.log(err))
	}

	const columns = [
		{
			dataField: "start",
			text: "Date & Time",
			sort: true,
			formatter: (cell) => {
				return <span>{moment(cell).format("MMMM Do YYYY, h:mm a")}</span>
			},
			headerStyle: { width: "150px" },
			style: { width: "150px" },
		},
		{
			dataField: "breedName",
			text: "Pet Name & Breed",
			sort: true,
			formatter: (cell) => {
				return <span className="capitalize">{cell}</span>
			},
		},
		{
			dataField: "city",
			text: "Location",
			sort: true,
			formatter: (cell) => {
				return <span className="capitalize">{cell}</span>
			},
		},
		{
			dataField: "name",
			text: "Groomer",
			sort: true,
			formatter: (cell) => {
				return <span className="capitalize">{cell}</span>
			},
		},
		{
			dataField: "appointment",
			text: "Appointment Code",
			sort: true,
		},
		{
			dataField: "actions",
			text: "Actions",
			isDummyField: true,
			csvExport: false,
			formatter: (cell, row) => {
				return (
					<div>
						<button
							disabled={
								moment(row.start).isSameOrBefore(currentDate) ||
								row.appointment === "cancel"
							}
							className="btn btn-info btn-table-clients"
							onClick={() => handleEventGetter(row)}
						>
							Modify
						</button>
					</div>
				)
			},
		},
	]

	const defaultSortedBy = [
		{
			dataField: "start",
			order: "desc",
		},
	]

	const toggleModalToEdit = async () => {
		if (!modalToEditEvent) {
			onCloseModal()
		}
		setModalToEditEvent(!modalToEditEvent)
	}

	const onCloseModal = () => {
		setTitle("")
		setBreedName("")
		setAppointment("")
		setSlotEvent("")
		setEventHistoryToggle(false)
		setBreedInputEmpty(false)
		setTitleInputEmpty(false)
	}

	const toggleHistoryHandler = (e) => {
		e.preventDefault()
		if (!appointmentModifiers.length) {
			return
		}
		return setEventHistoryToggle(!eventHistoryToggle)
	}

	const handleDeleteEvent = async (id) => {
		if (
			window.confirm(`Are you sure you wish to delete this Event permanently?`)
		) {
			setLoadingAxiosReq(true)

			if (title) {
				// console.log("To soft delete");
				const clientDeletedBy = {
					deletedAt: moment(new Date()).format("YYYY/MM/DD hh:mm"),
					deletedBy: localStorage.getItem("USERNAME"),
					isBeingBooked: false,
				}
				await axios
					.put(`/schedule/event/soft_delete/${id}`, clientDeletedBy)
					.then(async () => {
						getAppointments()
						setModalToEditEvent(false)
						setLoadingAxiosReq(false)
						setAppointment("")
					})
					.catch((error) => {
						console.log(error)
						setLoadingAxiosReq(false)
					})
			} else {
				await axios
					.delete(`/schedule/calendar/delete/${id}`)
					.then(() => {
						getAppointments()
						setModalToEditEvent(false)
						setLoadingAxiosReq(false)
					})
					.catch((error) => {
						console.log(error)
						setLoadingAxiosReq(false)
					})
			}
		}
	}

	const handleEventGetter = async (event) => {
		setTitle(event.title)
		setSlotEvent(event)
		setAppointment({ label: event.appointment, value: event.appointment })
		setBreedName(event.breedName)
		setStart(event.start)
		setEnd(event.end)
		setLastModifiedBy(event.lastModifiedBy)
		setAppointmentModifiers(event.AppointmentModifiers)
		setProps({ groomer: event.name })

		setModalToEditEvent(true)
	}

	const onSubmitModalToEdit = async (e) => {
		e.preventDefault()

		let obj = {
			id: slotEvent.id,
			title: title
				? title.toLowerCase()
				: appointment.value === "schedule" || appointment.value === "staffOnly"
				? ""
				: null,
			breedName: breedName
				? breedName.toLowerCase()
				: appointment.value === "schedule" || appointment.value === "staffOnly"
				? ""
				: null,
			start: start,
			end: end,
			appointment: appointment.value,
			lastModifiedBy: localStorage.getItem("USERNAME"),
			isBeingBooked: false,
		}
		if (
			appointment === "online" ||
			appointment === "onlineBath" ||
			appointment === "onlineCat" ||
			appointment === "staffOnly" ||
			appointment.value === "online" ||
			appointment.value === "onlineBath" ||
			appointment.value === "onlineCat" ||
			appointment.value === "staffOnly"
		) {
			//ONLINE title and breed-name events must be empty
			if (title || breedName) {
				if (title.length > 0 || breedName.length > 0) {
					if (title) {
						setTitleInputEmpty(true)
					}
					if (breedName) {
						setBreedInputEmpty(true)
					}
					return alert(
						"Action is not Allowed! Online appointments must be totally empty!"
					)
				}
			}
		} else if (appointment !== "schedule") {
			if (
				(typeof appointment === "object" && appointment.value !== "schedule") ||
				appointment !== "schedule" ||
				appointment !== "staffOnly" ||
				appointment.value !== "staffOnly"
			) {
				if (!title || !breedName) {
					if (!title) {
						setTitleInputEmpty(true)
					}
					if (!breedName) {
						setBreedInputEmpty(true)
					}
					return alert("Missing required fields!")
				}
			}
		}

		setLoadingAxiosReq(true)
		await axios
			.put(`/schedule/calendar/update/${slotEvent.id}`, obj)
			.then(() => {
				getAppointments()
				setModalToEditEvent(false)
				setLoadingAxiosReq(false)
				setAppointment("")
				setTitle("")
				setBreedName("")
			})
			.catch((error) => {
				console.log(error)
				setLoadingAxiosReq(false)
			})
	}

	return (
		<div className="">
			<h5 className="mt-2 text-md">Client's appointments history</h5>
			<hr />
			<BootstrapTable
				className="react-table-component"
				keyField="id"
				data={data}
				columns={columns}
				pagination={paginationFactory()}
				striped
				wrapperClasses="table-responsive"
				defaultSorted={defaultSortedBy}
				noDataIndication={"No appointments history for this client."}
			/>
			<MainCalModalUpdateEvent
				modalToEditEvent={modalToEditEvent}
				toggleModalToEdit={toggleModalToEdit}
				props={props && props}
				onSubmitModalToEdit={onSubmitModalToEdit}
				appointment={appointment && appointment}
				setAppointment={setAppointment}
				optionsAdmin={optionsAdmin}
				optionsTrainee={optionsTrainee}
				optionsNoSchedule={optionsNoSchedule}
				options={options}
				slotEvent={slotEvent && slotEvent}
				userGroomers={userGroomers}
				jobType={jobType}
				setTitle={setTitle}
				titleInputEmpty={titleInputEmpty}
				setTitleInputEmpty={setTitleInputEmpty}
				breedName={breedName}
				setBreedName={setBreedName}
				breedInputEmpty={breedInputEmpty}
				setBreedInputEmpty={setBreedInputEmpty}
				lastModifiedBy={lastModifiedBy}
				toggleHistoryHandler={toggleHistoryHandler}
				eventHistoryToggle={eventHistoryToggle}
				appointmentModifiers={appointmentModifiers}
				loadingAxiosReq={loadingAxiosReq}
				setLoadingAxiosReq={setLoadingAxiosReq}
				handleDeleteEvent={handleDeleteEvent}
				start={start}
			/>
		</div>
	)
}
