import axios from "axios"
import React, { useState } from "react"
import Select from "react-select"
import { Link } from "react-router-dom"
import { saveAs } from "file-saver"
import { ThreeDots } from "react-loader-spinner"

const cityOptions = [
	{ label: "Milton", value: "milton" },
	{ label: "Cambridge", value: "cambridge" },
]

export default function ClientsList() {
	const [isLoading, setIsLoading] = useState(false)
	const [start, setStart] = useState(3)
	const [end, setEnd] = useState(12)
	const [city, setCity] = useState("")
	const [clients, setClients] = useState([])
	const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" })

	const getClientsList = async (e) => {
		e.preventDefault()
		const accessString = localStorage.getItem("JWT")

		if (!city) {
			return alert("Please select a city!")
		}
		if (!start || !end) {
			return alert("Please add the date range!")
		}
		if (end <= start) {
			return alert("End cannot be less than start")
		}

		const url =
			city === "milton"
				? "/api/not_returned_clients"
				: "/api/cambridge/not_returned_clients"
		setIsLoading(true)
		try {
			const response = await axios.get(`${url}/${start}/${end}`, {
				headers: { jwt: `${accessString}` },
			})

			setClients(response.data)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.error("Error fetching clients:", error)
			alert("An error occurred while fetching clients.")
		}
	}

	const sortData = (key) => {
		let direction = "asc"
		if (sortConfig.key === key && sortConfig.direction === "asc") {
			direction = "desc"
		}
		setSortConfig({ key, direction })

		const sortedClients = [...clients].sort((a, b) => {
			if (a[key] < b[key]) {
				return direction === "asc" ? -1 : 1
			}
			if (a[key] > b[key]) {
				return direction === "asc" ? 1 : -1
			}
			return 0
		})
		setClients(sortedClients)
	}

	const getSortIcon = (key) => {
		if (sortConfig.key === key) {
			return sortConfig.direction === "asc" ? "▲" : "▼"
		}
		return ""
	}

	const convertToCSV = (data) => {
		const headers = [
			"Client ID",
			"Last Visit",
			"Client Full Name",
			"Phone Number",
			"Pet Name",
			"Breed",
			"Type",
			"Pet Notes",
			"Last Groomer",
			"Comment",
		]

		const rows = data.map((client) => [
			client.client_id,
			client.last_visit,
			`${client.client_name} ${client.client_last_name}`,
			client.primaryPhoneNumber,
			client.pet_name,
			client.pet_breed,
			client.pet_type,
			client.pet_notes,
			client.groomer,
			client.client_comment,
		])

		const csvContent = [
			headers.join(","),
			...rows.map((row) => row.join(",")),
		].join("\n")

		return csvContent
	}

	const downloadCSV = () => {
		const csvContent = convertToCSV(clients)
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
		saveAs(blob, `clients_list_${city}.csv`)
	}

	return (
		<div className="mt-12">
			<h3 className="text-center">
				Clients that have not come in a time frame
			</h3>
			<hr />
			<div className="grid grid-cols-1 md:grid-cols-4 gap-4">
				<div>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="start"
					>
						Timeframe Start (Months Ago)
					</label>
					<input
						defaultValue={start}
						onChange={(e) => setStart(Number(e.target.value))}
						id="start"
						type="number"
						placeholder="e.g., 3"
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
					/>
					<p className="text-xs text-gray-500">
						Clients last visited more than this number of months ago
					</p>
				</div>

				<div>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="end"
					>
						Timeframe End (Months Ago)
					</label>
					<input
						defaultValue={end}
						onChange={(e) => setEnd(Number(e.target.value))}
						id="end"
						type="number"
						placeholder="e.g., 12"
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
					/>
					<p className="text-xs text-gray-500">
						Clients last visited less than this number of months ago
					</p>
				</div>

				<div>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="city"
					>
						Select City
					</label>
					<Select
						options={cityOptions}
						onChange={(value) => setCity(value.value)}
					/>
				</div>

				<div className="flex">
					<button
						onClick={getClientsList}
						className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
					>
						Generate List
					</button>
				</div>
			</div>
			<hr />

			{isLoading ? (
				<div className="flex justify-center mt-16">
					<ThreeDots
						height="18"
						width="60"
						radius="9"
						color="#65BFF8"
						ariaLabel="three-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			) : clients.length > 0 ? (
				<div className="mt-8 overflow-x-auto">
					<button
						onClick={downloadCSV}
						className="bg-slate-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
					>
						Download CSV
					</button>
					<table className="min-w-full bg-white border">
						<thead>
							<tr className="bg-gray-200 text-gray-700">
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("client_id")}
								>
									Client ID {getSortIcon("client_id")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("last_visit")}
								>
									Last Visit {getSortIcon("last_visit")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("client_name")}
								>
									Client Full Name {getSortIcon("client_name")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("primaryPhoneNumber")}
								>
									Phone Number {getSortIcon("primaryPhoneNumber")}
								</th>
								<th className="py-2 px-4 border">Pet Name</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("pet_breed")}
								>
									Breed {getSortIcon("pet_breed")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("pet_type")}
								>
									Type {getSortIcon("pet_type")}
								</th>
								<th className="py-2 px-4 border">Pet Notes</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("groomer")}
								>
									Last Groomer {getSortIcon("groomer")}
								</th>
								<th
									className="py-2 px-4 border cursor-pointer"
									onClick={() => sortData("client_comment")}
								>
									Comment {getSortIcon("client_comment")}
								</th>
							</tr>
						</thead>
						<tbody>
							{clients.map((client, index) => (
								<tr key={index} className="text-left">
									<td className="py-2 px-4 border">
										<Link
											target="__blank"
											to={`/clients/${city}/${client.client_id}`}
										>
											{client.client_id}
										</Link>
									</td>
									<td className="py-2 px-4 border">{client.last_visit}</td>
									<td className="py-2 px-4 border capitalize">
										{client.client_name} {client.client_last_name}
									</td>
									<td className="py-2 px-4 border">
										{client.primaryPhoneNumber}
									</td>
									<td className="py-2 px-4 border capitalize">
										{client.pet_name}
									</td>
									<td className="py-2 px-4 border capitalize">
										{client.pet_breed}
									</td>
									<td className="py-2 px-4 border capitalize">
										{client.pet_type}
									</td>
									<td
										title={client.pet_notes}
										className="py-2 px-4 border overflow-hidden truncate whitespace-nowrap max-w-[300px]"
									>
										{client.pet_notes}
									</td>
									<td className="py-2 px-4 border capitalize">
										{client.groomer}
									</td>
									<td
										title={client.client_comment}
										className="py-2 px-4 border overflow-hidden truncate whitespace-nowrap max-w-[300px]"
									>
										{client.client_comment}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="text-center">No results yet</div>
			)}
		</div>
	)
}
