import React from "react";
import { CustomCard } from "./CustomCard";

const ReviewCard = ({ review, className }) => {
  return (
    <CustomCard className={className}>
      <a
        className="w-full h-full flex flex-col gap-[20px] hover:no-underline"
        href="https://www.google.com/localservices/prolist?g2lbs=AP8S6ENc0HB6t9g0vTLx2FdPg7xQMFw6pL3v1PqmsYoz_tMbakLXpI9NVZVeX48-FEjL-L7jwKelw72_V7MkznjrCpbn6bNj6aAet_h9KV1nxSkNtR9Kjoyz8di4SdwmefZDVRCKTAd4&hl=es-419&gl=ca&cs=1&ssta=1&q=amazing%20pet%20grooming&oq=amazing%20pet%20grooming&slp=MgA6HENoTUk1c3VhM01uc193SVZhZ096QUIzYlFRem9SAggCYAB6iAJDaFJoYldGNmFXNW5JSEJsZENCbmNtOXZiV2x1WjBqdmtQdmQ1NENBZ0FoYU1CQUFFQUVRQWhnQUdBRVlBaUlVWVcxaGVtbHVaeUJ3WlhRZ1ozSnZiMjFwYm1jcUNBZ0NFQUFRQVJBQ01nSmxicElCQzNCbGRGOW5jbTl2YldWeW1nRWpRMmhhUkZOVmFFNU5Semx1VXpCV1NsRXdSbTVUVlZKV1pHMVNVRmxXV2pORlFVV3FBVDBRQVRJZkVBRWlHeFhHUXdBYi1ZQ1pvRVZYaTM0VW90S083OWZ2TGN2MlVCYkIyRElZRUFJaUZHRnRZWHBwYm1jZ2NHVjBJR2R5YjI5dGFXNW6SATUKCy9nLzF0c3IzdmxnCg0vZy8xMXJyOGYxdmJrCgsvZy8xdHl3eWwzaBIEEgIIARIECgIIAQ%3D%3D&src=2&spp=CgsvZy8xdHl3eWwzaDrgAVdqQVFBQkFCRUFJWUFCZ0JHQUlpRkdGdFlYcHBibWNnY0dWMElHZHliMjl0YVc1bktnZ0lBaEFBRUFFUUFqSUNaVzZTQVF0d1pYUmZaM0p2YjIxbGNwb0JJME5vV2tSVFZXaE9UVWM1YmxNd1ZrcFJNRVp1VTFWU1ZtUnRVbEJaVmxvelJVRkZxZ0U5RUFFeUh4QUJJaHNWeGtNQUdfbUFtYUJGVjR0LUZLTFNqdV9YN3kzTDlsQVd3ZGd5R0JBQ0loUmhiV0Y2YVc1bklIQmxkQ0JuY205dmJXbHVadz09&serdesk=1&lrlstt=1688182460376&ved=2ahUKEwj96pLcyez_AhUvmokEHQxVASkQvS56BAhEEAE&scp=ChBnY2lkOnBldF9ncm9vbWVyEkMSEgkRK8dtzfUriBEDUlvs2iDp0SIVS2l0Y2hlbmVyLCBPTiBOMk4gM0I4KhQN3mTjGRXQMP_PHWC94xklZen_zzABGhRhbWF6aW5nIHBldCBncm9vbWluZyIUYW1hemluZyBwZXQgZ3Jvb21pbmcqI0VzcGVjaWFsaXN0YSBlbiBlbCBhc2VvIGRlIG1hc2NvdGFz#ts=3"
        target="_blank"
      >
        <div className="w-full flex gap-[10px] items-center justify-start">
          <img
            className="w-[50px] h-[50px] rounded-full bg-red-100"
            src={review.image}
          />
          <div className="flex flex-col gap-[0px] justify-center">
            <p className="text-black font-medium mb-0">{review.name}</p>
            <p className="mb-0">⭐️⭐️⭐️⭐️⭐️</p>
          </div>
        </div>
        <p className="text-black">{review.review}</p>
      </a>
    </CustomCard>
  );
};

export default ReviewCard;
