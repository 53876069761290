import { useContext } from "react"
import { RegistrationContext } from "../../../contexts/registrationStatusContext"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import DaycareBookingForm from "../../DaycareBookingForm"

export default function Index() {
	const { showBookTrialDayModal, setShowBookTrialDayModal } =
		useContext(RegistrationContext)
	return (
		<Modal
			modalClassName="mt-14"
			isOpen={showBookTrialDayModal}
			toggle={() => setShowBookTrialDayModal(!showBookTrialDayModal)}
			style={{ maxHeight: "90vh", overflowY: "auto" }}
		>
			<ModalHeader
				toggle={() => setShowBookTrialDayModal(!showBookTrialDayModal)}
			></ModalHeader>
			<ModalBody
				style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto" }}
			>
				<DaycareBookingForm appointmentType="free_trial" />
			</ModalBody>
		</Modal>
	)
}
