import React, { useEffect, useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { RegistrationContext } from "../../contexts/registrationStatusContext"

export default function Purchase() {
	const [searchParams, setSearchParams] = useSearchParams()

	const { setCart } = useContext(RegistrationContext)

	useEffect(() => {
		const paymentSuccess = searchParams.get("payment-success")
		if (paymentSuccess === "true") {
			setCart([])
		}
	}, [])
	return (
		<div className="mt-40 text-center">
			<h3>
				{searchParams.get("payment-success") === "true" ? "Success" : "Failure"}{" "}
			</h3>
			{searchParams.get("payment-success") !== "true" && (
				<h4>Something wrong happened.</h4>
			)}

			{searchParams.get("payment-success") === "true" ? (
				<div>
					<h4 className="text-center">Check your email for more details.</h4>
					<div className="flex justify-center">
						<img alt="success" src="/images/success_purchase.gif" />
					</div>
				</div>
			) : (
				<div className="flex justify-center">
					<img alt="failure" src="/images/failure_purchase.png" />
				</div>
			)}
		</div>
	)
}
