export const optionsDaycare = [
	{
		value: "morning",
		label: "Morning Session",
	},
	{
		value: "afternoon",
		label: "Afternoon Session",
	},
]
