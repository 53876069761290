import React from "react"

export default function ReleaseDaycareForm_Content() {
	return (
		<div>
			<p className="mb-4">
				Amazing Pet Grooming agrees to exercise due diligence and reasonable
				care, and to keep the premises sanitary and properly enclosed. All dogs
				are handled or cared for by the DA (Dog assistants) without liability on
				Amazing Pet Grooming’s part for loss or damage from disease, theft,
				fire, death, escape, injury, or harm to persons or other dogs or
				property by said dog(s), or from other unavoidable causes, due diligence
				and care having been exercised. Should any dog(s) become ill or seem to
				be in need of medical attention, Amazing Pet Grooming reserves the right
				to administer aid and/or to use any available veterinarian. All expenses
				so incurred shall be paid by the owner of said dog(s) in addition to any
				other applicable fees. Owner agrees to pay the rate for service in
				effect, on the date and time the dog is checked into our Amazing Pet
				Grooming daycare. Prices are subject to change without notice.
			</p>

			<p className="mb-4">
				Late pick-up fees will be applied for pick up after 7:00 pm on the day
				of the scheduled reservation at a rate of 0.50 cents per minute for the
				first 15 minutes and $1.00 per minute for all minutes thereafter. Should
				my dog not be picked up at the specified time, I authorize Amazing Pet
				Grooming to take whatever action Amazing Pet Grooming deems necessary
				for my dog, and I will pay all costs and charges thereof. Owner shall
				remain liable for all daycare bills and other applicable charges accrued
				in the care and maintenance of the dog listed in this contract. The
				owner further agrees to be solely responsible for any and all acts or
				behavior of said dog while in the care of Amazing Pet Grooming. The
				owner of the pet agrees to pay any and all attorney fees incurred by
				Amazing Pet Grooming in the collections of the dog.
			</p>

			<p className="mb-4">
				Owner shall report any and all incidents of aggression before the dog is
				dropped off for daycare. The dog must have at least two sets of shots
				before entering the daycare. Valid proof of vaccinations must be present
				(or in our system) before the pet can be dropped off for daycare. Owner
				also agrees to maintain vaccinations and to keep the Amazing Pet
				Grooming DA (Dog Assistants) staff up to date on any changes. Pets
				arriving with fleas/ticks will not be accepted. Sick and/or injured dogs
				will not be accepted unless previous arrangements have been made.
				Amazing Pet Grooming reserves the right to refuse any dog.
			</p>

			<p className="mb-4">
				Pinch collars, choke chains, and shock equipment are not permitted on
				the premises. Owner agrees that the dog will be picked up by the owner
				or another pre-approved person by 7:00 pm weekdays, and by 5:00 pm
				Saturday, unless a prior extension is agreed to before dropping the pet
				off for daycare. Late charges will be executed at the manager’s
				discretion as per above. There is a strict NO REFUND POLICY once payment
				has been received by Amazing Pet Grooming.
			</p>

			<p className="mb-4">
				My dog may be videotaped, photographed, and recorded when on Amazing Pet
				Grooming’s premises, and Amazing Pet Grooming shall be the exclusive
				owner of the results and proceeds of such taping, photography, and
				recordings with the rights, throughout the world, of unlimited use, and
				to copyright, to use and license in any manner.
			</p>
		</div>
	)
}
