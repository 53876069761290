import React from "react"
import SearchClients from "../../components/SearchClients"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import { Link } from "react-router-dom"

const Index = (props) => {
	const columns = [
		{
			dataField: "id",
			text: "ID",
			sort: true,
			order: "asc", // or desc
			headerStyle: (colum, colIndex) => {
				let widthColumn
				if (window.innerWidth < 451) {
					widthColumn = "40px"
				} else {
					widthColumn = "80px"
				}
				return { width: widthColumn, textAlign: "center" }
			},
		},
		{
			dataField: "lastName",
			text: "Last Name",
			sort: true,
		},
		{
			dataField: "firstName",
			text: "First Name",
			sort: true,
		},
		{
			dataField: "primaryPhoneNumber",
			text: "Primary phone",
			sort: true,
		},
		{
			dataField: "cellphone",
			text: "Cell",
			sort: true,
		},

		{
			dataField: "PetCambridges",
			text: "Pets",
			formatter: (cell) => {
				return cell.map((pet) => {
					return (
						<ul className="pets-in-table" key={pet.id}>
							<li>{pet.name}</li>
							<span>{pet.breed}</span>
						</ul>
					)
				})
			},
			sort: true,
		},
		{
			dataField: "actions",
			text: "Actions",
			isDummyField: true,
			csvExport: false,
			formatter: (cell, row) => {
				return (
					<div>
						<Link
							className="btn btn-info btn-table-clients"
							to={`/clients/cambridge/${row.id}`}
						>
							More Info
						</Link>
						<button
							className="btn btn-danger btn-table-clients"
							onClick={() => {
								props.handleDeleteClient(row.id)
							}}
						>
							Delete
						</button>
					</div>
				)
			},
		},
	]

	const defaultSortedBy = [
		{
			dataField: "id",
			order: "desc", // or desc
		},
	]

	return (
		<>
			<br />
			{props.showClientsCambridgeTable ? (
				<div className="react-table-component">
					{props.loadingClientsCambridgeTable ? (
						<div>
							<h3>
								Loading Clients... <i className="fa fa-refresh fa-spin "></i>
							</h3>
						</div>
					) : (
						<div className="table-responsive">
							<SearchClients city={props.city} />
							<BootstrapTable
								className="react-table-component"
								keyField="id"
								data={props.clientsCambridge}
								columns={columns}
								pagination={paginationFactory()}
								defaultSorted={defaultSortedBy}
								striped
								wrapperClasses="table-responsive"
							/>
						</div>
					)}
				</div>
			) : null}
		</>
	)
}

export default Index
