export let optionsDogSize = [
	{
		value: "appRequest",
		label: "Small",
	},
	{
		value: "mediumDogRequest",
		label: "Medium",
	},
	{
		value: "bigDogRequest",
		label: "Large",
	},
	{
		value: "bigDogRequest",
		label: "X-Large",
	},
]

export let optionsGroomersCambridge = [
	{
		value: "Paola",
		label: "Paola",
	},
	{
		value: "Claudia",
		label: "Claudia",
	},
	{
		value: "Adriana",
		label: "Adriana",
	},
	{
		value: "Peppa",
		label: "Peppa",
	},
	{
		value: "Mercedes",
		label: "Mercedes",
	},
]

export let optionsGroomers = [
	{
		value: "Paola",
		label: "Paola",
	},
	{
		value: "Claudia",
		label: "Claudia",
	},
	{
		value: "Adriana",
		label: "Adriana",
	},
	{
		value: "Diana",
		label: "Diana",
	},
	{
		value: "Peppa",
		label: "Peppa",
	},
	{
		value: "Mercedes",
		label: "Mercedes",
	},
	{
		value: "Frank",
		label: "Frank",
	},
	{
		value: "Baths",
		label: "Baths",
	},
]
export let optionsCatGroomers = [
	{
		value: "Paula",
		label: "Paula",
	},
]

export let optionsBathers = [
	{
		value: "Tristen",
		label: "Tristen",
	},
	{
		value: "Nicole",
		label: "Nicole",
	},
]

export let optionsIsNewClient = [
	{
		value: "Yes",
		label: "Yes",
	},
	{
		value: "No",
		label: "No",
	},
]

export let optionsDogServices = [
	{
		value: "Premium Pkg",
		label: "Premium Pkg (bath, full-haircut, nails, ears)",
	},
	{
		value: "Tidy Up",
		label: "Tidy Up Pkg (bath, slight trim, nails, ears)",
	},
]

export let optionsDogBath = [
	{
		value: "nails",
		label: "Nail trim",
	},
	{
		value: "bathSmallDog",
		label: "Bath pkg (bath, brush, nails, ears)",
	},
	{
		value: "puppyPkg",
		label:
			"Puppy pkg 5-month-old and under (bath, brush, nails, ears, face/paws-trimmed, no haircut)",
	},
]

export let optionsCatServices = [
	{
		value: "Cat Premium Pkg",
		label: "Cat Premium Pkg (bath, full-haircut, nails, ears)",
		image: "/images/premium-icon.png",
	},
	{
		value: "Cat Haircut Pkg",
		label: "Cat Haircut Pkg (Full-haircut, nails, ears)",
		image: "/images/grooming-icon.png",
	},
	{
		value: "Cat Bath Pkg",
		label: "Cat Bath Pkg (Bath, nails, ears)",
		image: "/images/bath-icon.png",
	},
]
