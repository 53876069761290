import React, { useState, useEffect } from "react"
import axios from "axios"
import { teamMembers } from "../../utils/constants"

const DiscountList = () => {
	const [discounts, setDiscounts] = useState([])
	const [editingId, setEditingId] = useState(null)
	const [formData, setFormData] = useState({})

	// Fetch discounts from API
	const fetchDiscounts = async () => {
		try {
			const token = localStorage.getItem("JWT")
			const response = await axios.get("/api/discounts", {
				headers: { jwt: `${token}` },
			})
			setDiscounts(response.data)
		} catch (error) {
			console.error("Error fetching discounts:", error)
		}
	}

	useEffect(() => {
		fetchDiscounts()
	}, [])

	const handleEditClick = (discount) => {
		setEditingId(discount.id)
		setFormData(discount)
	}

	const handleChange = (e) => {
		const { name, value } = e.target
		setFormData((prevData) => ({ ...prevData, [name]: value }))
	}

	const handleSaveClick = async () => {
		try {
			const token = localStorage.getItem("JWT")

			await axios.put(`/api/discounts/${editingId}`, formData, {
				headers: { jwt: `${token}` },
			})
			setDiscounts((prevDiscounts) =>
				prevDiscounts.map((discount) =>
					discount.id === editingId ? formData : discount
				)
			)
			setEditingId(null)
		} catch (error) {
			console.error("Error updating discount:", error)
		}
	}

	const handleCancelClick = () => {
		setEditingId(null)
		setFormData({})
	}

	return (
		<div className="bg-white shadow-md rounded-lg mx-auto">
			<h2 className="text-2xl font-bold mb-6 text-center">Discounts</h2>

			<div className="overflow-x-auto">
				<table className="min-w-full table-fixed bg-white border border-gray-300">
					<thead>
						<tr>
							<th className="px-4 py-2 border-b">Name</th>
							<th className="px-4 py-2 border-b w-20">Code</th>{" "}
							{/* Smaller width for Code */}
							<th className="px-4 py-2 border-b">Type</th>
							<th className="px-4 py-2 border-b">Value</th>
							<th className="px-4 py-2 border-b">Status</th>
							<th className="px-4 py-2 border-b">Service Type</th>
							<th className="px-4 py-2 border-b">Staff</th>
							<th className="px-4 py-2 border-b w-20">Location</th>{" "}
							{/* Smaller width for Location */}
							<th className="px-4 py-2 border-b">
								Expire Date <span className="text-xs">(YYYY-MM-DD)</span>{" "}
							</th>
							<th className="px-4 py-2 border-b">Actions</th>
						</tr>
					</thead>
					<tbody>
						{discounts.map((discount) => (
							<tr key={discount.id} className="text-center">
								<td className="px-4 py-2 border-b">
									{editingId === discount.id ? (
										<input
											type="text"
											name="name"
											value={formData.name}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										/>
									) : (
										discount.name
									)}
								</td>
								<td className="px-4 py-2 border-b w-20">
									{editingId === discount.id ? (
										<input
											type="text"
											name="code"
											value={formData.code}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										/>
									) : (
										discount.code
									)}
								</td>
								<td className="px-4 py-2 border-b">
									{editingId === discount.id ? (
										<select
											name="type"
											value={formData.type}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										>
											<option value="percent">Percent</option>
											<option value="amount">Amount</option>
										</select>
									) : (
										discount.type
									)}
								</td>
								<td className="px-4 py-2 border-b">
									{editingId === discount.id ? (
										<input
											type="number"
											name="value"
											value={formData.value}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										/>
									) : (
										discount.value
									)}
								</td>
								<td className="px-4 py-2 border-b">
									{editingId === discount.id ? (
										<select
											name="status"
											value={formData.status}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										>
											<option value="active">Active</option>
											<option value="inactive">Inactive</option>
										</select>
									) : (
										discount.status
									)}
								</td>
								<td className="px-4 py-2 border-b">
									{editingId === discount.id ? (
										<select
											name="serviceType"
											value={formData.serviceType}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										>
											<option value="all">All Services</option>
											<option value="daycare">Daycare</option>
											<option value="dogBath">Dog Bath</option>
											<option value="dogGrooming">Dog Grooming</option>
											<option value="catBath">Cat Bath</option>
											<option value="catGrooming">Cat Grooming</option>
										</select>
									) : (
										discount.serviceType
									)}
								</td>
								<td className="px-4 py-2 border-b">
									{editingId === discount.id ? (
										<select
											name="serviceToBeProvidedBy"
											value={formData.serviceToBeProvidedBy}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										>
											<option value={"any"}>Any</option>
											{Object.values(teamMembers).map(
												(member, index) =>
													member.description && (
														<option key={index} value={member.name}>
															{member.name}
														</option>
													)
											)}
										</select>
									) : (
										discount.serviceToBeProvidedBy
									)}
								</td>
								<td className="px-4 py-2 border-b w-20">
									{editingId === discount.id ? (
										<select
											name="location"
											value={formData.location}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										>
											<option value="all">All</option>
											<option value="milton">Milton</option>
											<option value="cambridge">Cambridge</option>
										</select>
									) : (
										discount.location
									)}
								</td>
								<td className="px-4 py-2 border-b">
									{editingId === discount.id ? (
										<input
											type="date"
											name="expireDate"
											value={formData.expireDate}
											onChange={handleChange}
											className="p-2 border border-gray-300 rounded"
										/>
									) : (
										discount.expireDate
									)}
								</td>
								<td className="px-4 py-2 border-b">
									{editingId === discount.id ? (
										<>
											<button
												onClick={handleSaveClick}
												className="text-green-500 mr-2"
											>
												Save
											</button>
											<button
												onClick={handleCancelClick}
												className="text-red-500"
											>
												Cancel
											</button>
										</>
									) : (
										<button
											onClick={() => handleEditClick(discount)}
											className="text-blue-500 hover:underline"
										>
											Edit
										</button>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default DiscountList
