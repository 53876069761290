import axios from "axios"
import moment from "moment"
import { useEffect, useState } from "react"
import Select from "react-select"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { daycarePassOptions } from "../../utils/constants"
import { v4 as uuidv4 } from "uuid"
import { FaPen, FaSave } from "react-icons/fa"

const NO_VISITS_LEFT = "No visits left"
const PASS_EXPIRED = "Pass Expired"
const ACTIVE = "Active"

export default function ClientAccount({ refetchData, data }) {
	const [selectedCoupon, setSelectedCoupon] = useState(null)
	const [modalToSaleMembership, setModalToSaleMembership] = useState(false)
	const [pass, setPass] = useState("")
	const [updatedDate, setUpdatedDate] = useState("")
	const [visits, setVisits] = useState([])
	const [coupons, setCoupons] = useState([])
	const [editingVisitsLeftId, setEditingVisitsLeftId] = useState(null)
	const [tempVisitsLeft, setTempVisitsLeft] = useState("")
	const accessString = localStorage.getItem("JWT")
	const JOBTYPE = localStorage.getItem("JOBTYPE")

	useEffect(() => {
		getClientDaycareVisits()
		getAllCoupons()
	}, [])

	const salePassHandler = async () => {
		if (!pass.value) {
			return alert("Select a pass first!")
		}
		const passObj = {
			name: pass.value,
			type: "pass",
			purchaseSource: "in-person",
			value: pass.cost,
			balance: 0,
			originalNumOfVisits: pass.numOfVisits,
			numOfVisitsLeft: pass.numOfVisits,
			orderNumber: uuidv4(),
			expireDate: moment().add(1, "year"),
			updatedDate: moment(),
			createdDate: moment(),
			ClientId: data.id,
			purchasedAt: pass.cost,
		}
		if (selectedCoupon && pass.value) {
			passObj.discountName = selectedCoupon.name
			passObj.purchasedAt =
				selectedCoupon.type === "amount"
					? pass.cost - selectedCoupon.value
					: pass.cost - (pass.cost * selectedCoupon.value) / 100
		}

		if (window.confirm("Do you confirm the Daycare Pass Sale")) {
			await axios
				.post(`/api/membership/${data.id}/milton`, passObj)
				.then(() => {
					refetchData()
					setSelectedCoupon(null)
					setModalToSaleMembership(false)
				})
				.catch((err) => console.log(err))
		}
	}

	const getClientDaycareVisits = async () => {
		const allVisits = await axios.get(
			`/auth/api/all_daycareVisits_by_client_id/${data.id}`,
			{
				headers: { JWT: accessString },
			}
		)
		setVisits(allVisits)
	}

	const getAllCoupons = async () => {
		const allCoupons = await axios.get(`/api/discounts`, {
			headers: { JWT: accessString },
		})
		const filteredCoupons = allCoupons.data.filter(
			(item) =>
				item.status === "active" &&
				item.serviceType === "daycare" &&
				moment(item.expireDate).isAfter(new Date())
		)

		setCoupons(filteredCoupons)
	}

	const renderVisits =
		visits.data && visits.data.length > 0 ? (
			visits.data.map((daycareVisit) =>
				daycareVisit.groomerName === "daycare" ? (
					<div
						className="w-full p-2 border flex flex-col items-center"
						key={daycareVisit.id}
					>
						<p>Date: {moment(daycareVisit.date).format("YYYY-MM-DD")}</p>
						<p>Pet Name: {daycareVisit.nameBreed}</p>
					</div>
				) : null
			)
		) : (
			<div>No visits history available</div>
		)

	const updatePassDateHandler = async (id) => {
		if (!updatedDate) return

		const passObj = {
			expireDate: moment(updatedDate).format("YYYY-MM-DD 23:59:59"),
		}
		await axios.put(`/api/membership/${id}`, passObj)
		refetchData()
	}

	const updateVisitsLeftHandler = async (id) => {
		if (tempVisitsLeft === "") return

		const passObj = { numOfVisitsLeft: parseInt(tempVisitsLeft, 10) }
		await axios.put(`/api/membership/${id}`, passObj)
		refetchData()
		setEditingVisitsLeftId(null) // Reset editing state after save
	}

	return (
		<div>
			<h3>Client Account</h3>
			<div className="flex my-4 flex-row gap-3 justify-between">
				<button
					onClick={() => setModalToSaleMembership(true)}
					className="bg-cyan-950 py-2 text-xl w-1/2 text-white"
				>
					Sell a Pass or Membership
				</button>
			</div>
			<div className="flex gap-4 flex-row">
				<div className="w-2/3">
					<h5>Current Passes/Membership:</h5>
					<div className="flex gap-2 p-2 flex-col sm:flex-row justify-between flex-wrap">
						{data && data.Memberships.length ? (
							data.Memberships.map((pass) => (
								<div
									className={`flex-1 rounded-sm w-full sm:w-1/4 p-4 mt-4 ${
										pass.numOfVisitsLeft === 0 ||
										moment(pass.expireDate).isBefore(moment())
											? "border-8 border-red-500 "
											: "border-8 border-green-500 "
									}`}
									key={pass.id}
								>
									{JOBTYPE === "admin" && (
										<div>
											<span>Edit Exp. Date</span>
											<div className="flex gap-1 border mb-4 flex-row justify-between">
												<input
													onChange={(e) => setUpdatedDate(e.target.value)}
													type="date"
												/>
												<button
													onClick={() => updatePassDateHandler(pass.id)}
													className="bg-cyan-600 px-2 text-sm text-white"
												>
													Edit
												</button>
											</div>
										</div>
									)}
									<h5>
										Status:{" "}
										{pass.numOfVisitsLeft === 0
											? NO_VISITS_LEFT
											: moment().isBefore(pass.expireDate)
											? ACTIVE
											: PASS_EXPIRED}{" "}
										{pass.numOfVisitsLeft > 0 &&
											`Good until: ${moment(pass.expireDate).format(
												"YYYY-MM-DD"
											)}`}
									</h5>
									<p className="capitalize">Pass Name: {pass.name}</p>
									{pass.discountName && (
										<p className="capitalize">Discount: {pass.discountName}</p>
									)}
									<p className="capitalize">
										Orig. Value: ${pass.value.toFixed(2)}
									</p>
									<p className="capitalize">
										Client Paid (No tax): $
										{pass.purchasedAt
											? pass.purchasedAt.toFixed(2)
											: pass.value.toFixed(2)}
									</p>
									<p className="capitalize">
										Purchase Source: {pass.purchaseSource}
									</p>
									<p className="capitalize">
										Original Number of visits: {pass.originalNumOfVisits}
									</p>
									<p className="capitalize">
										Number of visits left:{" "}
										{JOBTYPE === "admin" ? (
											editingVisitsLeftId === pass.id ? (
												<div className="flex items-center gap-1">
													<input
														type="number"
														value={tempVisitsLeft}
														onChange={(e) => setTempVisitsLeft(e.target.value)}
														className="border p-1 w-16"
													/>
													<FaSave
														onClick={() => updateVisitsLeftHandler(pass.id)}
														className="cursor-pointer text-blue-500"
													/>
												</div>
											) : (
												<>
													{pass.numOfVisitsLeft}{" "}
													<FaPen
														onClick={() => {
															setEditingVisitsLeftId(pass.id)
															setTempVisitsLeft(pass.numOfVisitsLeft)
														}}
														className="cursor-pointer"
													/>
												</>
											)
										) : (
											<span>{pass.numOfVisitsLeft}</span>
										)}
									</p>
									<p className="capitalize">
										Purchase date:{" "}
										{moment(pass.createdDate).format("YYYY-MM-DD")}
									</p>
									<p className="capitalize">Order Number: {pass.orderNumber}</p>
								</div>
							))
						) : (
							<div>
								No Passes or Memberships have been purchased by this client
							</div>
						)}
					</div>
				</div>
				<div className="w-1/3 h-400 border overflow-y-auto">
					<h5 className="pl-2 pt-2">Daycare Visits History:</h5>
					<div
						style={{ height: "100vh", overflowY: "auto" }}
						className="flex gap-2 mt-4 p-2 flex-col sm:flex-row justify-between flex-wrap h-400 overflow-y-auto"
					>
						{renderVisits}
					</div>
				</div>
			</div>
			<Modal
				size="lg"
				isOpen={modalToSaleMembership}
				toggle={() => setModalToSaleMembership(!modalToSaleMembership)}
			>
				<ModalHeader
					toggle={() => setModalToSaleMembership(!modalToSaleMembership)}
				>
					Pass or Membership sale
				</ModalHeader>
				<ModalBody>
					<Select
						onChange={(value) => setPass(value)}
						options={daycarePassOptions}
					/>
					<hr />
					<h6 className="mt-2 text-center  capitalize">
						Selected Coupon:{" "}
						<span
							className={`text-green-500 ${selectedCoupon ? "border" : ""} p-2`}
						>
							{selectedCoupon &&
								`${selectedCoupon.code} - ${
									selectedCoupon.type === "amount" ? "$" : ""
								}${selectedCoupon.value}${
									selectedCoupon.type === "percent" ? "%" : ""
								} OFF`}
							{selectedCoupon && (
								<span
									onClick={() => setSelectedCoupon(null)}
									className="ml-4 cursor-pointer text-sm text-red-500 border p-1"
								>
									Remove
								</span>
							)}
						</span>
					</h6>
					<hr />
					<h6 className="text-center">Available coupons for Daycare:</h6>
					{coupons.length > 0 ? (
						coupons.map((coupon) => (
							<button
								onClick={() => {
									if (pass) {
										setSelectedCoupon({
											name: coupon.name,
											type: coupon.type,
											value: coupon.value,
											code: coupon.code,
										})
									} else {
										alert("Select a Pass first!")
									}
								}}
								className="my-1 text-center border-dashed border-2 border-indigo-600 capitalize block bg-yellow-300 px-2 rounded-sm text-indigo-600 w-full"
								key={coupon.id}
							>
								{`Name: ${coupon.name}, Type: ${coupon.type} - Value: ${coupon.value} Exp: ${coupon.expireDate}`}
							</button>
						))
					) : (
						<p className="mt-2 text-center">No coupons available</p>
					)}
					<button
						className="btn btn-info mt-2 btn-block"
						onClick={salePassHandler}
					>
						Confirm Sale
					</button>
				</ModalBody>
			</Modal>
		</div>
	)
}
