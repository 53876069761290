import { useState, useEffect } from "react"
import axios from "axios"

export default function UpdatePasswordForm({
	isPasswordTemporary,
	email,
	toggleModalToUpdateOwner,
	setShowPasswordChangeForm,
	modalToUpdateOwner,
	setShowBackButton,
	firstName,
	mode,
}) {
	const [oldPassword, setOldPassword] = useState("")
	const [errorMessage, setErrorMessage] = useState("")
	const [showNewPasswordFields, setShowNewPasswordFields] = useState(false)
	const [newPassword, setNewPassword] = useState("")
	const [newPassword2, setNewPassword2] = useState("")
	const [showPassword1, setShowPassword1] = useState(false)
	const [showPassword2, setShowPassword2] = useState(false)

	useEffect(() => {
		if (!modalToUpdateOwner) {
			setOldPassword("")
			setErrorMessage("")
			setShowBackButton(true)
			setShowNewPasswordFields(false)
			setShowPasswordChangeForm(false)
		}
	}, [modalToUpdateOwner])

	const confirmOldPasswordHandler = async (e) => {
		e.preventDefault()
		if (!oldPassword) {
			return setErrorMessage("Enter your old password")
		}
		await axios
			.post("/api/verifyOldPassword", {
				email,
				password: oldPassword,
			})
			.then(() => {
				setOldPassword("")
				setErrorMessage("")
				setShowBackButton(false)
				setShowNewPasswordFields(true)
			})
			.catch((err) => {
				console.log(err.response.data.error)
				setErrorMessage(err.response.data.error)
			})
	}

	const saveNewPasswordHandler = async (e) => {
		e.preventDefault()
		const userToUpdate = {
			email,
			password: newPassword,
			password2: newPassword2,
			firstName,
		}
		await axios
			.post("/api/updatePassword", userToUpdate)
			.then(() => {
				setOldPassword("")
				setNewPassword("")
				setNewPassword2("")
				setShowNewPasswordFields(false)
				setShowPasswordChangeForm(false)
				setShowBackButton(true)
				toggleModalToUpdateOwner(false)
				alert("Your password has been updated.")
			})
			.catch((err) => {
				console.log(err)
				setErrorMessage(err.response.data.error)
			})
	}

	return (
		<div>
			{showNewPasswordFields ? (
				<form onSubmit={saveNewPasswordHandler} className="mt-4">
					<label htmlFor="user_name">New Password:</label>
					<div className="relative">
						<input
							value={showNewPasswordFields && newPassword ? newPassword : ""}
							className="py-2 pl-4 pr-12 w-full text-black border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
							type={showPassword1 ? "text" : "password"}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
						<span
							onClick={() => setShowPassword1(!showPassword1)}
							className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
						>
							{showPassword1 ? "Hide" : "Show"}
						</span>
					</div>
					<label className="mt-2" htmlFor="user_name">
						Confirm New Password:
					</label>
					<div className="relative">
						<input
							value={
								showNewPasswordFields && newPassword2 ? newPassword2 || "" : ""
							}
							className="py-2 pl-4 pr-12 w-full text-black border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
							type={showPassword2 ? "text" : "password"}
							onChange={(e) => setNewPassword2(e.target.value)}
						/>
						<span
							onClick={() => setShowPassword2(!showPassword2)}
							className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
						>
							{showPassword2 ? "Hide" : "Show"}
						</span>
					</div>
					<p className="error-message-registration mt-4 text-center">
						{errorMessage}
					</p>
					<button className="w-full my-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
						Submit Changes
					</button>
				</form>
			) : (
				<form onSubmit={confirmOldPasswordHandler}>
					<h5 className="mt-4">
						Enter your {isPasswordTemporary ? "temporary" : "old"} password
					</h5>
					<label htmlFor="user_name">Old Password:</label>
					<div className="relative">
						<input
							className="py-2 pl-4 pr-12 w-full text-black border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
							type={"text"}
							onChange={(e) => setOldPassword(e.target.value)}
						/>
					</div>
					<p className="error-message-registration mt-4 text-center">
						{errorMessage}
					</p>
					<button className="w-full my-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
						Verify
					</button>
				</form>
			)}
		</div>
	)
}
