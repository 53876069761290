import React from "react"
import Select from "react-select"

const PaymentMethodSelector = ({
	onSelectPaymentMethod,
	selectedOption,
	setSelectedOption,
}) => {
	const paymentOptions = [
		{ value: "debit_credit", label: "Debit / Credit Card" },
		{ value: "cash", label: "Cash" },
		{ value: "gift_card", label: "Gift Card (Purchased online)" },
		{ value: "gift_card_in_person", label: "Gift Card (Purchased in person)" },
		{ value: "e_transfer", label: "E-Transfer" },
	]

	const handleChange = (selected) => {
		setSelectedOption(selected)
		onSelectPaymentMethod && onSelectPaymentMethod(selected.value)
	}

	return (
		<div className="max-w-md mx-auto">
			<h3 className="text-xl font-semibold text-gray-800">
				Choose Payment Method
			</h3>
			<Select
				options={paymentOptions}
				value={selectedOption}
				onChange={handleChange}
				placeholder="Select payment method"
				className="react-select-container"
				classNamePrefix="react-select"
			/>
			{selectedOption && (
				<p className="mt-4 text-gray-700">
					Payment Method Selected: <strong>{selectedOption.label}</strong>
				</p>
			)}
		</div>
	)
}

export default PaymentMethodSelector
