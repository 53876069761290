import React from "react"
import BookingCard from "../BookingCard"

const City = ({ onClickMilton, onClickCambridge }) => {
	return (
		<>
			{/* Form question */}
			<h5 className="text-black font-black text-[30px] text-center md:text-[35px]">
				Select your city:
			</h5>

			{/* Form choises */}
			<div className="w-full h-full flex flex-col gap-[30px]">
				<BookingCard
					image="/images/house-icon.png"
					title="Milton, ON."
					description="📍 264 Bronte St S, Unit 15. - Milton, ON - L9T 5A3."
					onClick={onClickMilton}
				/>
				<BookingCard
					image="/images/house-icon.png"
					title="Cambridge, ON."
					description="📍 383 Elgin St N. Unit #1 - Cambridge, ON - N1R 8CI."
					onClick={onClickCambridge}
				/>
			</div>
		</>
	)
}

export default City
