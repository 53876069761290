import React, { useState, useEffect } from "react";
import "./style.scss";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-scroll";
import moment from "moment";
import axios from "axios";
import Select from "react-select";

let petTypeOptions = [
	{
		value: "Dog",
		label: "Dog",
	},
	{
		value: "Cat",
		label: "Cat",
	},
];

const GROOMERS_TYPES = [
	"groomer1",
	"groomer2",
	"groomer3",
	"groomer4",
	"groomer5",
	"groomer6",
	"groomer7",
];

const Index = ({ fetchWaitlist, closeModal }) => {
	const [city, setCity] = useState("");
	const [ownerFullName, setOwnerFullName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [petType, setPetType] = useState("");
	const [petName, setPetName] = useState("");
	const [breed, setBreed] = useState("");
	const [groomerName, setGroomerName] = useState("");
	const [errMsg, setErrMsg] = useState("");
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [groomersListMilton, setGroomersListMilton] = useState([]);
	const [groomersListCambridge, setGroomersListCambridge] = useState([]);
	const accessString = localStorage.getItem("JWT");
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false);

	useEffect(() => {
		fetchGroomersNames();
		// eslint-disable-next-line
	}, []);

	const fetchGroomersNames = async () => {
		await axios
			.get("/auth/employees", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => {
				setLoadingAxiosReq(false);
				let onlyEmployeesMilton = res.data
					.filter(
						(user) =>
							user.location === "milton" &&
							GROOMERS_TYPES.includes(user.jobType)
					)
					.map((employee) => ({
						value: employee.firstName,
						label: employee.firstName.toUpperCase(),
					}));
				let onlyEmployeesCambridge = res.data
					.filter(
						(user) =>
							user.location === "cambridge" &&
							GROOMERS_TYPES.includes(user.jobType)
					)
					.map((employee) => ({
						value: employee.firstName,
						label: employee.firstName.toUpperCase(),
					}));
				setGroomersListMilton([
					...onlyEmployeesMilton,
					{ value: "paola", label: "PAOLA" },
					{ value: "any", label: "ANY" },
				]);
				setGroomersListCambridge([
					...onlyEmployeesCambridge,
					{ value: "paola", label: "PAOLA" },
					{ value: "any", label: "ANY" },
				]);
			})
			.catch((err) => {
				setLoadingAxiosReq(false);
				console.log(err);
			});
	};

	const onSelectedChanged = (value) => {
		setPetType(value);
	};
	const onSelectedChangedGroomer = (value) => {
		setGroomerName(value);
	};

	const submitWaitList = async (e) => {
		e.preventDefault();
		const waitListObj = {
			ownerFullName,
			phoneNumber,
			email,
			groomerName: groomerName.value,
			petType: petType.value,
			petName,
			breed,
			city,
			dateCreated: moment().format("YYYY-MM-DD"),
		};

		if (
			!waitListObj.ownerFullName ||
			!waitListObj.phoneNumber ||
			!waitListObj.petType ||
			!waitListObj.petName ||
			!waitListObj.breed ||
			!waitListObj.groomerName
		) {
			return setErrMsg("ALL THE FIELDS ARE REQUIRED!");
		} else {
			setBtnDisabled(true);
			await axios
				.post("/waitList/staff/add", waitListObj)
				.then(() => {
					fetchWaitlist();
					closeModal();
				})
				.catch((err) => {
					console.error(err);
					setBtnDisabled(false);
				});
		}
	};

	return (
		<div className="waiting-list-container">
			<div>
				{city ? null : (
					<h3 className="text-center mt-4">
						{" "}
						<strong>
							{" "}
							<i className="fas fa-location-arrow"></i> Choose Location
						</strong>{" "}
					</h3>
				)}
				<div className="waitList-btns-container mt-3">
					<Link
						className="link-btn-waitList"
						activeClass="active"
						to="waitListForm"
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
					>
						<button
							onClick={() => {
								setCity("Milton");
								setGroomerName("");
							}}
							className="city-btns-waitList city-btns-waitList__milton"
						>
							MILTON
						</button>
					</Link>
					<Link
						className="link-btn-waitList"
						activeClass="active"
						to="waitListForm"
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
					>
						<button
							onClick={() => {
								setCity("Cambridge");
								setGroomerName("");
							}}
							className="city-btns-waitList  city-btns-waitList__cambridge"
						>
							CAMBRIDGE
						</button>
					</Link>
				</div>
			</div>

			<div className="text-center mt-3">
				{city ? (
					<h3 className="text center">
						Selected Location: <strong>{city}</strong>{" "}
					</h3>
				) : null}
			</div>

			{!city ? null : (
				<div className="row">
					<div
						className={`col-lg-12 waitList-form-container waitList-form-container__${city.toLocaleLowerCase()}`}
					>
						<Form onSubmit={submitWaitList}>
							<h4 className="text-center text-light">
								Fill out the form <i className="fas fa-arrow-circle-down"></i>{" "}
								one form per pet <i className="fas fa-paw"></i>
							</h4>
							<hr className="hr-white" />
							<FormGroup>
								<Label className="text-light" for="petType">
									* Preferred Groomer
								</Label>
								{loadingAxiosReq ? (
									<div>Loading groomer's list</div>
								) : (
									<Select
										id="groomerName"
										value={groomerName}
										options={
											city === "Milton"
												? groomersListMilton
												: groomersListCambridge
										}
										placeholder={"* Select Groomer"}
										isSearchable={false}
										onChange={onSelectedChangedGroomer}
									/>
								)}
							</FormGroup>
							<h5 className="text-center text-light">
								<i className="fas fa-user"></i> Owner Info
							</h5>
							<h5 className="text-center text-light">
								{" "}
								<i className="fas fa-arrow-down"></i>
							</h5>
							<FormGroup>
								<Label className="text-light" for="ownerFullName">
									* Owner's Full Name
								</Label>
								<Input
									value={ownerFullName}
									onChange={(e) => setOwnerFullName(e.target.value)}
									type="text"
									id="ownerFullName"
									placeholder="Owner's Full Name"
								/>
							</FormGroup>
							<FormGroup>
								<Label className="text-light" for="phoneNumber">
									* Phone Number
								</Label>
								<Input
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
									type="text"
									id="phoneNumber"
									placeholder="Phone number"
								/>
							</FormGroup>
							<FormGroup>
								<Label className="text-light" for="email">
									Email
								</Label>
								<Input
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									type="email"
									id="email"
									placeholder="Email"
								/>
							</FormGroup>
							<h5 className="text-center text-light">
								<i className="fas fa-paw"></i> Pet Info
							</h5>
							<h5 className="text-center text-light">
								{" "}
								<i className="fas fa-arrow-down"></i>
							</h5>
							<FormGroup>
								<Label className="text-light" for="petType">
									* Pet Type
								</Label>
								<Select
									value={petType}
									options={petTypeOptions}
									placeholder={"Select Pet Type"}
									isSearchable={false}
									onChange={onSelectedChanged}
								/>
							</FormGroup>
							<FormGroup>
								<Label className="text-light" for="petName">
									* Pet Name
								</Label>
								<Input
									value={petName}
									onChange={(e) => setPetName(e.target.value)}
									type="text"
									id="petName"
									placeholder="Pet Name"
								/>
							</FormGroup>{" "}
							<br />
							<FormGroup>
								<Label className="text-light" for="breed">
									* Breed
								</Label>
								<Input
									value={breed}
									onChange={(e) => setBreed(e.target.value)}
									type="text"
									id="breed"
									placeholder="Breed"
								/>
							</FormGroup>{" "}
							<br />
							{errMsg ? (
								<p className="text-center errMsg-waitList-form">
									<strong>
										<i className="fas fa-times-circle"></i> {errMsg}
									</strong>{" "}
								</p>
							) : null}
							<div className="mt-3">
								<button
									className="btn btn-dark btn-block"
									disabled={btnDisabled}
								>
									{btnDisabled ? "Please Wait..." : "Submit"}
								</button>
							</div>
						</Form>
					</div>

					<div id="waitListForm" className="col-lg-3">
						&nbsp;
					</div>
				</div>
			)}
		</div>
	);
};

export default Index;
