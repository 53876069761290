import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./style.scss";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Waitinglist from "../../pages/WaitingList";
import Table from "../Table";

const Index = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);
	const [modal, setModal] = useState(false);
	const [waitListDataMilton, setWaitListDataMilton] = useState([]);
	const [waitListDataCambridge, setWaitListDataCambridge] = useState([]);
	const [city, setCity] = useState("Milton");

	const fetchAuth = async () => {
		try {
			const response = await axios.get("/waitList/all");
			if (response) {
				setWaitListDataMilton(
					response.data.filter((info) => info.city === "Milton")
				);
				setWaitListDataCambridge(
					response.data.filter((info) => info.city === "Cambridge")
				);
				setIsLoading(false);
				setError(false);
				return;
			} else {
				setIsLoading(false);
				setError(true);
			}
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(true);
		}
	};
	useEffect(() => {
		fetchAuth();
	}, []);

	const handleDeleteWaitlist = async (id) => {
		if (
			window.confirm(`Are you sure you wish to delete this client permanently?`)
		) {
			await axios
				.delete(`/waitlist/delete/${id}`)
				.then(() => fetchAuth())
				.catch((err) => console.log(err));
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: "WAITLIST",
				// Second group columns
				columns: [
					{
						Header: "Owner Name",
						accessor: "ownerFullName",
					},
					{
						Header: "Phone",
						accessor: "phoneNumber",
					},
					{
						Header: "Email",
						accessor: "email",
					},
					{
						Header: "Pet Type",
						accessor: "petType",
					},
					{
						Header: "Breed",
						accessor: "breed",
					},
					{
						Header: "Date added",
						accessor: "dateCreated",
					},
					{
						Header: "Preferred Groomer",
						accessor: "groomerName",
					},
					{
						Header: "Action",
						Cell: (tableProps) => {
							return (
								<button
									className="btn btn-danger"
									onClick={() => {
										handleDeleteWaitlist(tableProps.row.original.id);
									}}
								>
									Delete
								</button>
							);
						},
					},
				],
			},
		],
		[]
	);

	const defaultSortedBy = [
		{
			dataField: "id",
			order: "asc", // or desc
		},
	];

	if (isLoading) {
		return (
			<div className="mt-5">
				<h2 className="text-center text-light">Loading data... please wait</h2>
			</div>
		);
	} else if (error) {
		return (
			<div>
				<h3 className="text-center">
					Something went wrong, please try again later
				</h3>
			</div>
		);
	} else {
		return (
			<div className="waitList-admin-container">
				<button
					className="btn-city-waiting-list btn-city-waiting-list__milton"
					onClick={() => setCity("Milton")}
				>
					Milton
				</button>
				<button
					className="btn-city-waiting-list btn-city-waiting-list__cambridge"
					onClick={() => setCity("Cambridge")}
				>
					Cambridge
				</button>

				<div>
					<h5
						className={
							city === "Cambridge"
								? "text-center waitList-city-heading waitList-city-heading__cambridge"
								: "text-center waitList-city-heading waitList-city-heading__milton"
						}
					>
						{city === "Cambridge" ? (
							<span>
								Wait list Cambridge: Total <i className="fas fa-paw"></i>{" "}
								{waitListDataCambridge.length} Pets
							</span>
						) : (
							<span>
								Wait list Milton: Total <i className="fas fa-paw"></i>{" "}
								{waitListDataMilton.length} Pets
							</span>
						)}
					</h5>
					<button className="btn btn-warning" onClick={() => setModal(!modal)}>
						+ Add to waitlist
					</button>
					<Table
						columns={columns}
						data={
							city === "Cambridge" ? waitListDataCambridge : waitListDataMilton
						}
					/>
				</div>
				<Modal
					isOpen={modal}
					toggle={() => setModal(!modal)}
					modalClassName="modal-add-clients"
					size="xl"
				>
					<ModalHeader toggle={() => setModal(!modal)}>Waitlist</ModalHeader>
					<ModalBody>
						<Waitinglist
							closeModal={() => setModal(false)}
							fetchWaitlist={fetchAuth}
						/>
					</ModalBody>
				</Modal>
			</div>
		);
	}
};

export default Index;
