import React from "react"
import "./style.scss"
import BootstrapTable from "react-bootstrap-table-next"

const TableCommission = (props) => {
	const columns = [
		{
			dataField: "clientNumber",
			text: "Client #",
			// sort: true,
		},
		{
			dataField: "clientPhone",
			text: "Phone #",
			// sort: true,
		},
		{
			dataField: "nameBreed",
			text: "Pet Name - Breed",
			// sort: true,
		},
		{
			dataField: "arrivalTime",
			text: "Arrival Time",
			// sort: true,
		},
		{
			dataField: "pickupTime",
			text: "Pickup Time",
			// sort: true,
		},
		{
			dataField: "called",
			text: "Called",
			isDummyField: true,
			csvExport: false,
			formatter: (cell, row) => {
				return (
					<div className="text-center">
						{row.called}
						{props.loadingAxiosReq ? (
							<p>Wait...</p>
						) : (
							<button
								style={
									!row.called
										? {
												backgroundColor: "rgb(117, 158, 199)",
												color: "white",
										  }
										: {
												backgroundColor: "rgb(98, 240, 156)",
												color: "black",
										  }
								}
								disabled={props.loadingAxiosReq}
								className="btn-phone-icon-commission"
								onClick={() =>
									row.called
										? props.callToggle(row.id, false)
										: props.callToggle(row.id, true)
								}
							>
								<i className="fas fa-phone-square phone-icon-commission"></i>
							</button>
						)}
					</div>
				)
			},
		},
		{
			dataField: "cost",
			text: "Cost",
			// sort: true,
			formatter: (cell, row) => {
				return (
					<p
						style={
							row.cost > 0
								? {
										color: "black",
										backgroundColor: "rgb(139, 218, 139)",
										height: "100%",
								  }
								: { color: "red" }
						}
						className="tdata-commission text-align-center"
					>
						${row.cost}
					</p>
				)
			},
		},
		{
			dataField: "tip",
			text: "Tip",
			// sort: true,
		},

		{
			dataField: "actions",
			text: "Actions",
			isDummyField: true,
			csvExport: false,
			formatter: (cell, row) => {
				return (
					<button
						className="update-info-commission"
						onClick={() =>
							props.getGroomerDataAndOpenModal(
								row.id,
								props.tableName.toLowerCase()
							)
						}
					>
						Update Info...
					</button>
				)
			},
		},
	]

	return (
		<div className={props.tableClass + " row commission-groomers-info"}>
			<h6 className="commission-subheading text-center">
				<strong>
					{props.tableName === "tristen" && <i className="fas fa-paw"></i>}
					{""} {props.tableName === "tristen" ? "BATHS" : props.tableName}
					{""} {props.tableName === "tristen" && <i className="fas fa-paw"></i>}
				</strong>
			</h6>
			<div className="text-center">
				<BootstrapTable
					className="react-table-component"
					keyField="id"
					data={props.groomerData}
					columns={columns}
					striped
					wrapperClasses="table-responsive"
				/>
			</div>
		</div>
	)
}

export default TableCommission
