import React, { Component } from "react";
import axios from "axios";
import DeleteBtn from "./DeleteBtn";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Button, Form, FormGroup, Label, Col } from "reactstrap";
import "./style.scss";

const ACTIVE_BATHER = "bather";

export default class LegacyCommissionReport extends Component {
	state = {
		commissionClaudiaInput: "",
		commissionDianaInput: "",
		commissionPaolaInput: "",
		commissionGroomer1Input: "",
		commissionGroomer2Input: "",
		commissionGroomer3Input: "",

		startDate: "",
		endDate: "",

		// claudia
		dateResultClaudia: [],
		dateResultClaudiaCost: [],
		dateResultClaudiaTip: [],
		dateResultClaudiaDates: [],
		totalDailyClaudia: "",
		claudiaDatePicker: false,
		periodStartClaudia: "",
		periodEndClaudia: "",

		// diana
		dateResultDiana: [],
		dateResultDianaCost: [],
		dateResultDianaTip: [],
		dateResultDianaDates: [],
		totalDailyDiana: "",
		dianaDatePicker: false,
		periodStartDiana: "",
		periodEndDiana: "",

		// Paola
		dateResultPaola: [],
		dateResultPaolaCost: [],
		dateResultPaolaTip: [],
		dateResultPaolaDates: [],
		totalDailyPaola: "",
		paolaDatePicker: false,
		periodStartPaola: "",
		periodEndPaola: "",

		// Groomer 1
		dateResultGroomer1: [],
		dateResultGroomer1Cost: [],
		dateResultGroomer1Tip: [],
		dateResultGroomer1Dates: [],
		totalDailyGroomer1: "",
		groomer1DatePicker: false,
		periodStartGroomer1: "",
		periodEndGroomer1: "",

		// Groomer 2
		dateResultGroomer2: [],
		dateResultGroomer2Cost: [],
		dateResultGroomer2Tip: [],
		dateResultGroomer2Dates: [],
		totalDailyGroomer2: "",
		groomer2DatePicker: false,
		periodStartGroomer2: "",
		periodEndGroomer2: "",

		// Groomer 3
		dateResultGroomer3: [],
		dateResultGroomer3Cost: [],
		dateResultGroomer3Tip: [],
		dateResultGroomer3Dates: [],
		totalDailyGroomer3: "",
		groomer3DatePicker: false,
		periodStartGroomer3: "",
		periodEndGroomer3: "",

		// Groomer 4 (Assigned to Peppa)
		dateResultGroomer4: [],
		dateResultGroomer4Cost: [],
		dateResultGroomer4Tip: [],
		dateResultGroomer4Dates: [],
		totalDailyGroomer4: "",
		groomer4DatePicker: false,
		periodStartGroomer4: "",
		periodEndGroomer4: "",
	};

	datePickerStart = (date) => {
		this.setState({
			startDate: date,
		});
	};

	datePickerEnd = (date) => {
		this.setState({
			endDate: date,
		});
	};

	handleChangeCommission = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	// CLAUDIA COMMISSIONS
	submitClaudiaCommission = async (e) => {
		e.preventDefault();
		if (!this.state.startDate || !this.state.endDate) {
			return;
		}

		const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
		const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
		await axios
			.get(`/auth/commission/claudia/${startDate}/${endDate}`)
			.then((res) => {
				// console.log(res);
				if (res.data.length === 0) {
					return alert("Nothing to report in these dates!");
				}
				let totalDailyResultCost = res.data.map((item) => {
					return item.cost;
				});
				let totalDailyResultTip = res.data.map((item) => {
					return item.tip;
				});
				let totalDailyResultDates = res.data.map((item) => {
					return item.date;
				});
				totalDailyResultDates = totalDailyResultDates[0];

				this.setState({
					periodStartClaudia: startDate,
					periodEndClaudia: endDate,
					dateResultClaudia: this.state.dateResultClaudia.concat(res.data),
					dateResultClaudiaDates: this.state.dateResultClaudiaDates.concat(
						totalDailyResultDates
					),
					dateResultClaudiaCost:
						this.state.dateResultClaudiaCost.concat(totalDailyResultCost),
					dateResultClaudiaTip:
						this.state.dateResultClaudiaTip.concat(totalDailyResultTip),
					commissionClaudiaInput: "",
				});
			})
			.catch((err) => console.log(err));
	};

	// DIANA COMMISSIONS
	submitDianaCommission = async (e) => {
		e.preventDefault();
		if (!this.state.startDate || !this.state.endDate) {
			return;
		}
		const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
		const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
		await axios
			.get(`/auth/commission/diana/${startDate}/${endDate}`)
			.then((res) => {
				if (res.data.length === 0) {
					return alert("This date does not exist!");
				}
				let totalDailyResultCost = res.data.map((item) => {
					return item.cost;
				});
				let totalDailyResultTip = res.data.map((item) => {
					return item.tip;
				});
				let totalDailyResultDates = res.data.map((item) => {
					return item.date;
				});
				totalDailyResultDates = totalDailyResultDates[0];

				this.setState({
					periodStartDiana: startDate,
					periodEndDiana: endDate,
					dateResultDiana: this.state.dateResultDiana.concat(res.data),
					dateResultDianaDates: this.state.dateResultDianaDates.concat(
						totalDailyResultDates
					),
					dateResultDianaCost:
						this.state.dateResultDianaCost.concat(totalDailyResultCost),
					dateResultDianaTip:
						this.state.dateResultDianaTip.concat(totalDailyResultTip),
					commissionDianaInput: "",
				});
			})
			.catch((err) => console.log(err));
	};

	// PAOLA COMMISSIONS
	submitPaolaCommission = async (e) => {
		e.preventDefault();
		if (!this.state.startDate || !this.state.endDate) {
			return;
		}
		const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
		const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
		await axios
			.get(`/auth/commission/paola/${startDate}/${endDate}`)
			.then((res) => {
				if (res.data.length === 0) {
					return alert("This date does not exist!");
				}
				let totalDailyResultCost = res.data.map((item) => {
					return item.cost;
				});
				let totalDailyResultTip = res.data.map((item) => {
					return item.tip;
				});
				let totalDailyResultDates = res.data.map((item) => {
					return item.date;
				});
				totalDailyResultDates = totalDailyResultDates[0];

				this.setState({
					periodStartPaola: startDate,
					periodEndPaola: endDate,
					dateResultPaola: this.state.dateResultPaola.concat(res.data),
					dateResultPaolaDates: this.state.dateResultPaolaDates.concat(
						totalDailyResultDates
					),
					dateResultPaolaCost:
						this.state.dateResultPaolaCost.concat(totalDailyResultCost),
					dateResultPaolaTip:
						this.state.dateResultPaolaTip.concat(totalDailyResultTip),
					commissionPaolaInput: "",
				});
			})
			.catch((err) => console.log(err));
	};

	// GROOMER 1 COMMISSIONS
	submitGroomer1Commission = async (e) => {
		e.preventDefault();
		if (!this.state.startDate || !this.state.endDate) {
			return;
		}
		const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
		const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
		await axios
			.get(`/auth/commission/groomer1/${startDate}/${endDate}`)
			.then((res) => {
				if (res.data.length === 0) {
					return alert("This date does not exist!");
				}
				let totalDailyResultCost = res.data.map((item) => {
					return item.cost;
				});
				let totalDailyResultTip = res.data.map((item) => {
					return item.tip;
				});
				let totalDailyResultDates = res.data.map((item) => {
					return item.date;
				});
				totalDailyResultDates = totalDailyResultDates[0];

				this.setState({
					periodStartGroomer1: startDate,
					periodEndGroomer1: endDate,
					dateResultGroomer1: this.state.dateResultGroomer1.concat(res.data),
					dateResultGroomer1Dates: this.state.dateResultGroomer1Dates.concat(
						totalDailyResultDates
					),
					dateResultGroomer1Cost:
						this.state.dateResultGroomer1Cost.concat(totalDailyResultCost),
					dateResultGroomer1Tip:
						this.state.dateResultGroomer1Tip.concat(totalDailyResultTip),
					commissionGroomer1Input: "",
				});
			})
			.catch((err) => console.log(err));
	};

	// GROOMER 2 COMMISSIONS
	submitGroomer2Commission = async (e) => {
		e.preventDefault();
		if (!this.state.startDate || !this.state.endDate) {
			return;
		}
		const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
		const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
		await axios
			.get(`/auth/commission/groomer2/${startDate}/${endDate}`)
			.then((res) => {
				if (res.data.length === 0) {
					return alert("This date does not exist!");
				}
				let totalDailyResultCost = res.data.map((item) => {
					return item.cost;
				});
				let totalDailyResultTip = res.data.map((item) => {
					return item.tip;
				});
				let totalDailyResultDates = res.data.map((item) => {
					return item.date;
				});
				totalDailyResultDates = totalDailyResultDates[0];

				this.setState({
					periodStartGroomer2: startDate,
					periodEndGroomer2: endDate,
					dateResultGroomer2: this.state.dateResultGroomer2.concat(res.data),
					dateResultGroomer2Dates: this.state.dateResultGroomer2Dates.concat(
						totalDailyResultDates
					),
					dateResultGroomer2Cost:
						this.state.dateResultGroomer2Cost.concat(totalDailyResultCost),
					dateResultGroomer2Tip:
						this.state.dateResultGroomer2Tip.concat(totalDailyResultTip),
					commissionGroomer2Input: "",
				});
			})
			.catch((err) => console.log(err));
	};

	// GROOMER 3 COMMISSIONS
	submitGroomer3Commission = async (e) => {
		e.preventDefault();
		if (!this.state.startDate || !this.state.endDate) {
			return;
		}
		const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
		const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
		await axios
			.get(`/auth/commission/groomer3/${startDate}/${endDate}`)
			.then((res) => {
				if (res.data.length === 0) {
					return alert("This date does not exist!");
				}
				let totalDailyResultCost = res.data.map((item) => {
					return item.cost;
				});
				let totalDailyResultTip = res.data.map((item) => {
					return item.tip;
				});
				let totalDailyResultDates = res.data.map((item) => {
					return item.date;
				});
				totalDailyResultDates = totalDailyResultDates[0];

				this.setState({
					periodStartGroomer3: startDate,
					periodEndGroomer3: endDate,
					dateResultGroomer3: this.state.dateResultGroomer3.concat(res.data),
					dateResultGroomer3Dates: this.state.dateResultGroomer3Dates.concat(
						totalDailyResultDates
					),
					dateResultGroomer3Cost:
						this.state.dateResultGroomer3Cost.concat(totalDailyResultCost),
					dateResultGroomer3Tip:
						this.state.dateResultGroomer3Tip.concat(totalDailyResultTip),
					commissionGroomer3Input: "",
				});
			})
			.catch((err) => console.log(err));
	};

	// GROOMER 4 COMMISSIONS
	submitGroomer4Commission = async (e) => {
		e.preventDefault();
		if (!this.state.startDate || !this.state.endDate) {
			return;
		}
		const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
		const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
		await axios
			.get(`/auth/commission/groomer4/${startDate}/${endDate}`)
			.then((res) => {
				if (res.data.length === 0) {
					return alert("This date does not exist!");
				}
				let totalDailyResultCost = res.data.map((item) => {
					return item.cost;
				});
				let totalDailyResultTip = res.data.map((item) => {
					return item.tip;
				});
				let totalDailyResultDates = res.data.map((item) => {
					return item.date;
				});
				totalDailyResultDates = totalDailyResultDates[0];

				this.setState({
					periodStartGroomer4: startDate,
					periodEndGroomer4: endDate,
					dateResultGroomer4: this.state.dateResultGroomer4.concat(res.data),
					dateResultGroomer4Dates: this.state.dateResultGroomer4Dates.concat(
						totalDailyResultDates
					),
					dateResultGroomer4Cost:
						this.state.dateResultGroomer4Cost.concat(totalDailyResultCost),
					dateResultGroomer4Tip:
						this.state.dateResultGroomer4Tip.concat(totalDailyResultTip),
					commissionGroomer4Input: "",
				});
			})
			.catch((err) => console.log(err));
	};
	toggleClaudiaDatePicker = () => {
		this.setState({
			claudiaDatePicker: !this.state.claudiaDatePicker,
			dianaDatePicker: false,
			paolaDatePicker: false,
			groomer1DatePicker: false,
			groomer2DatePicker: false,
			groomer4DatePicker: false,
			groomer3DatePicker: false,
			startDate: "",
			endDate: "",
		});
	};
	toggleDianaDatePicker = () => {
		this.setState({
			dianaDatePicker: !this.state.dianaDatePicker,
			paolaDatePicker: false,
			groomer1DatePicker: false,
			groomer2DatePicker: false,
			groomer4DatePicker: false,
			groomer3DatePicker: false,
			claudiaDatePicker: false,
			startDate: "",
			endDate: "",
		});
	};
	togglePaolaDatePicker = () => {
		this.setState({
			paolaDatePicker: !this.state.paolaDatePicker,
			dianaDatePicker: false,
			groomer1DatePicker: false,
			groomer2DatePicker: false,
			groomer4DatePicker: false,
			groomer3DatePicker: false,
			claudiaDatePicker: false,
			startDate: "",
			endDate: "",
		});
	};
	toggleGroomer1DatePicker = () => {
		this.setState({
			groomer1DatePicker: !this.state.groomer1DatePicker,
			groomer2DatePicker: false,
			groomer3DatePicker: false,
			groomer4DatePicker: false,
			dianaDatePicker: false,
			paolaDatePicker: false,
			claudiaDatePicker: false,
			startDate: "",
			endDate: "",
		});
	};
	toggleGroomer2DatePicker = () => {
		this.setState({
			groomer2DatePicker: !this.state.groomer2DatePicker,
			groomer1DatePicker: false,
			groomer3DatePicker: false,
			groomer4DatePicker: false,
			dianaDatePicker: false,
			paolaDatePicker: false,
			claudiaDatePicker: false,
			startDate: "",
			endDate: "",
		});
	};
	toggleGroomer3DatePicker = () => {
		this.setState({
			groomer3DatePicker: !this.state.groomer3DatePicker,
			groomer4DatePicker: false,
			groomer2DatePicker: false,
			groomer1DatePicker: false,
			dianaDatePicker: false,
			paolaDatePicker: false,
			claudiaDatePicker: false,
			startDate: "",
			endDate: "",
		});
	};
	toggleGroomer4DatePicker = () => {
		this.setState({
			groomer4DatePicker: !this.state.groomer4DatePicker,
			groomer3DatePicker: false,
			groomer2DatePicker: false,
			groomer1DatePicker: false,
			dianaDatePicker: false,
			paolaDatePicker: false,
			claudiaDatePicker: false,
			startDate: "",
			endDate: "",
		});
	};

	clearCommission = (groomer) => {
		console.log(groomer);
		if (groomer === "claudia") {
			this.setState({
				dateResultClaudia: [],
				dateResultClaudiaCost: [],
				dateResultClaudiaTip: [],
				dateResultClaudiaDates: [],
				totalDailyClaudia: "",
				claudiaDatePicker: false,
				periodStartClaudia: "",
				periodEndClaudia: "",
			});
		}
		if (groomer === "diana") {
			this.setState({
				dateResultDiana: [],
				dateResultDianaCost: [],
				dateResultDianaTip: [],
				dateResultDianaDates: [],
				totalDailyDiana: "",
				dianaDatePicker: false,
				periodStartDiana: "",
				periodEndDiana: "",
			});
		}
		if (groomer === "paola") {
			this.setState({
				dateResultPaola: [],
				dateResultPaolaCost: [],
				dateResultPaolaTip: [],
				dateResultPaolaDates: [],
				totalDailyPaola: "",
				paolaDatePicker: false,
				periodStartPaola: "",
				periodEndPaola: "",
			});
		}
		if (groomer === "frank") {
			this.setState({
				dateResultGroomer1: [],
				dateResultGroomer1Cost: [],
				dateResultGroomer1Tip: [],
				dateResultGroomer1Dates: [],
				totalDailyGroomer1: "",
				groomer1DatePicker: false,
				periodStartGroomer1: "",
				periodEndGroomer1: "",
			});
		}
		if (groomer === ACTIVE_BATHER || groomer === "bather") {
			this.setState({
				dateResultGroomer2: [],
				dateResultGroomer2Cost: [],
				dateResultGroomer2Tip: [],
				dateResultGroomer2Dates: [],
				totalDailyGroomer2: "",
				groomer2DatePicker: false,
				periodStartGroomer2: "",
				periodEndGroomer2: "",
			});
		}
		if (groomer === "nicole") {
			this.setState({
				dateResultGroomer3: [],
				dateResultGroomer3Cost: [],
				dateResultGroomer3Tip: [],
				dateResultGroomer3Dates: [],
				totalDailyGroomer3: "",
				groomer3DatePicker: false,
				periodStartGroomer3: "",
				periodEndGroomer3: "",
			});
		}
		if (groomer === "peppa") {
			this.setState({
				dateResultGroomer4: [],
				dateResultGroomer4Cost: [],
				dateResultGroomer4Tip: [],
				dateResultGroomer4Dates: [],
				totalDailyGroomer4: "",
				groomer4DatePicker: false,
				periodStartGroomer4: "",
				periodEndGroomer4: "",
			});
		}
	};

	render() {
		const dateResultClaudia = this.state.dateResultClaudia;
		const dateResultClaudiaList =
			dateResultClaudia.length > 0 ? (
				dateResultClaudia.map((result) => {
					return (
						<p
							className="result-claudia-container text-align-center"
							key={uuidv4()}
						>
							{result.nameBreed}
						</p>
					);
				})
			) : (
				<div className="text-align-center">
					<h5>
						Nothing yet <i className="far fa-surprise"></i>
					</h5>
				</div>
			);

		const dateResultDiana = this.state.dateResultDiana;
		const dateResultDianaList =
			dateResultDiana.length > 0 ? (
				dateResultDiana.map((result) => {
					return (
						<p
							className="result-claudia-container text-align-center"
							key={uuidv4()}
						>
							{result.nameBreed}
						</p>
					);
				})
			) : (
				<div className="text-align-center">
					<h5>
						Nothing yet <i className="far fa-surprise"></i>
					</h5>
				</div>
			);

		const dateResultPaola = this.state.dateResultPaola;
		const dateResultPaolaList =
			dateResultPaola.length > 0 ? (
				dateResultPaola.map((result) => {
					return (
						<p
							className="result-claudia-container text-align-center"
							key={uuidv4()}
						>
							{result.nameBreed}
						</p>
					);
				})
			) : (
				<div className="text-align-center">
					<h5>
						Nothing yet <i className="far fa-surprise"></i>
					</h5>
				</div>
			);

		const dateResultGroomer1 = this.state.dateResultGroomer1;
		const dateResultGroomer1List =
			dateResultGroomer1.length > 0 ? (
				dateResultGroomer1.map((result) => {
					return (
						<p
							className="result-claudia-container text-align-center"
							key={uuidv4()}
						>
							{result.nameBreed}
						</p>
					);
				})
			) : (
				<div className="text-align-center">
					<h5>
						Nothing yet <i className="far fa-surprise"></i>
					</h5>
				</div>
			);

		const dateResultGroomer2 = this.state.dateResultGroomer2;
		const dateResultGroomer2List =
			dateResultGroomer2.length > 0 ? (
				dateResultGroomer2.map((result) => {
					return (
						<p
							className="result-claudia-container text-align-center"
							key={uuidv4()}
						>
							{result.nameBreed}
						</p>
					);
				})
			) : (
				<div className="text-align-center">
					<h5>
						Nothing yet <i className="far fa-surprise"></i>
					</h5>
				</div>
			);

		const dateResultGroomer3 = this.state.dateResultGroomer3;
		const dateResultGroomer3List =
			dateResultGroomer3.length > 0 ? (
				dateResultGroomer3.map((result) => {
					return (
						<p
							className="result-claudia-container text-align-center"
							key={uuidv4()}
						>
							{result.nameBreed}
						</p>
					);
				})
			) : (
				<div className="text-align-center">
					<h5>
						Nothing yet <i className="far fa-surprise"></i>
					</h5>
				</div>
			);

		const dateResultGroomer4 = this.state.dateResultGroomer4;
		const dateResultGroomer4List =
			dateResultGroomer4.length > 0 ? (
				dateResultGroomer4.map((result) => {
					return (
						<p
							className="result-claudia-container text-align-center"
							key={uuidv4()}
						>
							{result.nameBreed}
						</p>
					);
				})
			) : (
				<div className="text-align-center">
					<h5>
						Nothing yet <i className="far fa-surprise"></i>
					</h5>
				</div>
			);
		return (
			<div className="commissions-container">
				<h3 className="text-align-center margin-top-small">
					Commissions{" "}
					<span className="text-danger">
						(It generates reports up to NOV 12th, 2022)
					</span>
				</h3>
				<div className="clear-commission-wrapper">
					<DeleteBtn
						groomerName="Claudia"
						clearCommission={() => this.clearCommission("claudia")}
					/>
					<DeleteBtn
						groomerName="Diana"
						clearCommission={() => this.clearCommission("diana")}
					/>
					<DeleteBtn
						groomerName="Paola"
						clearCommission={() => this.clearCommission("paola")}
					/>
					<DeleteBtn
						groomerName="Frank"
						clearCommission={() => this.clearCommission("frank")}
					/>
					<DeleteBtn
						groomerName="nicole"
						clearCommission={() => this.clearCommission("nicole")}
					/>
					<DeleteBtn
						groomerName="peppa"
						clearCommission={() => this.clearCommission("peppa")}
					/>
					<DeleteBtn
						groomerName={ACTIVE_BATHER}
						clearCommission={() => this.clearCommission(ACTIVE_BATHER)}
					/>
				</div>
				<div className="row">
					<div className="col-lg-3 ">
						<div className="container left-commissions">
							<button
								className="btn btn-info btn-block"
								onClick={this.toggleClaudiaDatePicker}
							>
								CLAUDIA
							</button>
							{this.state.claudiaDatePicker ? (
								<Form onSubmit={this.submitClaudiaCommission}>
									<Label className="text-align-center" lg={12}>
										<b>CLAUDIA</b>
									</Label>
									<FormGroup row>
										<Label lg={2}>From</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.startDate}
												onChange={this.datePickerStart}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label lg={2}>To</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.endDate}
												onChange={this.datePickerEnd}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>

									<Button block color="info">
										Claudia Submit
									</Button>
								</Form>
							) : null}
						</div>
						<div className="container left-commissions">
							<button
								className="btn btn-success btn-block"
								onClick={this.toggleDianaDatePicker}
							>
								DIANA
							</button>
							{this.state.dianaDatePicker ? (
								<Form onSubmit={this.submitDianaCommission}>
									<Label className="text-align-center" lg={12}>
										<b>DIANA</b>
									</Label>
									<FormGroup row>
										<Label lg={2}>From</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.startDate}
												onChange={this.datePickerStart}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label lg={2}>To</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.endDate}
												onChange={this.datePickerEnd}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<Button block color="success">
										Diana Submit
									</Button>
								</Form>
							) : null}
						</div>
						<div className="container left-commissions">
							<button
								className="btn btn-info btn-block"
								onClick={this.togglePaolaDatePicker}
							>
								PAOLA
							</button>
							{this.state.paolaDatePicker ? (
								<Form onSubmit={this.submitPaolaCommission}>
									<Label className="text-align-center" lg={12}>
										<b>PAOLA</b>
									</Label>
									<FormGroup row>
										<Label lg={2}>From</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.startDate}
												onChange={this.datePickerStart}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label lg={2}>To</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.endDate}
												onChange={this.datePickerEnd}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<Button block color="info">
										Paola Submit
									</Button>
								</Form>
							) : null}
						</div>
						<div className="container left-commissions">
							<button
								className="btn btn-success btn-block"
								onClick={this.toggleGroomer1DatePicker}
							>
								FRANK
							</button>
							{this.state.groomer1DatePicker ? (
								<Form onSubmit={this.submitGroomer1Commission}>
									<Label className="text-align-center" lg={12}>
										<b>FRANK</b>
									</Label>
									<FormGroup row>
										<Label lg={2}>From</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.startDate}
												onChange={this.datePickerStart}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label lg={2}>To</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.endDate}
												onChange={this.datePickerEnd}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<Button block color="success">
										Frank Submit
									</Button>
								</Form>
							) : null}
						</div>
						<div className="container left-commissions">
							<button
								className="btn btn-info btn-block"
								onClick={this.toggleGroomer3DatePicker}
							>
								NICOLE
							</button>
							{this.state.groomer3DatePicker ? (
								<Form onSubmit={this.submitGroomer3Commission}>
									<Label className="text-align-center" lg={12}>
										<b>NICOLE</b>
									</Label>
									<FormGroup row>
										<Label lg={2}>From</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.startDate}
												onChange={this.datePickerStart}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label lg={2}>To</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.endDate}
												onChange={this.datePickerEnd}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<Button block color="success">
										Nicole Submit
									</Button>
								</Form>
							) : null}
						</div>
						<div className="container left-commissions">
							<button
								className="btn btn-info btn-block"
								onClick={this.toggleGroomer4DatePicker}
							>
								PEPPA
							</button>
							{this.state.groomer4DatePicker ? (
								<Form onSubmit={this.submitGroomer4Commission}>
									<Label className="text-align-center" lg={12}>
										<b>Peppa</b>
									</Label>
									<FormGroup row>
										<Label lg={2}>From</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.startDate}
												onChange={this.datePickerStart}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label lg={2}>To</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.endDate}
												onChange={this.datePickerEnd}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<Button block color="success">
										Peppa Submit
									</Button>
								</Form>
							) : null}
						</div>
						<div className="container left-commissions">
							<button
								className="btn btn-success btn-block"
								onClick={this.toggleGroomer2DatePicker}
							>
								{ACTIVE_BATHER.toLocaleUpperCase()}
							</button>
							{this.state.groomer2DatePicker ? (
								<Form onSubmit={this.submitGroomer2Commission}>
									<Label className="text-align-center" lg={12}>
										<b>{ACTIVE_BATHER.toLocaleUpperCase}</b>
									</Label>
									<FormGroup row>
										<Label lg={2}>From</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.startDate}
												onChange={this.datePickerStart}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label lg={2}>To</Label>
										<Col lg={5}>
											<DatePicker
												selected={this.state.endDate}
												onChange={this.datePickerEnd}
												dateFormat="yyyy-MM-dd"
											/>
										</Col>
									</FormGroup>
									<Button block color="success">
										{ACTIVE_BATHER} Submit
									</Button>
								</Form>
							) : null}
						</div>
					</div>
					<div className="col-lg-9 right-calculations">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<h2 className="text-align-center">Calculation Results</h2>
									<hr />
								</div>
								<div className="row"></div>
								<div className="col-lg-4">
									<div className="card">
										<h5 className="text-align-center margin-top-small">
											CLAUDIA's PETS
										</h5>
										<hr />
										<div className="pets-list-claudia-container">
											{dateResultClaudiaList}
										</div>
									</div>
									<div className="card margin-top-small">
										<h5 className="text-align-center margin-top-small">
											DIANA's PETS
										</h5>
										<hr />
										<div className="pets-list-claudia-container">
											{dateResultDianaList}
										</div>
									</div>
									<div className="card margin-top-small">
										<h5 className="text-align-center margin-top-small">
											PAOLA's PETS
										</h5>
										<hr />
										<div className="pets-list-claudia-container">
											{dateResultPaolaList}
										</div>
									</div>
									<div className="card margin-top-small">
										<h5 className="text-align-center margin-top-small">
											FRANK's PETS
										</h5>
										<hr />
										<div className="pets-list-claudia-container">
											{dateResultGroomer1List}
										</div>
									</div>
									<div className="card margin-top-small">
										<h5 className="text-align-center margin-top-small">
											NICOLE's PETS
										</h5>
										<hr />
										<div className="pets-list-claudia-container">
											{dateResultGroomer3List}
										</div>
									</div>
									<div className="card margin-top-small">
										<h5 className="text-align-center margin-top-small">
											PEPPA's PETS
										</h5>
										<hr />
										<div className="pets-list-claudia-container">
											{dateResultGroomer4List}
										</div>
									</div>
									<div className="card margin-top-small">
										<h5 className="text-align-center margin-top-small">
											{ACTIVE_BATHER.toLocaleUpperCase()}'s PETS
										</h5>
										<hr />
										<div className="pets-list-claudia-container">
											{dateResultGroomer2List}
										</div>
									</div>
								</div>
								<hr />

								{/* Commissions rendering Start */}
								<div className="col-lg-8">
									{this.state.dateResultClaudiaCost.length > 0 ? (
										<div className="row">
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													CLAUDIA
												</h5>
												<hr />
												<h6>
													{this.state.periodStartClaudia} -{" "}
													{this.state.periodEndClaudia}
												</h6>
												<h6>
													Total Sales: $
													{this.state.dateResultClaudiaCost.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Total Tips: $
													{this.state.dateResultClaudiaTip.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Number of pets groomed ={" "}
													{this.state.dateResultClaudia.length}{" "}
													<i className="fas fa-paw"></i>
												</h6>
											</div>
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													CLAUDIA
												</h5>
												<hr />
												<h5>
													Total Commissions = $
													{(
														this.state.dateResultClaudiaCost.reduce(
															(a, b) => a + b
														) / 2
													).toFixed(2)}
												</h5>
												<h5>
													Final Tips (100%) = $
													{this.state.dateResultClaudiaTip
														.reduce((a, b) => a + b)
														.toFixed(2)}
												</h5>
											</div>
										</div>
									) : (
										<h4 className="text-align-center margin-top-small">
											No calculations for Claudia yet{" "}
											<i className="far fa-smile-beam"></i>
											<hr />
										</h4>
									)}

									{/* Diana Rendering commission */}
									{this.state.dateResultDianaCost.length > 0 ? (
										<div className="row">
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													DIANA
												</h5>
												<hr />
												<h6>
													{this.state.periodStartDiana} -{" "}
													{this.state.periodEndDiana}
												</h6>
												<h6>
													Total Sales: $
													{this.state.dateResultDianaCost.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Total Tips: $
													{this.state.dateResultDianaTip.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Number of pets groomed ={" "}
													{this.state.dateResultDiana.length}{" "}
													<i className="fas fa-paw"></i>
												</h6>
											</div>
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													DIANA
												</h5>
												<hr />
												<h5>
													Total Commissions = $
													{(
														this.state.dateResultDianaCost.reduce(
															(a, b) => a + b
														) / 2
													).toFixed(2)}
												</h5>
												<h5>
													Final Tips (100%) = $
													{this.state.dateResultDianaTip
														.reduce((a, b) => a + b)
														.toFixed(2)}
												</h5>
											</div>
										</div>
									) : (
										<h4 className="text-align-center margin-top-small">
											No calculations for Diana yet{" "}
											<i className="far fa-smile-beam"></i>
											<hr />
										</h4>
									)}

									{/* Paola Rendering commission */}
									{this.state.dateResultPaolaCost.length > 0 ? (
										<div className="row">
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													PAOLA
												</h5>
												<hr />
												<h6>
													{this.state.periodStartPaola} -{" "}
													{this.state.periodEndPaola}
												</h6>
												<h6>
													Total Sales: $
													{this.state.dateResultPaolaCost.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Total Tips: $
													{this.state.dateResultPaolaTip.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Number of pets groomed ={" "}
													{this.state.dateResultPaola.length}{" "}
													<i className="fas fa-paw"></i>
												</h6>
											</div>
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													PAOLA
												</h5>
												<hr />
												<h5>
													Total Commissions = $
													{(
														this.state.dateResultPaolaCost.reduce(
															(a, b) => a + b
														) / 2
													).toFixed(2)}
												</h5>
												<h5>
													Final Tips (100%) = $
													{this.state.dateResultPaolaTip
														.reduce((a, b) => a + b)
														.toFixed(2)}
												</h5>
											</div>
										</div>
									) : (
										<h4 className="text-align-center margin-top-small">
											No calculations for Paola yet{" "}
											<i className="far fa-smile-beam"></i>
											<hr />
										</h4>
									)}

									{/* Groomer 1 Rendering commission */}
									{this.state.dateResultGroomer1Cost.length > 0 ? (
										<div className="row">
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													Frank
												</h5>
												<hr />
												<h6>
													{this.state.periodStartGroomer1} -{" "}
													{this.state.periodEndGroomer1}
												</h6>
												<h6>
													Total Sales: $
													{this.state.dateResultGroomer1Cost.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Total Tips: $
													{this.state.dateResultGroomer1Tip.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Number of pets groomed ={" "}
													{this.state.dateResultGroomer1.length}{" "}
													<i className="fas fa-paw"></i>
												</h6>
											</div>
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													Frank
												</h5>
												<hr />
												<h5>
													Total Commissions = $
													{(
														this.state.dateResultGroomer1Cost.reduce(
															(a, b) => a + b
														) / 2
													).toFixed(2)}
												</h5>
												<h5>
													Final Tips (100%) = $
													{this.state.dateResultGroomer1Tip
														.reduce((a, b) => a + b)
														.toFixed(2)}
												</h5>
											</div>
										</div>
									) : (
										<h4 className="text-align-center margin-top-small">
											No calculations for Frank yet{" "}
											<i className="far fa-smile-beam"></i>
											<hr />
										</h4>
									)}

									{/* Groomer 3 Rendering commission */}
									{this.state.dateResultGroomer3Cost.length > 0 ? (
										<div className="row">
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													Nicole
												</h5>
												<hr />
												<h6>
													{this.state.periodStartGroomer3} -{" "}
													{this.state.periodEndGroomer3}
												</h6>
												<h6>
													Total Sales: $
													{this.state.dateResultGroomer3Cost.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Total Tips: $
													{this.state.dateResultGroomer3Tip.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Number of pets groomed ={" "}
													{this.state.dateResultGroomer3.length}{" "}
													<i className="fas fa-paw"></i>
												</h6>
											</div>
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													Nicole
												</h5>
												<hr />
												<h5>
													Total Commissions = $
													{(
														this.state.dateResultGroomer3Cost.reduce(
															(a, b) => a + b
														) / 2
													).toFixed(2)}
												</h5>
												<h5>
													Final Tips (100%) = $
													{this.state.dateResultGroomer3Tip
														.reduce((a, b) => a + b)
														.toFixed(2)}
												</h5>
											</div>
										</div>
									) : (
										<h4 className="text-align-center margin-top-small">
											No calculations for Nicole yet{" "}
											<i className="far fa-smile-beam"></i>
											<hr />
										</h4>
									)}

									{/* Groomer 4 Rendering commission */}
									{this.state.dateResultGroomer4Cost.length > 0 ? (
										<div className="row">
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													Peppa
												</h5>
												<hr />
												<h6>
													{this.state.periodStartGroomer4} -{" "}
													{this.state.periodEndGroomer4}
												</h6>
												<h6>
													Total Sales: $
													{this.state.dateResultGroomer4Cost.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Total Tips: $
													{this.state.dateResultGroomer4Tip.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Number of pets groomed ={" "}
													{this.state.dateResultGroomer4.length}{" "}
													<i className="fas fa-paw"></i>
												</h6>
											</div>
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													Peppa
												</h5>
												<hr />
												<h5>
													Total Commissions = $
													{(
														this.state.dateResultGroomer4Cost.reduce(
															(a, b) => a + b
														) / 2
													).toFixed(2)}
												</h5>
												<h5>
													Final Tips (100%) = $
													{this.state.dateResultGroomer4Tip
														.reduce((a, b) => a + b)
														.toFixed(2)}
												</h5>
											</div>
										</div>
									) : (
										<h4 className="text-align-center margin-top-small">
											No calculations for Peppa yet{" "}
											<i className="far fa-smile-beam"></i>
											<hr />
										</h4>
									)}

									{/* Groomer 2 Rendering commission */}
									{this.state.dateResultGroomer2Cost.length > 0 ? (
										<div className="row">
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													{ACTIVE_BATHER.toLocaleUpperCase}
												</h5>
												<hr />
												<h6>
													{this.state.periodStartGroomer2} -{" "}
													{this.state.periodEndGroomer2}
												</h6>
												<h6>
													Total Sales: $
													{this.state.dateResultGroomer2Cost.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Total Tips: $
													{this.state.dateResultGroomer2Tip.reduce(
														(a, b) => a + b
													)}
												</h6>
												<h6>
													Number of pets groomed ={" "}
													{this.state.dateResultGroomer2.length}{" "}
													<i className="fas fa-paw"></i>
												</h6>
											</div>
											<div className="col-lg-6 card margin-top-small">
												<h5 className="text-align-center margin-top-small">
													{ACTIVE_BATHER}
												</h5>
												<hr />
												<h5>
													Total Commissions = $
													{(
														this.state.dateResultGroomer2Cost.reduce(
															(a, b) => a + b
														) / 2
													).toFixed(2)}
												</h5>
												<h5>
													Final Tips (100%) = $
													{this.state.dateResultGroomer2Tip
														.reduce((a, b) => a + b)
														.toFixed(2)}
												</h5>
											</div>
										</div>
									) : (
										<h4 className="text-align-center margin-top-small">
											No calculations for {ACTIVE_BATHER.toLocaleUpperCase()}{" "}
											yet <i className="far fa-smile-beam"></i>
											<hr />
										</h4>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
