import React from "react"
import { Link } from "react-router-dom"
import { CustomButton } from "./CustomButton"
import { CustomCard } from "./CustomCard"

export const ServiceCard = ({ service, className, oppositeColor = false }) => {
	return (
		<CustomCard className={className} oppositeColor={oppositeColor}>
			<div className={`w-full h-full flex flex-col pt-[25px]`}>
				{/* Icon */}
				<div
					className={`w-[75px] h-[75px] rounded-full absolute -top-[20px] left-[20px] border-[5px] grid place-content-center ${
						oppositeColor
							? "bg-lightBlue border-white"
							: "bg-white border-lightBlue"
					}`}
					style={{
						boxShadow: "2px 4px 12px 0px rgba(0, 156, 255, 0.07)",
					}}
				>
					<p className="text-[30px] leading-none mb-0 select-none">
						{service.icon}
					</p>
				</div>

				{/* Content */}
				<div className={`w-full h-[80%] overflow-y-clip `}>
					<h3
						className={`text-[25px] font-medium mb-[20px] text-center text-main`}
					>
						{service.name}
					</h3>
					<p
						className={`mb-[40px] ${
							oppositeColor ? "text-main" : "text-black"
						}`}
					>
						{service.description}
					</p>
				</div>
				<div className="w-full h-[20%] flex items-end">
					<Link to="/schedule" className="w-full hover:no-underline">
						<CustomButton onClick={() => {}} fullWidth hasArrow noBackground>
							Booking
						</CustomButton>
					</Link>
				</div>
			</div>
		</CustomCard>
	)
}
